<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <div class="grey lighten-4">
    <div class="container">
      <!-- FilterAuth -->
      <FilterClaim @search="handleSearch" />
    </div>
  </div>

  <div class="container">

    <div class="row">
      <div class="col s12">

        <!-- Vetting Marker -->
        <div id="vet"></div>

        <!-- Claims -->
        <LoadingDiv v-if="!isReady" />

        <div v-else>
          <div class="section black-text center">
            <br />
            <h5 v-if="searchTitle" v-html="searchTitle"></h5>
            <h5 v-else>
              Displaying <span class="blue-text">Everything!</span>
              <i class="material-icons">check</i>
            </h5>
          </div>

          <div class="section">
            <div class="card-panel">
              <div class="row">
                <div class="col s1">#</div>
                <div class="col s3">Provider <small>(claims)</small></div>
                <div class="col s2">Sent</div>
                <div class="col s2">Approved</div>
                <div class="col s2">Claims <small>(scheme)</small></div>
                <div class="col s1">Status</div>
                <div class="col s1"><br /></div>
              </div>
            </div>

            <div class="progress" v-if="isLoading">
              <div class="indeterminate"></div>
            </div>

            <div class="card">
              <div class="card-panel" :class="{
                'blue-grey lighten-5': (index + 1) % 2 === 1,
              }" v-for="(cluster, index) in clusters" :key="cluster.id">
                <div class="row">
                  <div class="col s1">
                    <p>{{ numberBadge(index) }}</p>
                  </div>
                  <div class="col s3">
                    <h6>
                      <span v-if="
                        cluster.encounters.filter((x) => x.supervisor_flag == 'y')
                          .length
                      "><i class="tiny material-icons red-text pointer" title="supervisor issue">flag</i></span>
                      <span v-if="
                        cluster.encounters.filter((x) => x.hod_flag == 'y')
                          .length
                      "><i class="tiny material-icons red-text pointer" title="hod issue">flag</i></span>
                      <span v-if="
                        cluster.encounters.filter((x) => x.compliance_flag == 'y')
                          .length
                      "><i class="tiny material-icons red-text pointer" title="compliance issue">flag</i></span>
                      {{ capWords(cluster.provider.name) }}
                      <br />
                      <span v-if="cluster.status !== 'q'"><small>({{ cluster.encounters.length }} Claims)</small></span>
                    </h6>
                  </div>
                  <div class="col s2">
                    <p>
                      <span class="red-text" v-if="cluster.status === 'q'">₦0.00</span>
                      <span class="red-text" v-else>₦{{ grossTotalSent(cluster.encounters) }}</span>
                    </p>
                  </div>
                  <div class="col s2">
                    <p>
                      <span class="chip" v-if="cluster.status === 'p'">N/A</span>
                      <span class="chip yellow" v-if="cluster.status === 'q'">N/A</span>
                      <span class="green-text" v-if="cluster.status === 'r' || cluster.status === 'v'"><b>₦{{
                        grossTotalApproved(cluster.encounters) }}</b></span>
                    </p>
                  </div>
                  <div class="col s2">
                    <p>
                      {{ toMonthYear(cluster.month_year) }}
                      <small><b>({{ cluster.scheme.name }})</b></small>
                    </p>
                  </div>
                  <div class="col s1">
                    <p>
                      <span class="chip" :class="{
                        'chip teal lighten-4': paymentStatus(cluster)?.toLowerCase() === 'paid',
                        'chip grey lighten-2': paymentStatus(cluster)?.toLowerCase() === 'pending',
                        'chip orange lighten-2': paymentStatus(cluster)?.toLowerCase() === 'unsubmitted',
                        'chip indigo lighten-4': paymentStatus(cluster)?.toLowerCase() !== 'paid' && paymentStatus(cluster)?.toLowerCase() !== 'pending' && paymentStatus(cluster)?.toLowerCase() !== 'unsubmitted',
                      }">
                        {{
                          paymentStatus(cluster)
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="col s1">
                    <span v-if="cluster.status === 'q'">
                      <span class="btn-floating black pointable right"><i class="material-icons"
                          title="locked">lock_outline</i></span>
                    </span>

                    <span v-if="cluster.status === 'p'">
                      <a class="btn-floating red right"><i class="material-icons" title="review"
                          @click="handleEdit(cluster)">chevron_right</i></a>
                    </span>

                    <span v-if="cluster.status === 'r' || cluster.status === 'v'">
                      <a class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"><i
                          class="material-icons" @click="handleEdit(cluster)" title="details">chevron_right</i></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="section center" v-if="!clusters.length && !isLoading">
            <h6><br /><img class="responsive-img" src="@/assets/img/no-claim.png" width="256" title="No claims found" />
            </h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import getItems from "@/composables/getItems";
import SubHeading from "@/components/HeaderSubDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import FilterClaim from "@/components/claim/FilterClaimComponent.vue";
import {
  capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";
import {
  grossTotalSentFx,
  grossTotalApprovedFx,
  checkPaidFx,
  paymentStatusFx,
} from "@/composables/calFunction/cal";
import { getCache, addCache } from "@/composables/cache/cache";


export default {
  name: "claimHome",
  components: { SubHeading, LoadingDiv, PaginationDiv, FilterClaim },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const searchTitle = ref(null);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "All Claims Cluster | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Claims", title: "Home", icon: null, url: "claim", hash: null, });
      tabs.value.push({ id: 2, name: "Claims", title: "Vet", icon: "edit", url: "claim", hash: "#vet" });
      tabs.value.push({ id: 3, name: "Claims", title: "Add Claims", icon: "add_circle", url: "claimAdd", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      return grossTotalSentFx(encounters);
    };

    // total calculations (gross approved)
    const grossTotalApproved = (encounters, db) => {
      return grossTotalApprovedFx(encounters, db);
    };

    // convert to Month & Year
    const toMonthYear = (val) => {
      let parts = val.split("_");
      let month = parseInt(parts[0]);
      let year = parseInt(parts[1]);
      let date = new Date(year, month - 1); // months are zero-indexed in JavaScript
      let monthName = date.toLocaleString("default", { month: "short" });
      return monthName + " " + year;
    };

    // payment status
    const checkPaid = (payment, repayment) => {
      return checkPaidFx(payment, repayment);
    };

    // payment status
    const paymentStatus = (cluster) => {
      return paymentStatusFx(cluster);
    };

    // get function
    const getItemsFromApi = (table) => {

      // get item from store (cache)
      response.value = getCache(table);

      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          // add cache
          addCache(table, response.value);
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // handle search (emitted from component)
    const handleSearch = (val) => {
      // start loading
      isLoading.value = true;
      // set current Url
      currUrl.value = val.currUrl;
      // set current Hook
      currHook.value = val.currHook;
      // set search title
      searchTitle.value = val.searchTitle;
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // handle edit
    const handleEdit = (cluster) => {
      router.push({ name: "claimView", params: { clusterId: cluster.id } });
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // output results
    const clusters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data; // get data portion
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleActive,
      home,
      currTab,
      tabs,
      capWords,
      numberBadge,
      grossTotalSent,
      grossTotalApproved,
      toMonthYear,
      checkPaid,
      paymentStatus,
      handlePage,
      handleSearch,
      handleEdit,
      isReady,
      isLoading,
      error,
      searchTitle,
      clusters,
      allPages,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -15px;
  /* Adjust the value as needed to move the progress bar upwards */
}

.clickable {
  cursor: pointer;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.spacer {
  padding: 2px;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  color: #747373;
  border: 1px solid white;
  background-color: white;
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid white;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed (re-added)
  color: #000000;
  border: 1px solid #ddd;
  background-color: #d1f3df; */
.tab-custom-active {
  color: #000000;
  border: 1px solid #ddd;
  background-color: #d1f3df;
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>