<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <div class="grey lighten-4">
    <div class="container">
      <!-- FilterEnrollee -->
      <FilterEnrollee @search="handleSearch" />
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col s12">

        <LoadingDiv v-if="!isReady" />

        <div v-else>
          <div class="section black-text center">
            <br />
            <h6 v-if="searchTitle" v-html="searchTitle"></h6>
            <h5 v-else>
              Displaying <span class="blue-text">Everything!</span>
              <i class="material-icons">check</i>
            </h5>
          </div>

          <div class="section">
            <!-- List Display -->
            <div class="card-panel">
              <div class="row">
                <div class="col s1">#</div>
                <div class="col s3">Name <small>(Age/Sex)</small></div>
                <div class="col s2">ID</div>
                <div class="col s2">Client</div>
                <div class="col s2">Provider</div>
                <div class="col s2">Period</div>
              </div>
            </div>

            <div class="progress" v-if="isLoading">
              <div class="indeterminate"></div>
            </div>

            <div class="card" v-for="(enrollee, index) in enrollees" :key="enrollee.id">
              <div class="card-panel" :class="{
                'blue-grey lighten-5': (index + 1) % 2 === 1,
              }">
                <div class="row">
                  <div class="col s1">
                    <p>{{ numberBadge(index) }}</p>
                  </div>
                  <div class="col s3">
                    <h6>
                      <span class="new badge" data-badge-caption="principal" v-if="enrollee.type == 'p'"></span>
                      <span class="new badge orange" data-badge-caption="dependant" v-if="enrollee.type == 'd'"></span>
                      {{ enrollee.name }}
                      <small><span class="red-text">{{
                        ageSex(enrollee.dob, enrollee.sex)
                      }}</span></small>
                      <br />
                      {{ enrollee.phone }}
                    </h6>
                  </div>
                  <div class="col s2">
                    <p>
                      {{ enrollee.enrollee_number }}
                    </p>
                  </div>
                  <div class="col s2">
                    <p>{{ enrollee.client_name }}</p>
                  </div>
                  <div class="col s2">
                    <p>{{ enrollee.picked_provider }}</p>
                  </div>
                  <div class="col s2">
                    <p>
                      {{
                        new Date(listMonth).toLocaleString(undefined, {
                          year: "numeric",
                          month: "short",
                        })
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section center" v-if="!enrollees.length">
            <h6><img src="@/assets/img/no-user.png" width="256" class="circle responsive-img" /></h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import getItems from "@/composables/getItems";
import SubHeading from "@/components/HeaderSubDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import FilterEnrollee from "@/components/enrollee/FilterEnrolleeComponent.vue";
import { getCache, addCache } from "@/composables/cache/cache";


export default {
  name: "enrolleeHome",
  components: { SubHeading, LoadingDiv, PaginationDiv, FilterEnrollee },
  setup() {
    const response = ref(null);
    const listMonth = ref(null);
    const searchTitle = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Active (Monthly) Enrollees | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Enrollees", title: "Home", icon: null, url: "enrollee", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // get function
    const getItemsFromApi = (table) => {

      // get item from store (cache)
      response.value = getCache(table);

      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          // add cache
          addCache(table, response.value);
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // current month, year
    const getCurrentMonthYear = () => {
      return `${new Date().toLocaleDateString("en", {
        month: "long",
      })} ${new Date().getFullYear()}`;
    };

    // display function
    const ageSex = (dob, sex) => {
      let ageSex;
      let birthDateObj = new Date(dob);
      let today = new Date();
      let age = today.getFullYear() - birthDateObj.getFullYear();

      if (
        today.getMonth() < birthDateObj.getMonth() ||
        (today.getMonth() === birthDateObj.getMonth() &&
          today.getDate() < birthDateObj.getDate())
      ) {
        age--;
      }

      if (dob && sex) {
        ageSex = `(${sex}/${age})`;
      } else if (dob) {
        ageSex = `(${age}yrs)`;
      } else if (sex) {
        ageSex = `(${sex})`;
      }

      return ageSex;
    };

    // handle search (emitted from component)
    const handleSearch = (val) => {
      // start loading
      isLoading.value = true;
      // set current Url
      currUrl.value = val.currUrl;
      // set current Hook
      currHook.value = val.currHook;
      // set listMonth
      listMonth.value = val.listMonth;
      // set search title
      searchTitle.value = val.searchTitle;
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // output results
    const enrollees = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleActive,
      home,
      currTab,
      tabs,
      ageSex,
      numberBadge,
      getCurrentMonthYear,
      handleSearch,
      handlePage,
      isReady,
      isLoading,
      error,
      listMonth,
      searchTitle,
      enrollees,
      allPages,
    };
  },
};
</script>

<style scoped>
.line {
  margin-top: -45px;
}

.progress {
  margin-top: -15px;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
color: #000000;
border: 1px solid #ddd;
background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>