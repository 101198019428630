import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'easyHMO',
  storage: window.sessionStorage,
  reducer: (state) => ({ user: state.user, cluster: state.cluster, cache: state.cache })
})

export default createStore({
  state: {
    user: null,
    cluster: null,
    cache: [],
  },
  plugins: [vuexPersist.plugin],
  getters: {
  },
  mutations: {
    loadUser(state, payload) {
      state.user = payload;
    },
    loadCluster(state, payload) {
      state.cluster = payload;
    },
    loadCache(state, payload) {
      state.cache = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
