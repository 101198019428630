<template>
  <LoadingDiv v-if="isLoading" />

  <div v-else>
    <template v-if="encounter">
      <div class="card">
        <!-- Bio -->
        <div class="card-panel" :class="{ 'red lighten-5': encounter.status == 'd' }">
          <div class="row">
            <div class="col s1 grey lighten-4">
              <span class="chip light-blue lighten-5 center"><b>{{ curr }}/{{ total }}</b></span>
            </div>
            <div class="col s7">
              <p>{{ capWords(encounter.client_name) }}</p>
              <h5>{{ capWords(encounter.name) }} ({{ encounter.enrollee_number }})</h5>

              <span v-if="encounter?.encounter_date">
                <p>
                  <span class="orange-text text-darken-4">
                    {{
                      new Date(encounter?.encounter_date).toLocaleString(undefined, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}
                  </span>
                  ->
                  <span class="teal-text text-darken-4" v-if="encounter?.encounter_date_end">
                    {{
                      new Date(encounter?.encounter_date_end).toLocaleString(undefined, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}
                  </span>
                </p>
              </span>
              <span v-else>
                <p>Encounter Dates <em>not</em> set</p>
              </span>

            </div>
            <div class="col s4">
              <img :src="encounter.profile_img" width="96" class="circle responsive-img right"
                v-if="encounter.profile_img" />
              <img :src="`https://ui-avatars.com/api/?name=${encounter.name}&size=128`" width="96"
                class="circle responsive-img right" v-else />
            </div>
          </div>

          <!-- Show flag -->
          <template v-if="encounter?.compliance_flag == 'y'">
            <div class="card-content spacer10">
              <div class="row yellow lighten-5">
                <div class="col s12 spacer10">
                  <label class="active orange-text darken-text-2"><i class="tiny material-icons">flag </i> Compliance
                    Flagged</label>
                  <p>Kindly review claim for re-approval.</p>
                  <br />
                </div>
              </div>
            </div>
          </template>
        </div>

        <!-- Display Diagnosis -->
        <div id="display" class="row teal lighten-5">
          <div class="col s12">
            <div class="card-content yellow lighten-1">
              <span class="collections black-text">
                <h6>
                  <i class="tiny material-icons">bookmark_border</i>
                  Diagnosis: {{ encounter.diagnosis.toUpperCase() }}
                </h6>
              </span>
            </div>
          </div>
        </div>

        <!-- Claims Display Card -->
        <div class="card">

          <!-- Add -->
          <AddClaimSimple :turl="{ sid: forTariff?.scheme_id, pid: forTariff?.provider_id }" :reset="error" @update="handleAddClaimUpdate"
            v-if="adder && clusterStatus != 'v' && clusterStatus != 'r'" />

          <!-- Show -->
          <div class="card-content">

            <span class="card-title">Claims
              <span class="secondary-content chip clickable"
                :class="{ 'teal lighten-5 teal-text': !adder, 'orange lighten-5 orange-text': adder }"
                :title="adder ? 'close' : 'Add New Claim'" @click.prevent="handleAddClaim"
                v-if="clusterStatus != 'v' && clusterStatus != 'r'">
                <i class="material-icons">{{ adder ? 'close' : 'add_circle' }}</i>
              </span>
            </span>

            <!-- List claims -->
            <ol id="item-list" class="collection">
              <li class="collection-item" v-for="claim in encounter.claims" :key="claim.id">
                <template v-if="encounter.status == 'd'">

                  <!-- Deleted Encounter Items -->
                  <s>{{ capWords(claim.item) }} x {{ claim.sent_qty }} (Amount Sent: ₦{{
                    Number(claim.sent_price)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }})</s>
                  <b><span class="info-item tooltipped pointable" data-position="top" :data-tooltip="claim.comment">
                      <i class="material-icons grey-text">help</i>
                    </span></b>

                  <!-- Authorizations (delete) -->
                  <span v-if="claim.authorization == 'y'">
                    => <s> ({{ claim.authorization_code }}) </s>
                  </span>

                </template>

                <template v-else>
                  <!-- Approved Items -->
                  <span v-if="claim.status == 'a'">
                    <b>{{ capWords(claim.item) }}</b> x {{ claim.sent_qty }} (₦{{
                      Number(claim.sent_price)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }})

                    <!-- Reviewed claim -->
                    <span class="brown-text" v-if="claim.sent_price != claim.approved_price">
                      <b> =></b>

                      <span v-if="claim.sent_qty != claim.approved_qty"> x {{ claim.approved_qty }} </span>

                      <b> (₦{{
                        Number(claim.approved_price)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }})<span class="info-item tooltipped pointable" data-position="top"
                          :data-tooltip="capWords(claim.comment)">
                          <i class="material-icons grey-text">help</i>
                        </span></b>
                    </span>

                    <!-- Authorizations (manual) -->
                    <span v-if="claim?.authorization_code && claim.authorization != 'y'">
                      => ({{ claim.authorization_code }})
                    </span>

                    <!-- Authorizations (active) -->
                    <span v-if="claim.authorization == 'y'">
                      => ({{ claim.authorization_code }})
                      <span class="info-item tooltipped secondary-content pointable" data-position="top"
                        :data-tooltip="claim?.slug">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </span>

                    <!-- Actions -->
                    <template v-if="clusterStatus != 'v' && clusterStatus != 'r'">
                      <span v-if="
                        encounter.status != 'd' && claim.authorization != 'y'
                      ">
                        <a href="#" class="secondary-content cancel-item" @click.prevent="handleDelete(claim)"
                          title="decline"><i class="material-icons" :class="{
                            'grey-text': deleteClaim != claim,
                            'red-text': deleteClaim == claim,
                          }">cancel</i></a>
                        <a href="#" class="secondary-content edit-item" @click.prevent="handleEdit(claim)"
                          title="edit"><i class="material-icons" :class="{
                            'grey-text': editClaim != claim,
                            'blue-text': editClaim == claim,
                          }">edit</i></a>
                      </span>
                    </template>
                  </span>

                  <!-- Deleted Items -->
                  <span v-else>
                    <s>{{ capWords(claim.item) }} x {{ claim.sent_qty }} (Amount Sent: ₦{{
                      Number(claim.sent_price)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }})</s>
                    <b><span class="info-item tooltipped pointable" data-position="top"
                        :data-tooltip="capWords(claim.comment)">
                        <i class="material-icons grey-text">help</i>
                      </span></b>

                    <!-- Authorizations (delete) -->
                    <span v-if="claim.authorization == 'y'">
                      => ({{ claim.authorization_code }})
                      <span class="info-item tooltipped secondary-content pointable" data-position="top"
                        :data-tooltip="claim.slug">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </span>

                    <!-- Actions -->
                    <template v-if="clusterStatus != 'v' && clusterStatus != 'r'">
                      <span v-if="
                        encounter.status != 'd' && claim.authorization != 'y'
                      ">
                        <a href="#" class="secondary-content reset-item" @click.prevent="handleReset(claim)"
                          title="reset"><i class="small material-icons grey-text">refresh</i></a>
                      </span>
                    </template>
                  </span>
                </template>
              </li>
            </ol>

            <!-- Claims Submit -->
            <div v-if="claimsSubmitCard">
              <!-- Total -->
              <ul id="item-list" class="collection">
                <li class="collection-item">
                  <span class="right">
                    <em>(Sent: ₦{{ totalSent(encounter) }})</em> |

                    {{ encounter.status == "p" ? "Vetted" : "Approved" }}:
                    <b><span :class="{
                      'red-text': encounter.status == 'p',
                      'green-text text-darken-2': encounter.status != 'p',
                    }">₦{{ totalApproved(encounter) }}</span></b>

                    <br />
                  </span>
                </li>
              </ul>

              <br />

              <!-- Submit -->
              <template v-if="clusterStatus != 'v' && clusterStatus != 'r'">
                <a class="waves-effect waves-light btn-large right" :class="{ green: encounter.status == 'p' }"
                  @click.prevent="handleNext('approve')">
                  <i class="large material-icons right">{{
                    encounter.status == "p" ? "check_circle" : "chevron_right"
                  }} </i>{{ encounter.status == "p" ? "Approve" : "Next" }}
                </a>
                <a class="waves-effect waves-light btn-large black" @click.prevent="handleEncounterForDelete(encounter)"
                  v-if="encounter.status != 'd'"><i class="medium material-icons left">clear</i>Reject</a>
                <a class="waves-effect waves-light btn-large black" @click.prevent="handleReVet(encounter)" v-else><i
                    class="medium material-icons left">refresh</i>Re-vet</a>
              </template>

              <template v-else>
                <a class="waves-effect waves-light btn-large disabled black"><i
                    class="medium material-icons left">clear</i>Reject</a>

                <a class="waves-effect waves-light btn-large right" @click.prevent="handleBack"><i
                    class="large material-icons right">chevron_right</i>Okay</a>
              </template>
            </div>
          </div>

        </div>

        <!-- Edit Claims -->
        <div id="review" class="card" :class="{ 'teal lighten-5': !error, 'red lighten-5': error }" v-if="claimsCard">

          <div class="card-content" v-if="deleteClaim">
            <form>
              <div class="row" v-if="deleteHolder">
                <div class="input-field col s12">
                  <input v-model="comment" id="comment" type="text" />
                  <label class="active teal-text" for="comment">Comment</label>
                </div>
              </div>
              <div class="row" v-else>
                <div class="input-field col s8">
                  <input v-model="item" id="item" type="text" readonly />
                  <label class="active" for="qty">Item</label>
                </div>
                <div class="input-field col s4">
                  <input v-model="amt" id="amt" type="number" readonly />
                  <label class="active" for="amt">Amount</label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <a class="waves-effect waves-light btn black lighten-2" @click.prevent="handleRefresh">
                    <i class="medium material-icons left">chevron_left</i>Back
                  </a>

                  <button class="waves-effect waves-light btn right" :class="{ red: deleteHolder }"
                    @click.prevent="handleClaimDelete">
                    <i class="medium material-icons right">chevron_right</i>
                    {{ deleteHolder ? "Delete" : "Confirm Item" }}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-content" v-if="editClaim">
            <form>
              <div class="row" v-if="editHolder">
                <div class="input-field col s12">
                  <input v-model="comment" id="comment" type="text" />
                  <label class="active teal-text" for="comment">Comment</label>
                </div>
              </div>
              <div class="row" v-else>
                <div class="input-field col s8">
                  <input v-model="item" id="item" type="text" readonly />
                  <label class="active" for="qty">Item</label>
                </div>
                <div class="input-field col s2 teal lighten-5">
                  <input v-model="qty" @input="handlePricing" id="qty" type="number" />
                  <label class="active teal-text" for="qty">Quantity</label>
                </div>
                <div class="input-field col s2">
                  <input v-model="amt" id="amt" type="number" readonly />
                  <label class="active" for="amt">Amount (Approval)</label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <a class="waves-effect waves-light btn black lighten-2" @click.prevent="handleRefresh">
                    <i class="medium material-icons left">chevron_left</i>Back
                  </a>

                  <button class="waves-effect waves-light btn green right" @click.prevent="handleClaimEdit">
                    <i class="medium material-icons right">chevron_right</i>
                    Okay
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-content" v-if="deleteEncounter">
            <form>
              <div class="row">
                <div class="input-field col s12">
                  <input v-model="comment" id="comment" type="text" />
                  <label class="active teal-text" for="comment">Comment (Rejection Reason)</label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <a class="waves-effect waves-light btn black lighten-2" @click.prevent="handleRefresh">
                    <i class="medium material-icons left">chevron_left</i>Back
                  </a>

                  <button class="waves-effect waves-light btn red right" @click.prevent="handleEncounterDelete">
                    <i class="medium material-icons left">highlight_off</i>
                    Confirm Reject
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>

      </div>
    </template>

    <div class="section center" v-if="!encounter">
      <h6>No Claims Encounter <em>found</em>...</h6>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watchEffect, watch } from "vue";
import { useRouter } from "vue-router";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import AddClaimSimple from "@/components/claim/AddClaimSimpleComponent.vue";
import { capitalizeFirstLetter, capWordsFx, } from "@/composables/fxLibrary/coreFx.js";
import {
  totalSentFx,
  totalApprovedFx,
} from "@/composables/calFunction/cal";
import M from "materialize-css";

export default {
  name: "VetClaimComponent",
  components: { LoadingDiv, AddClaimSimple },
  props: ["forTariff", "clusterStatus", "cluster", "encounterId"],
  emits: ["vetted"],
  setup(props, context) {
    const response = ref(null);
    const responseAll = ref([]);
    const isLoading = ref(true);
    const error = ref(false);
    const curr = ref(0);
    const total = ref(0);
    const deleteClaim = ref(null);
    const deleteHolder = ref(false);
    const editClaim = ref(null);
    const editHolder = ref(false);
    const deleteEncounter = ref(null);
    const claimsCard = ref(false);
    const claimsDisplayCard = ref(true);
    const claimsSubmitCard = ref(true);
    const claimsList = ref([]);
    const item = ref(null);
    const qty = ref(null);
    const amt = ref(null);
    const standard = ref(null);
    const comment = ref(null);
    const adder = ref(null);

    // vue router
    const router = useRouter();

    // toolTip
    const toolTip = () => {
      var elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems, {});
    };

    // restart toolTip
    const restartComments = () => {
      // load comment
      setTimeout(() => {
        toolTip();
      }, 2000);
    };

    // load after data loads and updates
    watchEffect(() => {
      if (response.value) {
        restartComments();
      }
    });

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // total calculations (sent)
    const totalSent = (encounter) => {
      return totalSentFx(encounter);
    };

    // total calculations (approved)
    const totalApproved = (encounter) => {
      return totalApprovedFx(encounter);
    };

    // next Unvetted
    onMounted(() => {
      if (props.cluster) {
        // start
        responseAll.value = props.cluster;
        // total
        total.value = responseAll.value.length;
        // next
        updateClusterData(responseAll.value);
      }
    });

    // starter
    const updateClusterData = (val) => {
      if (val) {
        // check if available for vetting
        val.forEach((x, y) => {
          if (x.id == props.encounterId) {
            // (seen) good to go
            response.value = x;
            // number
            curr.value = y + 1;
          }
        });
        if (!response.value) {
          // next
          handleNext("next");
        }
      }
      // end load
      isLoading.value = false;
    };

    // clear inputs
    const claimInputClear = () => {
      // hide show cards
      claimsCard.value = false;
      claimsSubmitCard.value = true;

      // clear all
      item.value = null;
      qty.value = null;
      amt.value = null;
      standard.value = null;
      comment.value = null;

      // clear delete (if needed)
      deleteClaim.value = null;
      // clear editHolder
      deleteHolder.value = false;
      // clear edit (if needed)
      editClaim.value = null;
      // clear editHolder
      editHolder.value = false;
      // clear encounter delete
      deleteEncounter.value = null;
      // clear error (if needed)
      error.value = false;

      // retart Comments
      restartComments();
    };

    // watch 
    // slide between review and display
    watch(item, (newValue) => {
      if (newValue) {
        router.push({ hash: "#review" });
      } else {
        router.push({ hash: "#display" });
      }
    });

    // add new claim
    const handleAddClaim = () => {
      if (adder.value) {
        adder.value = false;
      } else {
        adder.value = true;
      }
      router.push({ hash: "#display" });
    }

    // add only approved
    // sent set to zero
    const handleAddClaimUpdate = async (val) => {

      if (val?.id === 'clearAuth') {
          // recall handleClaim
          handleAddClaim();
      } 

      if (val?.id === 'addNewAuth') {

        // clear all errors first
        error.value = false;

        // update
        let newVal = {
          encounter_id: response.value.id,
          item: val.value.claim,
          sent_qty: 0,
          sent_price: 0,
          approved_qty: val.value.qty,
          approved_price: val.value.amt,
          standard_price: val.value.standard,
          comment: "New Item (added by HMO)",
          status: "a",
        };

        // post update
        const { res, err } = await postItem("claim", newVal);

        if (res.value) {
          // success
          response.value.claims.push({
            ...res.value.data,
          });

          //reset comments manually
          restartComments();

          // clear
          handleAddClaim();

        } else {
          error.value = err.value;
        }


      }


    }

    // edit claim
    const handleEdit = (claim) => {
      if (claim != editClaim.value) {
        // clear all
        claimInputClear();

        // hide show cards
        claimsCard.value = true;
        claimsSubmitCard.value = false;

        // for main
        editClaim.value = claim;
        item.value = claim.item;
        qty.value = claim.approved_qty;
        amt.value = claim.approved_price;
        standard.value = claim.standard_price;
      } else {
        // same item was clicked twice
        // clear all
        claimInputClear();
      }
    };

    // delete claim
    const handleDelete = (claim) => {
      if (claim != deleteClaim.value) {
        // clear all
        claimInputClear();

        // hide show cards
        claimsCard.value = true;
        claimsSubmitCard.value = false;

        // for main
        deleteClaim.value = claim;
        item.value = claim.item;
        qty.value = claim.sent_qty;
        amt.value = claim.sent_price;
        standard.value = claim.standard_price;
      } else {
        // same item was clicked twice
        // clear all
        claimInputClear();
      }
    };

    // prepare delete encounter
    const handleEncounterForDelete = (encounter) => {
      // clear all
      claimInputClear();

      // hide show cards
      claimsCard.value = true;
      claimsSubmitCard.value = false;

      // for main
      deleteEncounter.value = encounter;
    };

    // re-vet
    const handleReVet = (encounter) => {
      // clear all
      claimInputClear();

      // restore
      if (response.value == encounter) {
        response.value.claims.forEach((x) => {
          // all
          x.status = "a";
          x.approved_qty = null; // insurance (should be null already) // encounter computed will fix temp
          x.approved_price = null; // insurance (should be null already) // encounter computed will fix temp
          x.comment = null;

          // update authorization y,z with stores
          if (x.authorization !== "n") {
            x.approved_qty = x.stores?.approved_qty;
            x.approved_price = x.stores?.approved_price;
            x.comment = x.stores?.comment;
          }

        });
        response.value.status = "p"; // switch back to pending
      }
    };

    // reset claim
    const handleReset = (claim) => {
      // edit
      const index = response.value.claims.findIndex((x) => x.id === claim.id);
      if (index !== -1) {
        // claim status = a = active
        response.value.claims[index] = {
          ...response.value.claims[index],
          status: "a",
        };
        // encounter status = p = pending vetting
        response.value.status = "p";

        // clear entry
        claimInputClear();
      }
    };

    // load claims
    const handleClaimDelete = () => {
      if (deleteHolder.value && comment.value) {
        // edit
        const index = response.value.claims.findIndex(
          (x) => x.id === deleteClaim.value.id
        );
        if (index !== -1) {
          // claim status = d = delete
          response.value.claims[index] = {
            ...response.value.claims[index],
            approved_qty: null,
            approved_price: null,
            comment: comment.value,
            status: "d",
          };
          // encounter status = p = pending vetting
          response.value.status = "p";

          // clear entry
          claimInputClear();
        }
      } else {
        // input default
        comment.value = "Not applicable";
        deleteHolder.value = true;

        // reset error (if required)
        error.value = false;
      }
    };

    // load claims
    const handleClaimEdit = () => {
      // start action
      if (item.value && qty.value && amt.value) {
        if (
          (editHolder.value && comment.value) ||
          Number(amt.value) >= Number(editClaim.value.sent_price) // proceed to reload approved amounts if new amount exceeds sent amount // sent amount will be validated below with standard and qty
        ) {
          // format for saving
          const { fx } = capitalizeFirstLetter(item.value);

          if (editClaim.value) {
            // edit
            const index = response.value.claims.findIndex(
              (x) => x.id === editClaim.value.id
            );
            if (index !== -1) {
              // found record
              // check if amount is greater 
              // base price or sent price - whichever is smaller
              let baseAmount = response.value.claims[index].sent_qty * response.value.claims[index].standard_price;
              if (response.value.claims[index].sent_price < baseAmount) { baseAmount = response.value.claims[index].sent_price }
              // continue logic
              if (
                Number(amt.value) >
                Number(baseAmount)
              ) {
                // reload approved price & qty
                response.value.claims[index] = {
                  ...response.value.claims[index],
                  item: fx.value,
                  approved_qty: response.value.claims[index].approved_qty,
                  approved_price: response.value.claims[index].approved_price,
                  comment: null,
                };
              } else {
                // load vetted price & qty
                response.value.claims[index] = {
                  ...response.value.claims[index],
                  item: fx.value,
                  approved_qty: qty.value,
                  approved_price: amt.value,
                  comment: comment.value,
                };
              }
              // encounter status = p = pending vetting
              response.value.status = "p";
            }
          }

          // clear entry
          claimInputClear();

          // reset error (if required)
          error.value = false;
        } else {
          // input default
          if (editClaim.value.comment) {
            comment.value = editClaim.value.comment;
          } else {
            comment.value = "Over Prescription";
          }
          editHolder.value = true;

          // reset error (if required)
          error.value = false;
        }
      } else {
        error.value = true;
      }
    };

    // reject encounter
    const handleEncounterDelete = () => {
      if (deleteEncounter.value && comment.value) {

        // call handle next
        handleNext("reject");

      } else {
        // set error
        error.value = true;
      }
    };

    // watch qty & price
    const handlePricing = () => {
      amt.value = (Number(qty.value) * Number(standard.value)).toFixed(2);
    };

    // refresh field
    const handleRefresh = () => {
      // clear entry
      claimInputClear();
    };

    // handle back
    const handleBack = () => {
      // done
      context.emit("vetted", {
        status: "back",
      });
    };

    // handle approve
    const handleNext = async (val) => {
      // starter
      isLoading.value = true;

      // approve workflow
      if (val == "approve") {
        // start
        let newVal = {
          encounter: { id: response.value.id, status: "v" },
          claims: response.value.claims,
        };

        // post update
        const { res, err } = await postItem("claim/update/bulk", newVal);

        if (res.value) {
          // mark
          response.value.status = "v";
        } else {
          error.value = err.value;
        }
      }

      // reject workflow
      if (val == "reject") {
        // empty object
        let stores = {};
        // response copy
        let responseCopy = response.value;

        // decline responseCopy
        responseCopy.status = "d";

        // decline workflow
        responseCopy.claims.forEach((x) => {
          // keep authorization z and y in stores (for reference)
          if (x.authorization !== "n") {
            if (x.stores) { stores = x.stores }
            if (x.authorization === "y") { stores.approved_price = x.approved_price }
            stores.approved_qty = x.approved_qty;
            stores.comment = x.comment;
            // reload stores
            x.stores = stores;
          }
          // wholesale update
          x.approved_qty = null;
          x.approved_price = null;
          x.comment = comment.value;
          x.status = "d";
        });

        // start
        let newVal = {
          encounter: { id: responseCopy.id, status: responseCopy.status },
          claims: responseCopy.claims,
        };

        // post update
        const { res, err } = await postItem("claim/update/bulk", newVal);

        if (res.value) {
          // mark
          response.value = responseCopy;
        } else {
          error.value = err.value;
        }

      }

      // update
      if (val != "next") {
        // next is from onMounted!
        responseAll.value.forEach((x) => {
          if (x.id == response.value.id) {
            x = response.value; // update
          }
        });

        // clear regardless
        response.value = null;
      }

      // NEXT
      // CLAIM
      // SELECTED 
      // HERE
      responseAll.value.forEach((x, y) => {
        if (x.status === "p" && response.value?.status !== "p") {
          // good to go
          response.value = x;
          // number
          curr.value = y + 1;
        }
      });

      // route
      if (!response.value) {
        // done
        context.emit("vetted", {
          status: "success",
          value: responseAll.value,
        });
      }

      isLoading.value = false; // end
    };

    // output encounter results
    const encounter = computed(() => {
      if (!response.value) {
        return null;
      } else {
        // billing insurance 
        // (if everything else fails, this wont!)
        // for pending encounters only
        response.value.claims.forEach((x) => {
          if (!x.approved_price && response.value.status === 'p') {
            if ((x.sent_price) > (x.sent_qty * x.standard_price)) {
              x.approved_qty = x.sent_qty;
              x.approved_price = x.sent_qty * x.standard_price;
              x.comment = "Over Billing";
            } else {
              x.approved_qty = x.sent_qty;
              x.approved_price = x.sent_price;
            }
          }
        });

        return response.value;
      }
    });

    return {
      capWords,
      handleAddClaimUpdate,
      handleAddClaim,
      handlePricing,
      handleEdit,
      handleReset,
      handleDelete,
      handleReVet,
      handleEncounterForDelete,
      handleEncounterDelete,
      handleClaimDelete,
      handleClaimEdit,
      handleRefresh,
      handleNext,
      handleBack,
      totalSent,
      totalApproved,
      curr,
      total,
      deleteClaim,
      deleteHolder,
      editClaim,
      editHolder,
      deleteEncounter,
      isLoading,
      error,
      claimsCard,
      claimsDisplayCard,
      claimsSubmitCard,
      claimsList,
      item,
      qty,
      amt,
      standard,
      comment,
      adder,
      encounter,
    };
  },
};
</script>

<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.custom-hr {
  width: 50%;
  /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc;
  /* Adjust the color and style as needed */
  margin: 20px 20px;
  /* Adjust margin as needed */
}

.counter {
  display: block;
  padding: 0.5em;
  /* Adjust padding to your liking */
  /* font-size: 1em;  Adjust font size */
}

.clickable {
  cursor: pointer;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  /* opacity: 1; Default opacity */
  /* transition: opacity 0.3s ease;  Smooth opacity change */
}

/* .pointable:hover {
    opacity: 0.8; Slightly reduced opacity on hover
  } */
</style>