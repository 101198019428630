<template>
  <!-- UI -->
  <nav class="custom-nav" :class="{ 'grey': !color, [color]: color }"></nav>

  <nav class="nav-extended" :class="{ 'grey': !color, [color]: color }">
    <div class="nav-wrapper container">
      <a href="#!" class="brand-logo" @click.prevent="handleActive(home)">{{ home?.name }}</a>
      <ul class="right hide-on-med-and-down">
        <li v-for="tab in tabs" :key="tab.id" :class="{ 'active indigo lighten-2': tab.id == currTab.id }"><a href="#"
            @click.prevent="handleActive(tab)">{{ tab.title
            }}</a>
        </li>
      </ul>
    </div>
    <div class="nav-content container">
      <br />
    </div>
  </nav>

</template>

<script>

export default {
  name: "HeaderSubComponent",
  emits: ["active"],
  props: ["home", "tabs", "currTab", "color"],
  setup(props, context) {

    const handleActive = (val) => {
      context.emit("active", val);
    }

    return {
      handleActive,
    };
  },
};
</script>

<style scoped>
.divider-lift {
  margin-top: -12px;
}

.custom-nav {
  height: 15px;
}
</style>