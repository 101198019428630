<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <!-- search layer -->
  <div class="grey lighten-4">
    <div class="container section"></div>
  </div>


  <div class="container">
    <!-- <br /> -->

    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <EnrolleeAuth @restart="handleRestart" :enrollee="enrollee" :serverDate="serverDate" v-if="enrollee" />
      <StartAuth @next="handleNext" v-else />
    </template>
  </div>
</template>


<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import SubHeading from "@/components/HeaderSubDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import StartAuth from "@/components/auth/StartAuthComponent.vue";
import EnrolleeAuth from "@/components/auth/EnrolleeAuthComponent.vue";


export default {
  name: "authAdd",
  components: { SubHeading, LoadingDiv, StartAuth, EnrolleeAuth },
  setup() {
    const back = ref("auth"); // default
    const isReady = ref(true);
    const enrollee = ref(null);
    const serverDate = ref(null);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Add Authorization | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Pre-Auth", title: "Home", icon: "arrow_back", url: "auth", hash: null, });
      tabs.value.push({ id: 2, name: "Pre-Auth", title: "Review", icon: "edit", url: "auth", hash: "#review" });
      tabs.value.push({ id: 3, name: "Pre-Auth", title: "Add Auth", icon: null, url: "authAdd", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Add"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // // handle back
    // const handleBack = () => {
    //   if (back.value == "auth") {
    //     router.push({ name: "auth" }); // final back
    //   } else if (back.value == "authAdd") {
    //     handleRestart(); // restart auth
    //   } else {
    //     router.push({ name: "home" }); // safeguard to prevent unexpected errors
    //   }
    // };

    // handle next
    const handleNext = (val) => {
      enrollee.value = val.value;
      serverDate.value = val.serverDate;
      back.value = "authAdd";
    };

    // handle restart
    const handleRestart = () => {
      enrollee.value = null;
      serverDate.value = null;
      back.value = "auth";
    };


    return {
      handleActive,
      home,
      serverDate,
      currTab,
      tabs,
      handleNext,
      handleRestart,
      isReady,
      enrollee,
    };
  },
};
</script>

<style></style>