<template>
    <div class="section">

        <!-- Modal Structure -->
        <div class="modal-content">
            <h4>{{ title }}</h4>
            <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
            <a href="#!" @click.prevent="handleOk()"
                class="modal-close waves-effect waves-green btn-flat">Ok, I Understand</a>
        </div>

    </div>
</template>

<script>
// import { onMounted, onUpdated } from "vue";
// import M from "materialize-css";

export default {
    name: "modalComponent",
    props: ["title", "message"],
    emits: ["ok"],
    setup(props, context) {

        // // alert
        // onMounted(() => {
        //     // modal
        //     const elems = document.querySelectorAll(".modal");
        //     M.Modal.init(elems, {});
        // });

        // // alert
        // onUpdated(() => {
        //     // modal
        //     const elems = document.querySelectorAll(".modal");
        //     M.Modal.init(elems, {});
        // });

        // Confirmation
        const handleOk = () => {
            context.emit("ok", true);
        }


        return {
            handleOk,
        };
    },
};
</script>

<style></style>