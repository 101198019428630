<template>
  <!-- Loading -->
  <div class="progress" v-if="isLoading">
    <div class="indeterminate"></div>
  </div>

  <!-- Display claims -->
  <template v-else>
    <div class="card yellow lighten-5">
      <div class="card-content white">
        <table class="highlight responsive-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name<br /><small>(Enrollee ID)</small></th>
              <th>Diagnosis</th>
              <th>Sent</th>
              <th>Vetted</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Claims Summary -->
            <template v-for="(encounter, index) in encounters" :key="encounter.id">
              <tr class="clickable" @click.prevent="handleShow(encounter)">
                <template v-if="encounter.status == 'd'">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <s>{{ capWords(encounter.name) }}</s><br />
                    <s>({{ encounter.enrollee_number }})</s>
                  </td>
                  <td>
                    <s>{{ capWords(encounter.diagnosis) }}</s>
                  </td>
                  <td>
                    <s>
                      ₦{{
                        Number(encounter.sent)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </s>
                  </td>
                  <td>
                    <s>₦{{ totalApproved(encounter) }}</s>
                  </td>
                  <td>
                    <i class="material-icons grey-text text-lighten-2 pointable" title="removed">do_not_disturb
                    </i>
                  </td>
                </template>

                <template v-else>
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ capWords(encounter.name) }}<br />
                    ({{ encounter.enrollee_number }})
                  </td>
                  <td>{{ capWords(encounter.diagnosis) }}</td>
                  <td>₦{{ totalSent(encounter) }}</td>
                  <td>₦{{ totalApproved(encounter) }}</td>
                  <td @click.prevent="handleFlag(encounter)">
                    <i class="material-icons clickable" :class="{
                      'grey-text text-lighten-2':
                        encounter.supervisor_flag == 'n',
                      'red-text': encounter.supervisor_flag == 'y',
                    }" :title="getTitle(encounter)">flag
                    </i>
                  </td>
                </template>
              </tr>

               <!-- Claims details -->
               <template v-if="currEncounter == encounter">
                <tr v-for="claim in encounter.claims" :key="claim.id">
                  <td><br /></td>
                  <td><br /></td>
                  <td class="secondary-content black-text">
                    <span v-if="claim.status == 'd' || encounter.status == 'd'"><s>{{ claim.item }}</s></span>
                    <span v-else>{{ claim.item }}</span>
                  </td>
                  <td class="orange-text text-darken-2">
                    <span v-if="claim.status == 'd' || encounter.status == 'd'"><s>₦{{
                      Number(claim.sent_price)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                    </s></span>
                    <span v-else>₦{{
                      Number(claim.sent_price)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                      <span>({{ claim.sent_qty }})</span></span>  
                  </td>
                  <td class="cyan-text text-darken-4">
                    <span v-if="claim.status == 'd' || encounter.status == 'd'"><s>₦{{
                      Number(claim.approved_price)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                      </s></span>
                    <span v-else>₦{{
                      Number(claim.approved_price)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                      <span>({{ claim.approved_qty }})</span></span>
                  </td>
                  <td>
                    <br />
                  </td>
                </tr>
              </template>
            </template>

            <!-- Final total -->
            <tr>
              <td colspan="2"><br /></td>
              <td>Total</td>
              <td>
                <b>₦{{ grossTotalSent(encounters) }}</b>
              </td>
              <td class="green-text text-darken-2">
                <h5>₦{{ grossTotalApproved(encounters) }}</h5>
              </td>
              <td colspan="1"><br /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Submit -->
    <div class="card-content white">
      <button class="waves-effect waves-light btn-large black right" :class="{
        disabled: isTalking || isSubmitting,
      }" @click="handleSubmit('return')" v-if="encounters.filter((x) => x.supervisor_flag == 'y').length">
        <i class="medium material-icons red-text right">chevron_left</i>
        {{ isSubmitting ? "Returning Claims" : "Request (Flagged) Re-vet" }}
      </button>
      <button class="waves-effect waves-light btn-large teal right" :class="{
        disabled: isTalking || isSubmitting,
      }" @click="handleSubmit('approve')" v-else>
        <i class="medium material-icons right">check_circle</i>{{ isSubmitting ? "Passing Claims" : "Pass Claims (To HOD)" }}
      </button>
    </div>
  </template>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import {
  totalSentFx,
  totalApprovedFx,
  grossTotalSentFx,
  grossTotalApprovedFx,
} from "@/composables/calFunction/cal";
import {
  capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";
import getItemsOfItems from "@/composables/getItemsOfItems";
import putItem from "@/composables/putItem";

export default {
  name: "SingleClaimComponent",
  props: ["clusterId"],
  emits: ["reset"],
  setup(props, context) {
    const response = ref(null);
    const currEncounter = ref(false);
    const error = ref(null);
    const isTalking = ref(0);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(true);
    const currUrl = ref(null);
    const encounterId = ref(0);

    // get title
    const getTitle = (val) => {
      if (val.supervisor_flag == "n") {
        return "Flag claim";
      } else {
        return "Un-flag";
      }
    };

     //from functions
     const capWords = (val) => {
      return capWordsFx(val);
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      return grossTotalSentFx(encounters);
    };

    // total calculations (gross approved)
    const grossTotalApproved = (encounters, db) => {
      return grossTotalApprovedFx(encounters, db);
    };

    // total calculations (sent)
    const totalSent = (encounter) => {
      return totalSentFx(encounter);
    };

    // total calculations (approved)
    const totalApproved = (encounter) => {
      return totalApprovedFx(encounter);
    };

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "encounter/cluster";
      // get items from function
      getItemsFromApi(currUrl.value, props.clusterId);
    });

    // show claims
    const handleShow = (val) => {
      if (currEncounter.value == val) {
        currEncounter.value = null;
      } else {
        currEncounter.value = val;
      }
    };

    // flag
    const handleFlag = async (val) => {
      // talk care of multiple clicks
      isTalking.value++;

      // set new flag
      let newFlag = "y";
      let newComment = "Supervisor flagged this Encounter for re-vetting.";
      let newStatus = "p";
      if (val.supervisor_flag == newFlag) {
        newFlag = "n";
        newComment = null;
        newStatus = "v";
      }

      // update server
      let newVal = {
        supervisor_flag: newFlag,
        supervisor_comment: newComment,
        status: newStatus,
      };

      // update first (twitter style)
      response.value.forEach((x, y) => {
        if (x.id == val.id) {
          return (response.value[y] = {
            ...x,
            supervisor_flag: newFlag,
            supervisor_comment: newComment,
            status: newStatus,
          });
        }
      });

      // put update
      const { res, err } = await putItem("encounter", val.id, newVal);

      if (res.value) {
        // send reset value to parent
        context.emit("reset", {
          clusterId: props.clusterId,
          encounterId: val.id,
          newFlag: newFlag,
          newComment: newComment,
          newStatus: newStatus,
        });
        // clear show claims
        currEncounter.value = null;
        // talk care of multiple clicks
        isTalking.value--;
      } else {
        error.value = err.value;
      }
    };

    // review
    const handleSubmit = async (val) => {
      // start
      isSubmitting.value = true;

      // set update
      let newVal = null;

      if (val == "approve") {
        // add paymentId on server
        newVal = {
          status: "v", // leave as usual (for safeguard)
          level: 5, // change to store.state.user.level
        };
      } else if (val == "return") {
        newVal = {
          status: "p", // change to "p (pending)"
          level: 3, // hardcode for security (returns to supervisor)
        };
      }

      // put update
      const { res, err } = await putItem(
        "cluster/supervisor/review",
        props.clusterId,
        newVal
      );

      if (res.value) {
        // send reset value to parent
        context.emit("reset", "reload");
      } else {
        error.value = err.value;
      }

      // end submit
      isSubmitting.value = false;
    };

    // output results
    const encounters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleShow,
      handleFlag,
      handleSubmit,
      capWords,
      grossTotalSent,
      grossTotalApproved,
      totalApproved,
      totalSent,
      getTitle,
      isReady,
      isTalking,
      isLoading,
      isSubmitting,
      currEncounter,
      error,
      encounterId,
      encounters,
    };
  },
};
</script>

<style scoped>
.custom-hr {
  width: 50%;
  /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc;
  /* Adjust the color and style as needed */
  margin: 20px 20px;
  /* Adjust margin as needed */
}

.progress {
  margin-top: -15px;
  /* Adjust the value as needed to move the progress bar upwards */
}

.styled-text {
  background-color: #fafad2;
  /* Light Golden Yellow */
  /* border: 2px solid #000; Black border */
  padding: 2px;
  /* Adequate padding */
}

.capsule {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: #000000 !important;
}

.pointable {
  cursor: default;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.info-item:hover .material-icons.grey-text,
.info-item:hover .material-icons.grey-text::before {
  color: rgb(0, 0, 0) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.reset-item:hover .material-icons.grey-text,
.reset-item:hover .material-icons.grey-text::before {
  color: rgb(23, 124, 40) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}
</style>