<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="grey-bg-custom">
      <div class="container">
        <div class="row">
          <div class="col s12">

            <div class="section">
              <h5 class="header">
                Private & Social <b>Plan(s) </b>
                <span @click="next = !next">
                  <span class="btn-floating btn-large waves-effect waves-light" :class="{ red: !next, teal: next }"><i
                      class="material-icons" title="Add Scheme (New)">{{
                        next ? "cancel" : "add"
                      }}</i></span>
                </span>
              </h5>
            </div>

            <template v-if="!next">
              <PolicyEditDiv :policy="editPolicy" @complete="handleUpdateComplete" v-if="editPolicy" />

              <template v-else>
                <!-- Selected -->
                <div class="card-panel" v-for="(plan, id) in plans" :key="plan.id">
                  <div class="row" :class="{ 'yellow lighten-2': plan.status == 's' }">
                    <div class="col s1">
                      <span class="chip grey darken-2 white-text">{{
                        id + 1
                      }}</span>
                    </div>
                    <div class="col s9">
                      <h5>
                        {{ plan.name }}
                      </h5>
                      <p><span class="chip"
                          :class="{ 'teal lighten-4': plan.scheme.type === 'p', 'orange lighten-4': plan.scheme.type === 's', }">{{
                            plan.description }}</span></p>
                      <h6>
                        Individual: ₦{{ toPremium(plan.premiums, 'i') }}
                      </h6>
                      <h6>Family: ₦{{ toPremium(plan.premiums, 'f') }}</h6>
                      <p>
                        (<b>Benefits</b>) {{ plan.benefits.length }} line items
                        defined
                        <i class="material-icons clickable" :class="{
                          'red-text': showBenefit == plan.id,
                          'teal-text': !(showBenefit == plan.id),
                        }" title="view all" @click="showBenefits(plan)">{{
                          showBenefit == plan.id ? "cancel" : "visibility"
                        }}</i>
                      </p>
                    </div>
                    <div class="col s2">
                      <div class="right">
                        <a class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2"><i
                            class="material-icons" title="edit plan" @click="handleEdit(plan)">edit</i></a>
                      </div>
                      <p><br /></p>
                      <div class="right" v-if="plan.scheme.type === 'p'">
                        <a class="btn-floating btn-medium waves-effect waves-light" :class="{
                          'red lighten-2': plan.status == 'a',
                          'green lighten-2': plan.status == 's',
                        }"><i class="material-icons" :title="plan.status == 'a'
                          ? 'suspend plan'
                          : 'activate plan'
                          " @click="handleStatus(plan)">{{
                              plan.status == "a"
                                ? "pause_circle_outline"
                                : "play_circle_filled"
                            }}</i></a>
                      </div>
                    </div>
                  </div>

                  <div class="row teal lighten-5" v-if="showBenefit == plan.id">
                    <span v-for="(benefit, y) in plan.benefits" :key="benefit.id">
                      <div class="col s1"><br /></div>
                      <div class="col s11">
                        <h6>
                          {{ y + 1 }}. {{ benefit.item }}
                          <span class="secondary-content grey lighten-2 chip">{{ benefit.description }}</span>
                        </h6>
                      </div>
                    </span>
                  </div>
                </div>

                <!-- Empty -->
                <div v-if="!plans.length">
                  <span class="chip">No Plan added</span>
                </div>
              </template>
            </template>

            <template v-else>
              <div class="section">
                <div class="card grey lighten-4">
                  <!-- Heading -->
                  <div class="card-content">
                    <span class="card-title teal-text">Add Shelf Policy<i class="material-icons right"
                        v-if="plans.length">check_circle</i></span>
                  </div>

                  <!-- Register Policy -->
                  <div class="card-panel teal-text">
                    <div class="row">
                      <div class="col s12">
                        <span class="black-text">Policy Name</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s12" :class="{ 'red lighten-5': error && !policyName }">
                        <div class="input-field">
                          <input type="text" id="policyName" v-model="policyName" />
                          <label for="policyName">Policy</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Premium -->
                  <div class="card-panel teal-text">
                    <div class="row">
                      <div class="col s12">
                        <span class="black-text">Premiums</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s1"><span class="chip">₦</span></div>
                      <div class="col s4" :class="{
                        'red lighten-5': error && !premiumIndividual,
                      }">
                        <div class="input-field">
                          <input type="number" id="premiumIndividual" v-model="premiumIndividual" class="validate"
                            required />
                          <label class="active" for="premiumIndividual">Premium (Individual)</label>
                        </div>
                      </div>

                      <div class="col s1"></div>

                      <div class="col s1"><span class="chip">₦</span></div>
                      <div class="col s4" :class="{ 'red lighten-5': error && !premiumFamily }">
                        <div class="input-field">
                          <input type="number" id="premiumFamily" v-model="premiumFamily" class="validate" required />
                          <label class="active" for="premiumFamily">Premium (Family)</label>
                        </div>
                      </div>

                      <div class="col s1"></div>
                    </div>
                  </div>

                  <!-- Benefits -->
                  <div class="card-panel teal-text">
                    <div class="row">
                      <div class="col s12">
                        <span class="black-text">Plan</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s12" :class="{
                        'red lighten-5': error && !file,
                      }">
                        <div class="file-field input-field">
                          <div class="btn" :class="{ disabled: isSubmitting }">
                            <span>Benefits</span>
                            <input type="file" @change="handleFile" />
                          </div>
                          <div class="file-path-wrapper">
                            <input class="file-path" v-model="inputFile" type="text" />
                            <p class="red-text" v-if="fileError">
                              {{ fileError }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="section center">
                <p>
                  <a class="waves-effect waves-light btn-large grey" :class="{
                    disabled: isSubmitting,
                  }" @click="handleBack">
                    <i class="material-icons left">chevron_left</i>Back
                  </a>
                  <button class="waves-effect waves-light btn-large black" :class="{
                    disabled: isSubmitting,
                  }" @click="handleAdd">
                    <i class="material-icons left">add</i>
                    {{ isSubmitting ? `Adding (${uploadProgress})` : "Add" }}
                  </button>
                </p>
              </div>
            </template>

            <template v-if="!next && !editPolicy">
              <div class="section center">
                <p>
                  <br /><br /><br /><br />
                  <button class="waves-effect waves-light btn-large" @click="handleNext">
                    <i class="material-icons right">call_made</i>Submit
                  </button>
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>

    </div>
  </template>
</template>

<script>
import { ref, computed, watchEffect, onMounted } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItems from "@/composables/getItems";
import useStorage from "@/composables/useStorage";
import putItem from "@/composables/putItem";
import PolicyEditDiv from "@/components/client/onboard/PolicyEditOnboardComponent.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "PlanSetupComponent",
  components: { PolicyEditDiv, LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    const response = ref(null);
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isReady = ref(false);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(null);
    const next = ref(false);
    const policy = ref([]);
    const policyName = ref(null);
    const premiumIndividual = ref(null);
    const premiumFamily = ref(null);
    const showBenefit = ref(null);
    const editPolicy = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // toMoney
    const toMoney = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // to premium
    const toPremium = (premiums, type) => {
      if (premiums) {
        let selectedPremium = premiums.find(x => x.type === type);
        return toMoney(selectedPremium.amount);
      }
    }

    // show benefits
    const showBenefits = (val) => {
      if (val.id != showBenefit.value) {
        showBenefit.value = val.id;
      } else {
        showBenefit.value = null;
      }
    };

    // start
    onMounted(() => {
      // get items
      loadPlans();
    });

    // handle plans loader
    const loadPlans = () => {
      // loasder on
      isReady.value = false;
      // start
      (async () => {
        const { res, err } = await getItems("plan/setup");

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    }

    // edit policy
    const handleEdit = (val) => {
      if (val) {
        // sort
        let newVal = val;
        newVal.premiumIndividual = val.premiums.find(x => x.type === "i").amount;
        newVal.premiumFamily = val.premiums.find(x => x.type === "f").amount;
        // load
        editPolicy.value = newVal;
      }
    };

    // edit status
    const handleStatus = async (val) => {

      // only private plans can be suspended here
      if (val.scheme.type === 'p') {

        // start
        isLoading.value = true;

        // value
        let newStatus;
        let planId = val.id;

        // new status
        if (val.status == "a") {
          newStatus = "s"; // update status
        } else if (val.status == "s") {
          newStatus = "a"; // update status
        }

        // update
        let newVal = {
          status: newStatus,
        };

        // post item
        const { res, err } = await putItem("plan", planId, newVal);

        if (res.value) {
          // reload plans
          loadPlans();
        } else {
          error.value = err.value;
        }

        // end
        isLoading.value = false;

      }

    };

    // handle update complete
    const handleUpdateComplete = (val) => {
      // release
      editPolicy.value = null;

      if (val.complete) {
        loadPlans();
      }
    };

    // handle add
    const handleReset = () => {
      // clear files
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      uploadProgress.value = "0%";
      uploadError.value = null;
      data.value = null;
      completed.value = null;
      // null all
      policyName.value = null;
      premiumIndividual.value = null;
      premiumFamily.value = null;
      // next
      next.value = false;
      // clear error
      error.value = false;
      // clear loading
      isLoading.value = false;
      // clear submitting
      isSubmitting.value = false;
      // isReady ???
    };

    const handleBack = () => {
      // restart
      handleReset();
    };

    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // handle add
    const handleAdd = async () => {
      if (
        file.value &&
        policyName.value &&
        premiumIndividual.value &&
        premiumFamily.value
      ) {
        // Benefits
        // Start Uploading
        isSubmitting.value = true;

        //start
        uploadError.value = null;

        // Cloud Function
        const fx = "planLoader";

        // setup
        // new plan
        const plan = {
          scheme_id: null, // update this
          name: policyName.value,
          description: "Standard Shelf Plan",
        };

        // individual premium
        const individual = {
          plan_id: null, // update this on server
          amount: premiumIndividual.value,
          type: "i",
        };

        // family premium
        const family = {
          plan_id: null, // update this on server
          amount: premiumFamily.value,
          type: "f",
        };

        // scope
        const scope = 0; // default

        // round up,
        const cloudInput = {
          plan: plan,
          individual: individual,
          family: family,
          scope: scope,
          action: "new",
        };

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // clear
        handleReset();
        // success
        // handleSuccess(data.value.succ.inserted_rows);
        handleSuccess();
      }
      if (uploadError.value) {
        // restart
        // handleReset();
        // stop
        error.value = uploadError.value;
        isSubmitting.value = false;
      }
    });

    // handle success
    const handleSuccess = () => {
      // update
     loadPlans();
    };

    // handle remove
    const handleRemove = (val) => {
      // filter delete
      policy.value = policy.value.filter((x) => x.id != val);
      // clean up
      handleReset();
    };

    // handle submit
    const handleNext = () => {
      // next
      // emit new parameters
      context.emit("next", {
        step: 3,
        isComplete: true,
      });
    };

    // output results (plans)
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      isReady,
      isLoading,
      isSubmitting,
      error,
      handleStatus,
      handleEdit,
      handleNext,
      handleFile,
      handleAdd,
      handleBack,
      handleRemove,
      handleUpdateComplete,
      showBenefits,
      toPremium,
      toMoney,
      showBenefit,
      editPolicy,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      next,
      policyName,
      premiumIndividual,
      premiumFamily,
      plans,
    };
  },
};
</script>

<style>
.grey-bg-custom {
  background-color: #f2f2f2;
}

.hover-text {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}
</style>