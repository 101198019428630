<template>
  <!-- Use general search from parent component -->
  <!-- Lobby Principals -->
  <div class="card">
    <div class="card-content white" v-if="quote && !isSubmitting">
      <!-- HMO, Retail -->
      <button
        class="waves-effect waves-light btn-large teal darken-4 right"
        :class="{
          disabled: changeToNairaTotal(quote.total) == 0 || isSubmitting,
        }"
        @click="handleConfirmation()"
        v-if="cat != 'tpa'"
      >
        <i class="medium material-icons right">check_circle</i>Generate Invoice
        (₦{{ changeToNairaTotal(quote.total) }})
      </button>

      <!-- TPA -->
      <button
        class="waves-effect waves-light btn-large teal darken-4 right"
        :class="{
          disabled: !enrollees.length || isSubmitting,
        }"
        @click="handleActivateTPA()"
        v-else
      >
        <i class="medium material-icons right">check_circle</i>Activate TPA
      </button>
    </div>
    <div class="card" :class="{ 'teal lighten-5': !isSubmitting }">
      <div class="progress" v-if="isSubmitting">
        <div class="indeterminate"></div>
      </div>

      <br v-if="!isSubmitting" />

      <!-- Link after submission -->
      <template v-if="cat != 'retail' && showLink">
        <div class="card-content spacer10">
          <div class="row amber lighten-5">
            <div class="col s12 spacer10">
              <label class="active teal-text">Registration</label>
              <h6><b>General Link</b></h6>
              <p>
                {{
                  `http://localhost:8080/utility/view/${clientId}/${Date.now()}`
                }}
              </p>
              <br />
            </div>
          </div>
        </div>
      </template>

      <div class="card-content white">
        <table class="highlight responsive-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name <small>(Enrollee ID)</small></th>

              <th>Plan</th>
              <th>Type</th>
              <th>Billable</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="clickable"
              v-for="(enrollee, index) in enrollees"
              :key="enrollee.id"
              @click.prevent="handleReview(enrollee)"
            >
              <td>{{ index + 1 }}</td>
              <td>
                {{ enrollee.name }}<br />
                <span v-if="enrollee.enrollee_number"
                  >({{ enrollee.enrollee_number }})</span
                >
              </td>

              <td>{{ enrollee.plan.name }}</td>
              <td>{{ enrollee.type == "f" ? "Family" : "Individual" }}</td>
              <td>
                <span
                  >₦{{
                    changeToNaira(enrollee.plan.premiums, enrollee.type)
                  }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ref } from "vue";
import { ageSex, changeToMoney } from "@/composables/fxLibrary/coreFx";
import postItem from "@/composables/postItem";

export default {
  name: "PrincipalLobbyClientComponent",
  props: ["cat", "clientId", "enrollees", "quote"],
  emits: ["requote"],
  setup(props, context) {
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const showLink = ref(false);
    const error = ref(null);

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // to naira
    const changeToNaira = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // to naira
    const changeToNairaTotal = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // handle review
    const handleReview = () => {
      console.log(null);
    };

    // handle re-activation here
    const handleConfirmation = async () => {
      // start
      isSubmitting.value = true;

      // round up
      const newVal = {
        client_id: props.clientId,
        client_cat: props.cat,
      };

      // post item
      const { res, err } = await postItem(
        "operator/client/invoice/create",
        newVal
      );

      if (res.value) {
        // next
        handleReQuote();
        // show
        showLink.value = true;
      } else {
        error.value = err.value;
      }

      // show button back
      isSubmitting.value = false;
    };

    // handle re-activation here
    const handleActivateTPA = async () => {
      // start
      isSubmitting.value = true;

      // round up
      const newVal = {
        client_id: props.clientId,
        client_cat: props.cat,
      };

      // post item
      const { res, err } = await postItem(
        "operator/client/invoice/create/activate/tpa",
        newVal
      );

      if (res.value) {
        // next
        handleReQuote();
        // show
        showLink.value = true;
      } else {
        error.value = err.value;
      }

      // show button back
      isSubmitting.value = false;
    };

    // handle
    const handleReQuote = () => {
      // go back
      context.emit("requote", true);
    };

    return {
      handleReview,
      handleReQuote,
      handleConfirmation,
      handleActivateTPA,
      changeToNaira,
      changeToNairaTotal,
      showAgeSex,
      showLink,
      isSubmitting,
      isLoading,
      error,
    };
  },
};
</script>
    
  
<style scoped>
.progress {
  margin-top: 0px; /* Adjust the value as needed to move the progress bar upwards */
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chip-more:hover {
  font-size: 150%;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>