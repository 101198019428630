<template>
  <template v-if="showHeader">
    <header>
      <div class="navbar-fixed">
        <nav>
          <div class="nav-wrapper indigo">
            <router-link to="/" class="brand-logo center"><img src="@/assets/logo/logo.png" width="200" /></router-link>
            <ul class="left">
              <li>
                <a href="#!" data-target="slide-out" class="sidenav-trigger show-on-medium-and-up"><i
                    class="large material-icons">menu</i></a>
              </li>
            </ul>
            <ul class="right">
              <li class="">
                <router-link :to="{ name: 'home' }" title="Dashboard"><i
                    class="large material-icons">dashboard</i></router-link>
              </li>
              <li class="hide-on-med-and-down" v-if="tabs.find(x => x.to === 'enrollee')">
                <router-link :to="{ name: 'enrollee' }" title="Enrollees"><i
                    class="large material-icons">people</i></router-link>
              </li>
              <li class="hide-on-med-and-down" v-if="tabs.find(x => x.to === 'claim')">
                <router-link :to="{ name: 'claim' }" title="Claims"><i
                    class="large material-icons">folder</i></router-link>
              </li>
              <li class="hide-on-med-and-down" v-if="tabs.find(x => x.to === 'auth')">
                <router-link :to="{ name: 'auth' }" title="Authorization" class="btn-floating"><i
                    class="large material-icons">insert_comment</i></router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <!-- Side Bar -->
      <div id="slide-out" class="nav-wrapper sidenav grey lighten-2">
        <div class="center grey lighten-4">
          <br />
          <img :src="`https://ui-avatars.com/api/?name=${user?.name}&background=a0a0a0&size=128`" width="96"
            class="circle responsive-img" />

          <h6>
            {{ user?.name }}
            <br />
            <small>({{ user?.designation }})</small>
          </h6>

          <br />
        </div>

        <div class="divider"></div>

        <ul>
          <li>
            <router-link :to="{ name: 'home' }" title="Dashboard"><i class="material-icons indigo-text">dashboard</i>
              Dashboard</router-link>
          </li>
          <li v-for="tab in tabs" :key="tab.id">
            <router-link :to="{ name: tab.to }" :title="tab.title"><i class="material-icons indigo-text">{{
              tab.icon }}</i>
              {{ tab.title }}</router-link>
          </li>
        </ul>

        <ul>
          <li @click.prevent="handleSignOut">
            <router-link :to="{ name: 'home' }" title="Logout"><i
                class="small material-icons orange-text">power_settings_new</i>
              Logout Account</router-link>
          </li>
        </ul>
      </div>

      <!-- UI -->
      <!-- <nav class="custom-nav indigo lighten-5"></nav> -->

      <!-- Breadcrumbs -->
      <!-- <nav v-if="breadcrumbs.length">
        <div class="nav-wrapper grey">
          <div class="col s12 center">
            <router-link class="breadcrumb" to="/">Dashboard</router-link>

            <router-link
              class="breadcrumb"
              :to="index === 0 ? { name: crumb } : currentPath"
              v-for="(crumb, index) in breadcrumbs"
              :key="index"
              >{{ capitalizeFirstLetter(crumb) }}</router-link
            >
          </div>
        </div>
      </nav> -->

      <!-- <br /> -->
    </header>
  </template>
</template>

<script>
import { onMounted, onUpdated, computed, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { clearCache } from "@/composables/cache/logout";
import { addModules } from "@/composables/cache/modules";
import M from "materialize-css";

export default {
  name: "HeaderComponent",
  setup() {
    // vue router
    const router = useRouter();

    // route
    const route = useRoute();

    // store
    const store = useStore();

    // display
    const showHeader = computed(() => {
      if (route.path.includes("setup")) {
        return false;
      } else if (route.path.includes("login")) {
        return false;
      } else if (route.path.includes("utility")) {
        return false;
      } else {
        return true;
      }
    });

    // capitalize
    function capitalizeFirstLetter(val) {
      if (!val) return ''; // Handle empty strings
      return val.charAt(0).toUpperCase() + val.slice(1);
    }

    // title
    onMounted(() => {
      // menu
      const elems = document.querySelectorAll(".sidenav");
      M.Sidenav.init(elems, {});
    });

    // title
    onUpdated(() => {
      // menu
      const elems = document.querySelectorAll(".sidenav");
      M.Sidenav.init(elems, {});
    });

    // Current path
    const currentPath = computed(() => {
      let paths = route.path;
      return paths;
    });

    // Generate breadcrumbs
    const breadcrumbs = computed(() => {
      let paths = route.path.replace(/[0-9]/g, "").split("/");
      return paths.filter((x) => x);
    });

    // user
    const user = computed(() => {
      if (!store.state.user) {
        return null;
      } else {
        return store.state.user;
      }
    });

    const tabs = computed(() => {
      // load allowed
      let sourceTabs = addModules();
      // fitered
      let filteredTabs = [];
      // filter
      if (store.state.user) {
        filteredTabs = sourceTabs.filter(x => store.state.user.modules.includes(x.to));
      }
      // return
      return filteredTabs;
    });

    //state management
    watchEffect(() => {
      if (!store.state.user) {
        //log out
        router.push({
          name: "login",
        });
      }
    });

    // handle SignOut
    const handleSignOut = () => {
      // clean up
      clearCache();
    };


    return {
      capitalizeFirstLetter,
      currentPath,
      breadcrumbs,
      handleSignOut,
      showHeader,
      user,
      tabs,
    };
  },
};
</script>

<style scoped>
.brand-logo {
  margin-top: 7px;
}

.divider-lift {
  margin-top: -12px;
}

.custom-nav {
  height: 15px;
}
</style>