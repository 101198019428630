<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="grey-bg-custom">
      <div class="container">
        <div class="row">
          <div class="col s12">

            <div class="section">
              <h5 class="header">Active <b>Scheme(s) </b>
                <span @click="handleNew">
                  <span class="btn-floating btn-large waves-effect waves-light"
                    :class="{ 'red': !addNew, 'teal': addNew }"><i class="material-icons" title="Add Scheme (New)">{{
                      addNew ? "cancel" : "add"
                    }}</i></span>
                </span>
              </h5>
              <p v-if="success">
                New Scheme Added <i class="material-icons green-text">done</i>
              </p>
            </div>

            <div class="section" v-if="addNew">
              <div class="card-panel green lighten-5">
                <h6 class="header">Add (New) Scheme</h6>
                <br />
                <p class="red-text" v-if="error">{{ error }}<br /></p>
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col s7">
                      <div class="input-field">
                        <input id="newScheme" type="text" v-model="newScheme" required />
                        <label class="active" for="newScheme">New Scheme Name</label>
                      </div>
                    </div>

                    <div class="col s5">
                      <div class="input-field">
                        <select v-model="payer" required>
                          <option value="" disabled selected></option>
                          <option value="hmo">HMO pays</option>
                          <option value="gov">GOV pays</option>
                        </select>
                        <label>Who Pays</label>
                      </div>
                    </div>

                    <div class="col s12">
                      <div class="input-field">
                        <input id="schemeType" type="text" value="Social Scheme" readonly />
                        <label class="active" for="schemeType">Scheme Type</label>
                      </div>
                    </div>
                  </div>

                  <button class="waves-effect waves-light btn-large black" :class="{
                    disabled: isSubmitting,
                  }">
                    <i class="material-icons left" :class="{
                      'red-text': isSubmitting,
                    }">add</i>{{ isSubmitting ? `Adding...` : "Add" }}
                  </button>
                </form>
              </div>
            </div>

            <div class="section">
              <div class="card-panel" :class="{ 'yellow lighten-4': scheme?.id == isEditing }"
                v-for="(scheme, index) in schemes" :key="scheme.id">
                <div class="row">
                  <div v-if="scheme?.id != isEditing">
                    <div class="col s10">
                      <p class="red-text" v-if="error && errorId == scheme?.id">
                        {{ error }}
                      </p>
                      <h5>
                        {{ index + 1 }}. {{ scheme?.name }}
                        <small>({{
                          scheme?.type == "p"
                            ? "private scheme"
                            : "social scheme"
                          }})</small>
                      </h5>
                      <p><span class="chip"
                          :class="{ 'teal lighten-5': scheme?.who_pays === 'hmo', 'orange lighten-5': scheme?.who_pays === 'gov' }">{{
                            scheme?.who_pays.toUpperCase() }} pays</span></p>
                      <p>
                        Created:
                        {{
                          new Date(scheme?.created_at).toLocaleString(undefined, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            weekday: "long",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        }}
                      </p>
                    </div>
                    <div class="col s2">
                      <a class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"><i
                          class="material-icons" title="edit" @click="handleEdit(scheme)">edit</i></a>
                    </div>
                  </div>

                  <div v-else>
                    <div class="col s10">
                      <h5>Edit Scheme</h5>

                      <div class="row">
                        <form @submit.prevent="handleEditConfirm(scheme)">
                          <div class="col s6">
                            <div class="input-field">
                              <input id="name" type="text" v-model="name" required />
                              <label class="active" for="name">Name</label>
                            </div>
                          </div>

                          <div class="col s4">
                            <div class="input-field">
                              <h5>
                                <small>({{
                                  scheme?.type == "p"
                                    ? "private scheme"
                                    : "social scheme"
                                  }})</small>
                              </h5>
                            </div>
                          </div>

                          <div class="col s2">
                            <button class="waves-effect waves-light btn teal" :class="{
                              disabled: isSubmitting,
                            }">
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="col s2">
                      <a class="btn-floating btn-medium waves-effect waves-light orange right"><i class="material-icons"
                          title="close" @click="handleCancel()">close</i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section" @click="handleFinish">
              <a class="waves-effect waves-light btn-large right">
                <i class="material-icons white-text right">call_made</i>Submit
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </template>
</template>

<script>
import { computed, onMounted, onUpdated, ref } from "vue";
import getItems from "@/composables/getItems";
import putItem from "@/composables/putItem";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import M from "materialize-css";


export default {
  name: "SchemeSetupComponent",
  components: { LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    // start
    const response = ref(null);
    const error = ref(null);
    const errorId = ref(null);
    const addNew = ref(null);
    const success = ref(false);
    const newScheme = ref(false);
    const payer = ref(null);
    const isReady = ref(false);
    const scheme = ref(null);
    const isEditing = ref(null);
    const isSubmitting = ref(false);
    const name = ref(null);

    // start
    onMounted(() => {
      // get items
      (async () => {
        const { res, err } = await getItems("scheme");

        if (res.value) {
          response.value = res.value.data;

          // page is ready
          isReady.value = true;
        } else {
          error.value = err.value;
        }
      })();
    });

    // select
    onMounted(() => {
      var elems = document.querySelectorAll("select");
      M.FormSelect.init(elems, {});
    });

    // select
    onUpdated(() => {
      var elems = document.querySelectorAll("select");
      M.FormSelect.init(elems, {});
    });

    // handle new scheme
    const handleNew = () => {
      addNew.value = !addNew.value;
      newScheme.value = null;
      success.value = false;
    };

    // handle submit
    const handleSubmit = async () => {
      isSubmitting.value = true;

      // update backend (post item)
      let val = {
        name: newScheme.value,
        who_pays: payer.value,
        type: "s",
      };

      const { res, err } = await postItem("scheme", val);

      if (res.value) {
        // update local array (backend is sorted)
        response.value.push(res.value.data);
        // success
        success.value = true;
      } else {
        error.value = err.value;
      }

      handleCancel();
    };

    // handle edit
    const handleEdit = (scheme) => {
      isEditing.value = scheme?.id;
      name.value = scheme?.name;
    };

    // handle cancel
    const handleCancel = () => {
      isSubmitting.value = false;
      isEditing.value = null;
      name.value = null;
      newScheme.value = null;
      payer.value = null;
      error.value = null;
      addNew.value = false;
    };

    // handle edit confirm
    const handleEditConfirm = (scheme) => {
      isSubmitting.value = true;
      response.value.forEach(async (x) => {
        if (x.id == scheme?.id) {
          // keep track of errorId (if required)
          errorId.value = x.id;

          // update backend (post item)
          let val = {
            name: name.value,
          };

          const { res, err } = await putItem("scheme", x.id, val);

          if (res.value) {
            // update local array (backend is sorted)
            x.name = res.value.data.name;
          } else {
            error.value = err.value;
          }
        }
      });
      handleCancel();
    };

    // handle finish
    const handleFinish = () => {
      // start
      handleCancel();
      success.value = false;

      // next
      // emit new parameters
      context.emit("next", {
        step: 2,
        isComplete: true,
      });

    };

    // schemes
    const schemes = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });


    return {
      handleSubmit,
      handleEdit,
      handleEditConfirm,
      handleCancel,
      handleNew,
      handleFinish,
      isReady,
      schemes,
      scheme,
      error,
      errorId,
      addNew,
      success,
      payer,
      newScheme,
      isEditing,
      isSubmitting,
      name,
    };
  },
};
</script>

<style scoped>
.grey-bg-custom {
  background-color: #f2f2f2;
}

.hover-text {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}
</style>