import postItem from "@/composables/postItem";
import putItem from "@/composables/putItem";

// add
const addClaimSave = async (encounterId, newVal) => {

    const { res } = await postItem("claim", {
        encounter_id: encounterId,
        item: newVal.claim,
        sent_qty: newVal.qty,
        sent_price: newVal.amt,
        standard_price: newVal.standard,
        authorization_code: newVal?.authorization_code,
        status: newVal.status
    });

    if (res.value) {

        res.value.data.claim = res.value.data.item;
        res.value.data.qty = res.value.data.sent_qty;
        res.value.data.amt = res.value.data.sent_price;
        res.value.data.standard = res.value.data.standard_price;

        return res.value.data;

    } else {
        return null;
    }

};

// update
const updateSavedClaim = async (claimId, newVal) => {

    const { res } = await putItem("claim", claimId, {
        item: newVal.claim,
        sent_qty: newVal.qty,
        sent_price: newVal.amt,
        authorization_code: newVal?.authorization_code,
    });

    if (res.value) {

        res.value.data.claim = res.value.data.item;
        res.value.data.qty = res.value.data.sent_qty;
        res.value.data.amt = res.value.data.sent_price;

        return res.value.data;

    } else {
        return null;
    }

};

// delete
const deleteSavedClaim = async (claimId, newVal) => {

    const { res } = await putItem("claim", claimId, newVal);

    if (res.value) {
        return res.value.data;
    } else {
        return null;
    }

};

// update
const updateSavedEnrollee = async (encounterId, newVal) => {

    const { res } = await putItem("encounter", encounterId, newVal);

    if (res.value) {
        return res.value.data;
    } else {
        return null;
    }

};

// delete
const deleteSavedEnrollee = async (encounterId, newVal) => {

    const { res } = await putItem("encounter", encounterId, newVal);

    if (res.value) {
        return res.value.data;
    } else {
        return null;
    }

};

// sync
const syncSavedEnrollee = async (val, cluster) => {

    // defualt
    let newVal = {};

    // master confirmation
    if (val.encounter?.encounter_date_set) {

        // work on deletion
        if (!val.encounter.claims.length || val.encounter.status === "d") {

            newVal = await deleteSavedEnrollee(val.encounter.id, {
                status: "d",
                stores: val.encounter?.encounter_cluster_id,
                encounter_cluster_id: 0,
            });

            return newVal;

        } else {

            let hasChanged = false;

            cluster.encounters.forEach((x) => {
                if (x.editId === val.encounter.editId) {
                    if (val.encounter?.old_diagnosis ||
                        val.encounter?.old_encounter_date ||
                        val.encounter?.old_encounter_date_end) {
                        // set to true
                        hasChanged = true;
                    }
                }
            });

            if (hasChanged) {

                newVal = await updateSavedEnrollee(val.encounter.id, {
                    diagnosis: val.encounter?.diagnosis,
                    encounter_date: val.encounter?.encounter_date,
                    encounter_date_end: val.encounter?.encounter_date_end,
                }); // newVal is null if it fails

                return newVal;

            }

        }

    }

};


export {
    addClaimSave,
    updateSavedClaim,
    deleteSavedClaim,
    syncSavedEnrollee,
}
