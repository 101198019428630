<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <!-- Search -->

    <!-- Bar -->
    <span v-if="showSearch && enrollees.length">
      <nav class="white">
        <div class="nav-wrapper">
          <form>
            <div class="input-field blue-text text-darken-2">
              <input
                type="search"
                v-model="searchItem"
                placeholder="Search By Name, ID or Plan"
              />
              <label class="label-icon" for="search"
                ><i class="material-icons black-text">search</i></label
              >
              <i
                class="material-icons white-text orange"
                @click="handleClearSearch"
                v-if="searchItem"
                >clear</i
              >
            </div>
          </form>
        </div>
      </nav>
      <!-- Loader -->
      <div class="progress" v-if="isLoading">
        <div class="indeterminate"></div>
      </div>
    </span>

    <!-- Lobby Principals -->
    <LobbyDiv
      :cat="cat"
      :clientId="clientId"
      :enrollees="enrollees"
      :quote="quote"
      @requote="handleReQuote"
      v-if="displayType == 'p'"
    />

    <!-- Active Principals -->
    <!-- On-Hold Principals -->
    <ActiveHoldDiv
      :authLevel="authLevel"
      :cat="cat"
      :enrollees="enrollees"
      :displayType="displayType"
      @reset="handleReset"
      v-if="displayType == 'a' || displayType == 'h'"
    />

    <!-- In-active Principals -->
    <OutDiv
      :cat="cat"
      :enrollees="enrollees"
      @reset="handleReset"
      v-if="displayType == 'd'"
    />

    <template v-if="!isLoading">
      <div class="section center" v-if="!enrollees.length">
        <h6><img src="@/assets/img/no-user.png" width="256" class="circle responsive-img" /></h6>
      </div>

      <span v-else>
        <PaginationDiv :payLoad="allPages" @newPage="handlePage" />
      </span>
    </template>
  </template>
</template>

  
<script>
import { computed, onMounted, onUpdated, ref, watchEffect } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import ActiveHoldDiv from "@/components/client/extras/PrincipalActiveHoldClientComponent.vue";
import LobbyDiv from "@/components/client/extras/PrincipalLobbyClientComponent.vue";
import OutDiv from "@/components/client/extras/PrincipalOutClientComponent.vue";
import M from "materialize-css";

export default {
  name: "EnrolleeClientComponent",
  components: {
    LoadingDiv,
    PaginationDiv,
    ActiveHoldDiv,
    LobbyDiv,
    OutDiv,
  },
  props: ["cat", "authLevel", "clientId", "displayType"],
  setup(props) {
    const response = ref(null);
    const utility = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const error = ref(null);
    const currTab = ref([]);
    const tabs = ref([]);
    const currUrl = ref(null);
    const currHook = ref(null);
    const searchItem = ref(null);

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res: uRes, err: uErr } = await getItemsOfItems(table, id);
        if (uRes.value) {
          utility.value = uRes.value.data;
        } else {
          error.value = uErr.value;
        }
      })();
    };

    // get function
    const getSearchItemsFromApi = (table, match) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, match);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // get quote
    const getQuote = () => {
      getItemsFromApi("operator/client/invoice/quote", props.clientId);
    };

    // starter
    onMounted(() => {
      // start from the top
      if (props.displayType == "p") {
        getQuote();
      }
    });

    // starter
    onMounted(() => {
      // loader
      isReady.value = false;
      // start from the top
      getAllUsers(props.displayType);
    });

    // dropdown
    onMounted(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // dropdown
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // prinipals
    const getAllUsers = (val) => {
      // set current Url
      currUrl.value = "principal/client/search";
      // set current Hook
      currHook.value = `?z=${val}&`;
      // get items from function
      getSearchItemsFromApi(currUrl.value, `${props.clientId}?z=${val}`);
    };

    // restart
    const handleClearSearch = () => {
      // start laoding
      isLoading.value = true;
      // null search
      searchItem.value = null;
      // start from the top
      getAllUsers(props.displayType);
    };

    // switch page
    const handlePage = (page) => {
      // get new page items from function
      getSearchItemsFromApi(
        currUrl.value,
        `${props.clientId}${currHook.value}page=${page}`
      );
    };

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
      if (!tab.status) {
        getAllUsers(props.displayType);
      }
    };

    // handle
    const handleReQuote = (val) => {
      // do review
      if (val) {
        getQuote();
        getAllUsers(props.displayType);
      }
    };

    // handle
    const handleReset = (val) => {
      // do review
      let idx;

      if (val) {
        response.value.data.forEach((x, y) => {
          if (x.id == val.id) {
            idx = y;
          }
        });
        // update
        response.value.data[idx] = val;
      }
    };

    // search watcher
    watchEffect(() => {
      if (searchItem.value && isReady.value) {
        // start laoding
        isLoading.value = true;
        // set current Url
        currUrl.value = "principal/client/search";
        // set current Hook
        currHook.value = `?z=${props.displayType}&q=${searchItem.value}&`;
        // get searchItem items from function
        getSearchItemsFromApi(
          currUrl.value,
          `${props.clientId}?z=${props.displayType}&q=${searchItem.value}`
        );
      } else {
        handleClearSearch();
      }
    });

    // all enrollees
    const enrollees = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // quote
    const quote = computed(() => {
      if (!utility.value) {
        return null;
      } else {
        return utility.value;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    // opened all up
    const showSearch = computed(() => {
      if (props.displayType == "p") {
        return false;
      } else {
        return true;
      }
    });

    return {
      handlePage,
      handleActive,
      handleClearSearch,
      handleReQuote,
      handleReset,
      currTab,
      tabs,
      isReady,
      isSubmitting,
      isLoading,
      error,
      searchItem,
      quote,
      allPages,
      showSearch,
      enrollees,
    };
  },
};
</script>
  

<style scoped>
.progress {
  margin-top: 0px;
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chip-more:hover {
  font-size: 150%;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>