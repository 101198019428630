import * as XLSX from "xlsx";

// excelSheet
function downloadExcelFx(data, title) {

        if (data.length === 0) {
          alert("No data to export!");
          return;
        }
  
        // Extract dynamic headers from the first data object
        const headers = [Object.keys(data[0])];
  
        // Convert data dynamically based on extracted headers
        const formattedData = data.map((item) => headers[0].map((key) => item[key]));
  
        // Define description row
        const descriptionRow = [[title]];
  
        // Combine everything into a single dataset
        const rawData = [
          ...descriptionRow, // Title row
          [],
          ...headers, // Table headers
          ...formattedData, // Table data
        ];
  
        // Create worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(rawData);
  
        // Merge the description row across all columns
        worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: headers[0].length - 1 } }];
  
        // Dynamically adjust column widths based on the longest value in each column
        worksheet["!cols"] = headers[0].map((header) => ({
          wch: Math.max(header.length, ...data.map((row) => (row[header] ? row[header].toString().length : 0))) + 2,
        }));
  
        // Create workbook and export the file
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
        XLSX.writeFile(workbook, `${title}.xlsx`);
   
}


export {
  downloadExcelFx,
}