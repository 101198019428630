<template>

  <!-- UI -->
  <nav class="custom-nav grey"></nav>

  <!-- custom menu -->
  <nav class="nav-extended grey">
    <div class="nav-wrapper container">
      <a href="#!" class="brand-logo" @click.prevent="handleActive(home)">{{ home?.name }}</a>
      <ul class="right hide-on-med-and-down">
        <li v-for="tab in tabs" :key="tab.id" :class="{ 'active indigo lighten-2': tab.id == currTab.id }"><a href="#"
            @click.prevent="handleActive(tab)">{{ tab.title
            }}</a>
        </li>
      </ul>
      <br />
    </div>
    <div class="nav-content container" v-if="isReady">
      <ul class="tabs tabs-transparent grey-text text-lighten-4">
        <li class="tab">
          <span class="chip black white-text">{{ capWords(clusterProvider) }}</span>
        </li>
      </ul>
      <ul class="tabs tabs-transparent grey-text text-lighten-4">
        <li class="tab">
          <span class="chip"><b>{{ clusterScheme?.toUpperCase() }}</b></span>
          <span class="chip"><b>{{ clusterPeriod }} Claims</b></span>
          <span class="chip yellow lighten-4" v-if="clusterStatus == 'p'"><em><b>UnVetted</b></em></span>
          <span class="chip green lighten-4" v-if="clusterStatus == 'v'"><em><b>Vetted</b></em></span>
          <span class="chip green lighten-4" v-if="clusterStatus == 'r'"><em><b>Vetted</b></em></span>
        </li>
      </ul>
    </div>
  </nav>

  <!-- search layer -->
  <div class="grey lighten-4">
    <div class="container section"></div>
  </div>

  <!-- main -->
  <div class="container">

    <div class="section">
      <br />
      <div class="row">
        <div class="col s12">
          <LoadingDiv v-if="!isReady" />

          <div v-else>

            <div class="center">
              <span v-for="tab in tabsState" :key="tab.id" :class="{
                'tab-custom-active': tab.state === currState,
                'tab-custom': tab.state !== currState,
              }" @click="handleReview(tab.state)">
                {{ tab.title }} <span v-if="tab.title.toLowerCase().includes('all')">({{
                  encounters.length ? encounters.length : 0
                }})</span>
              </span>
              <br /><br /><br />
            </div>

            <!-- Vet claim -->
            <VetClaim :encounterId="encounterId" :forTariff="cluster" :cluster="encounters"
              :clusterStatus="clusterStatus" @vetted="handleVetted" v-if="currState == 'v'" />

            <!-- Display claims -->
            <template v-if="currState == 'd'">
              <div class="card">
                <div class="card yellow lighten-5">
                  <div class="card-content white">
                    <table class="highlight responsive-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name<br /><small>(Enrollee ID)</small></th>
                          <th>Diagnosis</th>
                          <th>Sent Amount</th>
                          <th>Vetted Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="clickable" :class="{
                          'grey lighten-5': encounter.status == 'p',
                        }" v-for="(encounter, index) in encounters" :key="encounter.id"
                          @click.prevent="handleStart(encounter)">
                          <template v-if="encounter.status == 'd'">
                            <td>{{ index + 1 }}</td>
                            <td>
                              <s>{{ capWords(encounter.name) }}</s><br />
                              <s>({{ encounter.enrollee_number }})</s>
                            </td>
                            <td>
                              <s>{{ capWords(encounter.diagnosis) }}</s>
                            </td>
                            <td>
                              <s>
                                ₦{{ totalSent(encounter) }}
                              </s>
                            </td>
                            <td>
                              <s>₦{{ totalApproved(encounter) }}</s>
                            </td>
                            <td>
                              <i class="material-icons grey-text pointable" :title="getTitle(encounter)">check_circle
                              </i>
                            </td>
                          </template>

                          <template v-else>
                            <td>{{ index + 1 }}</td>
                            <td>
                              {{ capWords(encounter.name) }}<br />
                              ({{ encounter.enrollee_number }})
                            </td>
                            <td>{{ capWords(encounter.diagnosis) }}</td>
                            <td>₦{{ totalSent(encounter) }}</td>
                            <td>₦{{ totalApproved(encounter) }}</td>
                            <td>
                              <i class="material-icons pointable" :class="{
                                'yellow-text text-darken-2':
                                  encounter.status == 'p',
                                'teal-text text-lighten-3': !(
                                  encounter.status == 'p'
                                ),
                              }" :title="getTitle(encounter)">{{
                                encounter.status == "p"
                                  ? "watch_later"
                                  : "check_circle"
                              }}
                              </i>
                            </td>
                          </template>
                        </tr>

                        <!-- Final total -->
                        <tr>
                          <td colspan="2"><br /></td>
                          <td>Total</td>
                          <td>
                            <b>₦{{ grossTotalSent(encounters) }}</b>
                          </td>
                          <td class="green-text text-darken-2">
                            <h5>₦{{ grossTotalApproved(encounters) }}</h5>
                          </td>
                          <td colspan="1"><br /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Submit -->
              <div class="card-content">
                <br />
                <button class="waves-effect waves-light btn-large black right" :class="{
                  disabled:
                    complete || clusterStatus == 'v' || clusterStatus == 'r',
                }" @click="handleReview('v')">
                  <i class="medium material-icons right">chevron_right</i>Continue
                  Vetting
                </button>
              </div>
            </template>

            <!-- Show status -->
            <template v-if="currState == 's'">
              <div class="card">
                <div class="card yellow lighten-5">
                  <div class="card-content white">
                    <table class="responsive-table">
                      <thead>
                        <tr>
                          <th colspan="2"><br /></th>
                          <th>Sent Total</th>
                          <th>Approved Total</th>
                          <th>Status</th>
                          <th>TAT</th>
                          <th>
                            <span class="normal-th chip"
                              v-if="clusterStatus === 'p' && clusterUserType && user.level === 5">
                              <a href="#" class="secondary-content teal-text text-darken-1 dropdown-trigger right"
                                data-target="dropmenu"><i class="tiny material-icons" title="review">more_vert</i></a>

                              <!-- DropMenu -->
                              <ul id="dropmenu" class="dropdown-content">
                                <li :class="{ 'disabledItem': clusterUserType && clusterUserType !== 'hmo' }"
                                  @click.prevent="handleReInput('hmo')">
                                  <a href="#">Return Claims <em>to</em> HMO Team</a>
                                </li>
                                <li class="divider" tabindex="-1"></li>
                                <li :class="{ 'disabledItem': clusterUserType && clusterUserType !== 'provider' }"
                                  @click.prevent="handleReInput('provider')">
                                  <a href="#">Return Claims <em>to</em> Provider</a>
                                </li>
                                <li class="divider" tabindex="-1"></li>
                              </ul>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="2">Payment</td>
                          <td>₦{{ grossTotalSent(encounters) }}</td>
                          <td :class="{
                            'green-text text-darken-2':
                              clusterStatus == 'v' || clusterStatus == 'r',
                            'red-text': !(
                              clusterStatus == 'v' || clusterStatus == 'r'
                            ),
                          }">
                            <b>{{
                              clusterStatus == "v" || clusterStatus == "r"
                                ? `₦${grossTotalApproved(encounters)}`
                                : "Pending vetting"
                            }}</b>
                          </td>
                          <td>
                            <span class="chip" :class="{
                              'chip teal lighten-4': paymentStatus(cluster)?.toLowerCase() === 'paid',
                              'chip grey lighten-2': paymentStatus(cluster)?.toLowerCase() === 'pending',
                              'chip orange lighten-2': paymentStatus(cluster)?.toLowerCase() === 'unsubmitted',
                              'chip indigo lighten-4': paymentStatus(cluster)?.toLowerCase() !== 'paid' && paymentStatus(cluster)?.toLowerCase() !== 'pending' && paymentStatus(cluster)?.toLowerCase() !== 'unsubmitted',
                            }">
                              {{
                                paymentStatus(cluster)
                              }}
                            </span>
                          </td>
                          <td>{{ clusterTAT }} days</td>
                          <td>
                            <span class="clickable edit-item" title="Download Payment Advice"
                              @click.prevent="handleDownload()">
                              <i class="material-icons grey-text">download</i>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import getItemsOfItems from "@/composables/getItemsOfItems";
import putItem from "@/composables/putItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import {
  toMonthYear,
  getDifferenceInDays,
  capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";
import {
  grossTotalSentFx,
  grossTotalApprovedFx,
  totalSentFx,
  totalApprovedFx,
  paymentStatusFx,
} from "@/composables/calFunction/cal";
import {
  downloadExcelFx,
} from "@/composables/reportFunction/downloader";
import VetClaim from "@/components/claim/VetClaimComponent.vue";
import M from "materialize-css";

export default {
  name: "claimView",
  props: ["clusterId"],
  components: { LoadingDiv, VetClaim },
  setup(props) {
    const response = ref(null);
    const error = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(true);
    const currUrl = ref(null);
    const encounterId = ref(0);
    const cluster = ref(null);
    const clusterProvider = ref(null);
    const clusterPeriod = ref(null);
    const clusterScheme = ref(null);
    const clusterStatus = ref(null);
    const clusterPayment = ref(null);
    const clusterRepayment = ref(null);
    const clusterUserType = ref(null);
    const clusterTAT = ref(null);
    const currState = ref("v"); // v=vet, d=display, s=status
    const complete = ref(false);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);
    const tabsState = ref([]);

    // vue router
    const router = useRouter();

    // store
    const store = useStore();

    // start
    onMounted(() => {
      // page name
      document.title = "View Claims | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Claims", title: "Home", icon: "arrow_back", url: "claim", hash: null, });
      tabs.value.push({ id: 2, name: "Claims", title: "Vet", icon: null, url: null, hash: null, });
      tabs.value.push({ id: 3, name: "Claims", title: "Add Claims", icon: "add_circle", url: "claimAdd", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Vet"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // start
    onMounted(() => {
      // load tab
      tabsState.value.push({ id: 1, title: "View All Pages", state: "d" });
      tabsState.value.push({ id: 2, title: "Show Status", state: "s" });
    });

    // check for cluster status
    watchEffect(() => {
      if (clusterStatus.value !== "p") {
        tabsState.value = tabsState.value.filter(x => x.state !== 'v');
      } else {
        tabsState.value.push({ id: 3, title: "Vet Claims", state: "v" });
      }
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // get title
    const getTitle = (val) => {
      if (val.status == "p") {
        return "Un-Vetted Claim";
      } else if (val.status == "d") {
        return "Completed (Rejected)";
      } else {
        return "Completed";
      }
    };

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      return grossTotalSentFx(encounters);
    };

    // total calculations (gross approved)
    const grossTotalApproved = (encounters, db) => {
      return grossTotalApprovedFx(encounters, db);
    };

    // total calculations (sent)
    const totalSent = (encounter) => {
      return totalSentFx(encounter);
    };

    // total calculations (approved)
    const totalApproved = (encounter) => {
      return totalApprovedFx(encounter);
    };

    // payment status
    const paymentStatus = (cluster) => {
      return paymentStatusFx(cluster);
    };

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    const starter = () => {
      if (props.clusterId) {
        (async () => {
          const { res } = await getItemsOfItems("cluster", props.clusterId);

          if (res.value) {
            // cluster period
            const { fx: monthYear } = toMonthYear(res.value.data.month_year);
            cluster.value = res.value.data // all
            clusterPeriod.value = monthYear.value;
            clusterProvider.value = res.value.data.provider.name;
            clusterScheme.value = res.value.data.scheme.name;
            clusterStatus.value = res.value.data.status;
            clusterPayment.value = res.value.data.payment;
            clusterRepayment.value = res.value.data.repayment;
            clusterUserType.value = res.value.data.user?.type;

            // get TAT
            const { fx: inDays } = getDifferenceInDays(
              res.value.data.created_at,
              res.value.data.created_at
            );
            clusterTAT.value = inDays.value;

            // reset status
            if (clusterStatus.value === "v" || clusterStatus.value === "r") {
              currState.value = "s";
            }

            // send q and d away
            if (clusterStatus.value === "q" || clusterStatus.value === "d") {
              // route back home
              router.push({ name: "claim" });
            }
          } else {
            // cluster Error
            error.value = "(Error) No Claims Encounter Found";
          }
        })();
      }
    }

    // clusterPeriod getter
    onMounted(() => {
      starter();
    });

    // more
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "encounter/cluster";
      // get items from function
      getItemsFromApi(currUrl.value, props.clusterId);
    });

    // Send Back claims
    const handleReInput = async () => {

      // start 
      isReady.value = false;

      // update
      let newVal = { status: "q" };

      // put update
      const { res } = await putItem("cluster/return", props.clusterId, newVal);

      if (res.value) {
        // route back home
        router.push({ name: "claim" });
      } else {
        // close out  
        // restart page
        starter();
        // start 
        isReady.value = true;
      }

    }

    // switch page
    const handleStart = (encounter) => {
      // start spec laoding
      if (props.clusterId) {
        // re-assign
        encounterId.value = encounter.id;
        // switch to vetting
        currState.value = "v";
      }
    };

    // review
    const handleReview = (val) => {
      // switch to display
      if (val == "v") {
        currState.value = "v";
      } else if (val == "d") {
        currState.value = "d";
      } else if (val == "s") {
        currState.value = "s";
      }
    };

    // vetted
    const handleVetted = async (val) => {

      // switch to display
      currState.value = "d";

      // reload // val.status == 'success'
      if (val.status == "success" && clusterStatus.value == "p") {
        //after checking emits, checking cluster status
        // setup cluster
        let dbEntry = true;
        let payment_final = grossTotalApproved(val.value, dbEntry);
        let newVal = { secondary_user_id: store.state.user.id, level: store.state.user.level, payment_final: payment_final, status: "v" };

        // put update
        const { res, err } = await putItem("cluster", props.clusterId, newVal);

        if (res.value) {
          // update
          response.value = val.value;
          // update cluster from .cluster object (outside the ususal .data)
          clusterStatus.value = res.value.cluster.status;
          // update cluster status
          cluster.value.status = res.value.cluster.status;
          // notify (Toast)
          M.toast({
            html: "Claims Vetting Completed <i class='material-icons'>check_circle</i>", // Toast content
            // classes: 'green rounded', // Add custom classes
            displayLength: 5000, // Duration in milliseconds
            inDuration: 300, // Transition in duration
            outDuration: 300, // Transition out duration
          });
        } else {
          error.value = err.value;
        }
      }

    };

    // download
    const handleDownload = () => {
      let data = [];

      // total
      encounters.value.forEach((x, y) => {

        // detail
        x.claims.forEach((z, j) => {

          if (j === 0) {

            // first
            data.push({
              No: y + 1,
              Name: x.name,
              ID: x.enrollee_number,
              Encounter: x.encounter_date,
              End: x.encounter_date_end,
              Diagnosis: x.diagnosis,
              Item: z.item,
              sent_qty: z.sent_qty,
              appr_qty: x.status == 'd' || z.status == 'd' ? 0 : z.approved_qty,
              Sent: z.sent_price,
              Approved: x.status == 'd' || z.status == 'd' ? 0 : z.approved_price,
              Comment: z.comment,
            })

          } else {

            // others
            data.push({
              No: "",
              Name: "",
              ID: "",
              Encounter: "",
              End: "",
              Diagnosis: "",
              Item: z.item,
              sent_qty: z.sent_qty,
              appr_qty: x.status == 'd' || z.status == 'd' ? 0 : z.approved_qty,
              Sent: z.sent_price,
              Approved: x.status == 'd' || z.status == 'd' ? 0 : z.approved_price,
              Comment: z.comment,
            })

          }

        })

        // total
        data.push({
          No: "",
          Name: "",
          ID: "",
          Encounter: "",
          End: "",
          Diagnosis: "",
          Item: "",
          sent_qty: "",
          appr_qty: "",
          Sent: totalSent(x),
          Approved: totalApproved(x),
          Comment: "",
        })

        // space
        data.push({})

      });

      // space
      data.push({})

      // final
      data.push({
        No: "",
        Name: "",
        ID: "",
        Encounter: "",
        End: "",
        Diagnosis: "",
        Item: "",
        sent_qty: "",
        appr_qty: "Final",
        Sent: grossTotalSent(encounters.value),
        Approved: grossTotalApproved(encounters.value),
        Comment: "",
      })

      // fire function
      return downloadExcelFx(data, `${clusterProvider.value}_${clusterScheme.value}_${clusterPeriod.value} Claims`);
    }

    // monitor complete
    watchEffect(() => {
      if (response.value) {
        // start with
        complete.value = true;
        // update
        response.value.forEach((x) => {
          if (x.status == "p") {
            // set to false
            complete.value = false;
          }
        });
      }
    });

    // output results
    const encounters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      user: store.state.user,
      handleActive,
      home,
      currTab,
      tabs,
      tabsState,
      currState,
      capWords,
      handleDownload,
      handleReInput,
      handleStart,
      handleReview,
      handleVetted,
      grossTotalSent,
      grossTotalApproved,
      totalApproved,
      totalSent,
      paymentStatus,
      getTitle,
      isReady,
      isLoading,
      isSubmitting,
      error,
      encounterId,
      cluster,
      clusterProvider,
      clusterPeriod,
      clusterScheme,
      clusterStatus,
      clusterPayment,
      clusterRepayment,
      clusterUserType,
      clusterTAT,
      complete,
      encounters,
    };
  },
};
</script>

<style scoped>
.normal-th {
  font-weight: normal;
}

.disabledItem {
  pointer-events: none;
  opacity: 0.5;
}

.custom-hr {
  width: 50%;
  /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc;
  /* Adjust the color and style as needed */
  margin: 20px 20px;
  /* Adjust margin as needed */
}

.progress {
  margin-top: -15px;
  /* Adjust the value as needed to move the progress bar upwards */
}

.styled-text {
  background-color: #fafad2;
  /* Light Golden Yellow */
  /* border: 2px solid #000; Black border */
  padding: 2px;
  /* Adequate padding */
}

.capsule {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
}

.clickable {
  cursor: pointer;
}

.pointable {
  cursor: default;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.info-item:hover .material-icons.grey-text,
.info-item:hover .material-icons.grey-text::before {
  color: rgb(0, 0, 0) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.reset-item:hover .material-icons.grey-text,
.reset-item:hover .material-icons.grey-text::before {
  color: rgb(23, 124, 40) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}

.clickable {
  cursor: pointer;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.spacer {
  padding: 2px;
}

.pointer {
  cursor: default;
}

.custom-nav {
  height: 15px;
}
</style>