<template>

<!-- UI -->
<nav class="custom-nav grey"></nav>

  <!-- custom menu -->
  <nav class="nav-extended grey">
    <div class="nav-wrapper container">
      <a href="#!" class="brand-logo" @click.prevent="handleActive(home)">{{ home?.name }}</a>
      <ul class="right hide-on-med-and-down">
        <li v-for="tab in tabs" :key="tab.id" :class="{ 'active indigo lighten-2': tab.id == currTab.id }"><a href="#"
            @click.prevent="handleActive(tab)">{{ tab.title
            }}</a>
        </li>
      </ul>
      <br />
    </div>
    <div class="nav-content container" v-if="isReady">
      <ul class="tabs tabs-transparent grey-text text-lighten-4">
        <li class="tab">
          <span class="chip black white-text">{{ capWords(provider?.name) }}</span>
        </li>
      </ul>
      <br />
    </div>
  </nav>


   <!-- search layer -->
   <div class="grey lighten-4">
    <div class="container section"></div>
  </div>


  <!-- main -->
  <div class="container">

    <!-- <div class="card-content">
      <div class="row">
        <div class="col s4">
          <h3 class="header">
            <router-link :to="{ name: 'provider' }" class="blue-grey-text text-darken-2">Providers</router-link>
          </h3>
          <h5 class="header blue-grey-text text-darken-2 ups bar-over-text" v-if="isReady">
            <small>
              <span class="blue-grey-text text-darken-2 tooltipped pointer" data-position="bottom"
                data-tooltip="Provider" v-if="provider && 'name' in provider">
                <i class="tiny material-icons teal-text text-lighten-2">local_hospital</i> {{
                  provider?.name.toUpperCase() }}
              </span>
              <span v-else> Provider... </span>
            </small>
          </h5>
        </div>
        <div class="col s8">
          <span v-for="tab in tabs" :key="tab.id" class="right" :class="{
            'tab-custom-active': tab.id == currTab.id,
            'tab-custom': tab.id != currTab.id,
          }" @click="handleActive(tab)">
            <span v-if="tab.icon"><i class="tiny material-icons cyan-text">{{ tab.icon }}</i></span>
            {{ tab.title }}
            <span v-if="tab.id == currTab.id"><i
                class="tiny material-icons blue-grey-text text-darken-2">check_circle</i></span>
          </span>

        </div>
      </div>

    </div> -->

    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <div class="section center">
        <br />
        <span v-for="tab in tabsSmall" :key="tab.id" :class="{
          'tab-custom-active': tab.id == currTabSmall,
          'tab-custom': tab.id != currTabSmall,
        }" @click="handleActiveSmall(tab.id)">
          {{ tab.title }}
        </span>
      </div>

      <div class="row">
        <div class="col s12">
          <!-- Profile -->
          <ProfileDiv :provider="provider" @update="handleUpdate" v-if="currTabSmall == 1" />
          <!-- Tariff -->
          <TariffDiv :provider="provider" id="1" v-if="currTabSmall == 2" />
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getItem from "@/composables/getItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import ProfileDiv from "@/components/provider/ProfileProviderComponent.vue";
import TariffDiv from "@/components/provider/TariffProviderComponent.vue";
import {
  capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";
import M from "materialize-css";

export default {
  name: "providerView",
  components: { LoadingDiv, ProfileDiv, TariffDiv },
  props: ["id"],
  setup(props) {
    onMounted(() => {
      // page name
      document.title = "View Single Provider | EasyHMO";
    });

    const response = ref(null);
    const isReady = ref(false);
    const error = ref(null);
    const currTabSmall = ref(null);
    const tabsSmall = ref([]);
    const providerId = ref(props.id);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Provider", title: "Home", icon: "arrow_back", url: "provider", hash: null, });
      tabs.value.push({ id: 2, name: "Provider", title: "View", icon: null, url: null, hash: null, });
      tabs.value.push({ id: 3, name: "Provider", title: "Add Provider", icon: "add_circle", url: "providerAdd", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("View"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // UI modification
    const maxRule = (text) => {
      let maxLength = 15;
      if (text.length > maxLength) {
        return ` ${text.substring(0, maxLength)} ... `;
      } else {
        return ` ${text} `;
      }
    };

    onMounted(() => {
      // load tab
      tabsSmall.value.push({ id: 1, title: "Profile" });
      tabsSmall.value.push({ id: 2, title: "Tariff" });
      // set default
      currTabSmall.value = 1;
    });

    // handle curr status
    const handleActiveSmall = (tab) => {
      // update show and components
      currTabSmall.value = tab;
    };

    // Title
    watchEffect(() => {
      if (response.value) {
        // load title
        setTimeout(() => {
          var elems = document.querySelectorAll(".tooltipped");
          M.Tooltip.init(elems, {});
        }, 2000);
      }
    });

    // Initiate
    onMounted(() => {
      // get provider
      (async () => {
        const { res, err } = await getItem("provider", props.id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    });

    // handle emitted update
    const handleUpdate = (val) => {
      response.value = val;
    };

    // output
    const provider = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleActive,
      home,
      currTab,
      tabs,
      handleActiveSmall,
      handleUpdate,
      capWords,
      maxRule,
      isReady,
      tabsSmall,
      currTabSmall,
      error,
      providerId,
      provider,
    };
  },
};
</script>

<style scoped>
.pointer {
  cursor: default;
}

.custom-nav {
  height: 15px;
}
</style>