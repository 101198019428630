<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div v-if="contract">
      <div class="card-panel">
        <div class="row">
          <div class="col s4"><span>Client</span></div>
          <div class="col s4">{{ contract.client }}</div>
          <div class="col s4">
            <i class="material-icons grey-text right">check_circle</i>
          </div>
        </div>
      </div>

      <div class="card-panel">
        <div class="row">
          <div class="col s4">
            <span>{{ cat == "tpa" ? "TPA" : "Policy" }} Year</span>
          </div>
          <div class="col s4">
            {{
              new Date(contract.start).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}

            -

            {{
              new Date(contract.end).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
          </div>
          <div class="col s4">
            <i class="material-icons grey-text right">{{
              contract.status == 0 ? "schedule" : "check_circle"
            }}</i>
          </div>
        </div>
      </div>

      <div class="card-panel">
        <div class="row">
          <div class="col s4"><span>Plans</span></div>
          <div class="col s4">
            <span v-for="plan in contract.plans" :key="plan.id">
              {{ plan.name }}
              <span
                v-if="
                  plan.premiums.filter((x) => x.scope > 0).length &&
                  cat != 'tpa'
                "
                ><em>(Customized)</em></span
              ><br />
            </span>
          </div>
          <div class="col s4">
            <i class="material-icons grey-text right">{{
              contract.status == 0 ? "schedule" : "check_circle"
            }}</i>
          </div>
        </div>
      </div>

      <div class="card-panel" v-if="cat != 'tpa'">
        <div class="row">
          <div class="col s4"><span>Premiums</span></div>
          <div class="col s4">
            <span v-for="plan in contract.plans" :key="plan.id">
              ₦{{
                changeToNaira(
                  plan.premiums.filter((x) => x.type == "i")[0].amount
                )
              }}
              {{ plan.name }} <small>Individual</small>
              <br />
              ₦{{
                changeToNaira(
                  plan.premiums.filter((x) => x.type == "f")[0].amount
                )
              }}
              {{ plan.name }} <small>Family</small>
              <br />
            </span>
          </div>
          <div class="col s4">
            <i class="material-icons grey-text right">{{
              contract.status == 0 ? "schedule" : "check_circle"
            }}</i>
          </div>
        </div>
      </div>

      <div class="card-panel">
        <div class="row">
          <div class="col s4"><span>Payment Installments</span></div>
          <div class="col s4">
            <span
              v-for="installment in contract.installments"
              :key="installment.id"
            >
              {{
                new Date(installment.start).toLocaleString(undefined, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
              }}
              <br />
            </span>
          </div>
          <div class="col s4">
            <i class="material-icons grey-text right">{{
              contract.status == 0 ? "schedule" : "check_circle"
            }}</i>
          </div>
        </div>
      </div>
    </div>

    <!-- Unloaded contract -->
    <div class="section" v-else>
      <div class="card-panel">
        <div class="row center">
          <div class="col s12"><span>Sorry, Contract isn't loading!</span></div>
        </div>
      </div>
    </div>
  </template>
</template>
      
    <script>
import { computed, onMounted, ref } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItemsOfItems from "@/composables/getItemsOfItems";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "ContractClientComponent",
  components: { LoadingDiv },
  props: ["cat", "clientId"],
  setup(props) {
    const response = ref(null);
    const isReady = ref(false);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const show = ref(false);

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // to naira
    const changeToNaira = (val) => {
      // conclude
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // starter
    onMounted(() => {
      // start from the top
      getStatus();
    });

    // prinipals
    const getStatus = () => {
      // loader
      isReady.value = false;
      // set current Url
      currUrl.value = "operator/client/status/policy/info";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value, props.clientId);
    };

    // handle download
    const handleDownload = (val) => {
      console.log(val);
    };

    // handle show
    const handleShow = (val) => {
      show.value = val;
    };

    // enrollee ction
    const handleView = (enrollee) => {
      console.log(enrollee);
    };

    // output pages
    const contract = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleShow,
      handleView,
      handleDownload,
      changeToNaira,
      isReady,
      error,
      show,
      contract,
    };
  },
};
</script>
      
    
  <style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>