<template>

  <!-- Top -->
  <div id="top"></div>

  <!-- Main -->
  <div class="row">
    <div class="col s12">

      <LoadingDiv v-if="!isReady" />

      <!-- Remove secton class -->
      <div class="sectionxxx" v-else>

        <div class="center">
          <span v-for="tab in tabsState" :key="tab.id" :class="{
            'tab-custom-active': tab.id === currState,
            'tab-custom chip': tab.id !== currState,
          }" @click="handleState(tab.id)">
            {{ tab.title.toUpperCase() }}
          </span>
          <br /><br /><br />
        </div>

        <!-- Claims details -->
        <div class="card-panel z-depth-4" v-if="!currCluster && clusters.length">
          <div class="row">
            <div class="col s1">#</div>
            <div class="col s3">Provider <small>(claims)</small></div>
            <div class="col s2">Sent</div>
            <div class="col s2">Approved</div>
            <div class="col s3">Claims <small>(scheme)</small></div>
            <div class="col s1"><br /></div>
          </div>
        </div>

        <div v-for="(cluster, index) in clusters" :key="cluster.id">
          <div class="card-panel" :class="{
            'blue-grey lighten-5': (index + 1) % 2 === 1,
            'z-depth-1': currCluster == cluster,
          }" v-if="currCluster == cluster || !currCluster">
            <div class="row">
              <div class="col s1">
                <p>{{ numberBadge(index) }}</p>
              </div>
              <div class="col s3">
                <h6>
                  <span v-if="
                    cluster.encounters.filter((x) => x.supervisor_flag == 'y')
                      .length
                  "><i class="tiny material-icons red-text pointer" title="supervisor issue">flag</i></span>
                  <span v-if="
                    cluster.encounters.filter((x) => x.hod_flag == 'y')
                      .length
                  "><i class="tiny material-icons red-text pointer" title="hod issue">flag</i></span>
                  <span v-if="
                    cluster.encounters.filter((x) => x.compliance_flag == 'y')
                      .length
                  "><i class="tiny material-icons red-text pointer" title="compliance issue">flag</i></span>
                  {{ capWords(cluster.provider.name) }}
                  <br />
                  <span v-if="cluster.status === 'r' || cluster.status === 'v'"><small>({{ cluster.encounters.length }}
                      Claims)</small></span>
                </h6>
              </div>
              <div class="col s2">
                <p>
                  <span class="red-text" v-if="cluster.status === 'r' || cluster.status === 'v'">₦{{
                    grossTotalSent(cluster.encounters) }}</span>
                </p>
              </div>
              <div class="col s2">
                <p>
                  <span class="green-text" v-if="cluster.status === 'r' || cluster.status === 'v'"><b>₦{{
                    grossTotalApproved(cluster.encounters) }}</b></span>
                </p>
              </div>
              <div class="col s3">
                <p>
                  {{ toMonthYear(cluster.month_year) }}
                  <small><b>({{ cluster.scheme.name }})</b></small>
                </p>
              </div>
              <div class="col s1">
                <span v-if="
                  (cluster.status === 'r' || cluster.status === 'v') &&
                  !currCluster
                ">
                  <a class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"><i
                      class="material-icons" @click="handleView(cluster)" title="details">chevron_right</i></a>
                </span>
                <span class="right" v-else>
                  <a class="btn-floating btn-medium waves-effect waves-light light-blue right"><i class="material-icons"
                      @click="handleClear()" title="go back">keyboard_return</i></a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Cluster details -->
        <ClaimDiv :clusterId="currCluster.id" @reset="handleReset" v-if="currCluster" />

        <!-- Pages -->
        <template v-else>
          <div class="section center" v-if="!clusters.length">
            <h6><img class="responsive-img" src="@/assets/img/no-claim.png" width="256" title="No claims found" /></h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import getItems from "@/composables/getItems";
import ClaimDiv from "@/components/supervisor/SupervisorSingleClaimComponent.vue";
import {
  grossTotalSentFx,
  grossTotalApprovedFx,
} from "@/composables/calFunction/cal";
import {
  toMonthYearFx,
  capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "SupervisorClaimsComponent",
  components: { LoadingDiv, PaginationDiv, ClaimDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currCluster = ref(false);
    const currUrl = ref(null);
    const currHook = ref(null);
    const currServerDate = ref(null);
    const tabsState = ref([]);
    const currState = ref(null);

    // vue router
    const router = useRouter();

    // store
    const store = useStore();

    // start
    onMounted(() => {
      // load tab
      tabsState.value.push({ id: 0, title: "All" });

      // load tab
      store.state.user.schemes.forEach((x) => {
        tabsState.value.push({ id: x.id, title: capWords(x.name) });
      })

      // set default (all)
      currState.value = 0;
    });

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // get started
      getPendingClaims(0);
    });

    // get fresh load
    const getPendingClaims = (val) => {
      // restart
      isReady.value = false;
      // set current Url
      currUrl.value = `cluster/supervisor/vetted?u=${store.state.user.id}&s=${val}`;
      // set current Hook
      currHook.value = "&";
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // change state
    const handleState = (val) => {

      // clear all
      handleClear();

      // set
      currState.value = val;
      router.push({ hash: "#top" });

      // restart
      getPendingClaims(currState.value);

    }

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      return grossTotalSentFx(encounters);
    };

    // total calculations (gross approved)
    const grossTotalApproved = (encounters, db) => {
      return grossTotalApprovedFx(encounters, db);
    };

    // convert to Month & Year
    const toMonthYear = (val) => {
      return toMonthYearFx(val);
    };

    // payment status
    const checkPaid = (payment, repayment) => {
      let val = false;

      if (payment) {
        if (payment.stage == "c") {
          val = true;
        }
      }

      if (repayment) {
        if (repayment.stage == "c") {
          val = true;
        }
      }

      return val;
    };

    // update cluster
    const handleReset = (val) => {
      if (val != "reload") {
        response.value.data.forEach((x, y) => {
          if (x.id == val.clusterId) {
            x.encounters.forEach((z, k) => {
              if (z.id == val.encounterId) {
                response.value.data[y].encounters[k] = {
                  ...z,
                  supervisor_flag: val.newFlag,
                  supervisor_comment: val.newComment,
                  status: val.newStatus,
                };
              }
            });
          }
        });
      } else {
        // supervisor completed (reload page)
        getPendingClaims(currState.value);
        // kill currCluster
        currCluster.value = null;
      }
    };

    // enrollee ction
    const handleClear = () => {
      currCluster.value = null;
    };

    // enrollee ction
    const handleView = (val) => {
      // set
      currCluster.value = val;
      // back to top
      router.push({ hash: "#top" });
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // output results
    const clusters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data; // get data portion
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });


    return {
      capWords,
      numberBadge,
      grossTotalSent,
      grossTotalApproved,
      toMonthYear,
      checkPaid,
      handleState,
      handleReset,
      handlePage,
      handleClear,
      handleView,
      tabsState,
      currState,
      currServerDate,
      currCluster,
      isReady,
      isLoading,
      error,
      clusters,
      allPages,
    };
  },
};
</script>

<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}
</style>