<template>
  <div class="grey lighten-5"> <!-- blue-grey lighten-5 -->
    <Header />
    <main>
      <router-view />
    </main>
    <!-- <Footer /> -->
     <br /><br /><br /><br />
  </div>
</template>


<script>
import Header from "@/components/HeaderDiv.vue";
// import Footer from "@/components/FooterDiv.vue";

export default {
  name: "App",
  components: { Header },
  setup() {},
};
</script>

<style>
</style>