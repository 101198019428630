import store from '@/store';

// format url
function urlFormat(turl) {
    let scheme_type = store.state.user.schemes.find(x => x.id === turl.sid)?.type;
    if (scheme_type === 's') {
        return `operator/tariff/search?z=${turl.sid}&p=0`;
    } else if (scheme_type === 'p') {
        return `operator/tariff/search?z=${turl.sid}&p=${turl.pid}`;
    }
}

export {
    urlFormat,
}