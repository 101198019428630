import store from '@/store'

// clear
function clearCache() {
    store.commit("loadUser", null);
    store.commit("loadCluster", null);
    store.commit("loadCache", []);
}

export {
    clearCache,
}