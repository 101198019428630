import { ref } from 'vue';
import { adminAPI } from '@/laravel/config';

const getItem = async (table, id, destination) => {
    const res = ref(null);
    const err = ref(null);
    let endPoint = null;
    
    // checker
    if (destination) {
        // custom
      endPoint = destination;
    } else {
        // default
      endPoint = adminAPI;
    }

    // start
    try {
        const response = await fetch(endPoint + `/${table}` + `/${id}`);
        if (!response.ok) {
            throw new Error(`Network response was not OK: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        res.value = data;
    } catch (error) {
        err.value = error.message;
    }

    return { res, err };
};

export default getItem;