<template>

  <!-- UI -->
  <nav class="custom-nav teal"></nav>

  <!-- custom menu -->
  <nav class="nav-extended teal">
    <div class="nav-wrapper container">
      <a href="#!" class="brand-logo" @click.prevent="handleActive(home)">{{ home?.name }}</a>
      <ul class="right hide-on-med-and-down">
        <li v-for="tab in tabs" :key="tab.id" :class="{ 'active indigo lighten-2': tab.id == currTab.id }"><a href="#"
            @click.prevent="handleActive(tab)">{{ tab.title
            }}</a>
        </li>
      </ul>
      <br />
    </div>
    <div class="nav-content container" v-if="isReady">
      <ul class="tabs tabs-transparent grey-text text-lighten-4">
        <li class="tab">
          <span class="chip black white-text clickable" @click="handleActiveSmall()">{{ capWords(client?.name) }}</span>
        </li>
      </ul>
      <br />
    </div>
  </nav>


  <!-- search layer -->
  <div class="grey lighten-4">
    <div class="container section"></div>
  </div>


  <div class="container">

    <!-- <div class="card-content">
      <div class="row">
        <div class="col s4">
          <h3 class="header">
            <router-link :to="{ name: 'retail' }" class="blue-grey-text text-darken-2">Retail <small>(ePlans)</small></router-link>
          </h3>
          <h5 class="header teal-text text-darken-3 ups bar-over-text" @click="handleActiveSmall()">
            <small>
              <span class="teal-text text-darken-3 tooltipped pointer" data-position="bottom"
                data-tooltip="HMO Client" v-if="client && 'name' in client">
                <i class="tiny material-icons blue-grey-text text-darken-2">business</i> {{
                  client.name.toUpperCase() }}
              </span>
              <span v-else> Client... </span>
            </small>
          </h5>
        </div>
        <div class="col s8">
          <span v-for="tab in tabs" :key="tab.id" class="right" :class="{
            'tab-custom-active': tab.id == currTab.id,
            'tab-custom': tab.id != currTab.id,
          }" @click="handleActive(tab)">
            <span v-if="tab.icon"><i class="tiny material-icons cyan-text">{{ tab.icon }}</i></span>
            {{ tab.title }}
            <span v-if="tab.id == currTab.id"><i
                class="tiny material-icons blue-grey-text text-darken-2">check_circle</i></span>
          </span>

        </div>
      </div>

    </div> -->

    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <div class="card-content">
        <h6 class="center" v-if="currTabSmall">
          <span class="tab-custom blue-text" @click="handleActiveSmall()">
            <i class="tiny material-icons cyan-text">arrow_back</i> Menu</span>
          <span class="tab-custom-active">
            {{ currTabSmall }}</span>
        </h6>
      </div>

      <div class="section">
        <br />
        <div class="card-panel" v-if="!currTabSmall">
          <div v-for="tab in tabsSmall" :key="tab.id" @click="handleActiveSmall(tab.title)">
            <div v-if="tab.title === 'Spacer'">
              <br />
            </div>
            <div class="card-dashboard grey-text text-darken-2" v-else>
              <i class="medium material-icons blue-text text-lighten-3 secondary-content">chevron_right</i>
              <h5>{{ tab.title }}</h5>
            </div>
          </div>
        </div>

        <!-- Contract -->
        <ContractDiv :cat="cat" :clientId="clientId" v-if="currTabSmall == 'Client Contract'" />

        <!-- Status -->
        <StatusDiv :cat="cat" :clientId="clientId" v-if="currTabSmall == 'Status'" />

        <!-- Settings -->
        <SettingsDiv :cat="cat" :clientId="clientId" v-if="currTabSmall == 'Settings'" />

        <!-- Profile -->
        <ProfileDiv :cat="cat" :client="client" @update="handleUpdate" v-if="currTabSmall == 'Profile'" />

        <!-- Policy -->
        <PolicyDiv :cat="cat" :client="client" @reset="handlePolicyReset" v-if="currTabSmall == 'Plans -> All'" />

        <!-- Policy Add -->
        <PolicyAddDiv :cat="cat" :client="client" @reset="handlePolicyAdd" v-if="currTabSmall == 'Plans -> Add'" />

        <!-- Enrollees Lobby -->
        <EnrolleesDiv :authLevel="authLevel" :cat="cat" :clientId="clientId"
          :displayType="handleSplitForDisplay(currTabSmall)" v-if="currTabSmall == 'Principals -> Lobby'" />

        <!-- Enrollees Active -->
        <EnrolleesDiv :authLevel="authLevel" :cat="cat" :clientId="clientId"
          :displayType="handleSplitForDisplay(currTabSmall)"
          v-if="currTabSmall == 'Principals & Dependants -> Active'" />

        <!-- Enrollees On-Hold -->
        <EnrolleesDiv :authLevel="authLevel" :cat="cat" :clientId="clientId"
          :displayType="handleSplitForDisplay(currTabSmall)"
          v-if="currTabSmall == 'Principals & Dependants -> (On-hold)'" />

        <!-- Enrollees Inactive -->
        <EnrolleesDiv :authLevel="authLevel" :cat="cat" :clientId="clientId"
          :displayType="handleSplitForDisplay(currTabSmall)" v-if="currTabSmall == 'Principals & Dependants -> Bin'" />

        <!-- Enrollees Download -->
        <EnrolleeDownloadDiv :cat="cat" :clientId="clientId" v-if="currTabSmall == 'Downloader'" />

        <!-- Payment -->
        <PaymentDiv :cat="cat" :clientId="clientId" v-if="currTabSmall == 'Invoices -> All'" />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getItem from "@/composables/getItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import ContractDiv from "@/components/client/ContractClientComponent.vue";
import StatusDiv from "@/components/client/StatusClientComponent.vue";
import SettingsDiv from "@/components/client/SettingsClientComponent.vue";
import EnrolleesDiv from "@/components/client/EnrolleeClientComponent.vue";

import EnrolleeDownloadDiv from "@/components/client/download/EnrolleeDownloadComponent.vue";

import ProfileDiv from "@/components/client/ProfileClientComponent.vue";
import PolicyDiv from "@/components/client/PolicyClientComponent.vue";
import PolicyAddDiv from "@/components/client/onboard/PolicyReviewAddOnboardComponent.vue";
import PaymentDiv from "@/components/client/PaymentClientComponent.vue";

import {
  capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";

import M from "materialize-css";


export default {
  name: "retailClientView",
  components: {
    LoadingDiv,
    ContractDiv,
    SettingsDiv,
    StatusDiv,
    ProfileDiv,
    PolicyDiv,
    PolicyAddDiv,
    EnrolleesDiv,
    EnrolleeDownloadDiv,
    PaymentDiv,
  },
  props: ["id"],
  setup(props) {
    const home = ref(null);
    const authLevel = 5;
    const cat = ref("retail");
    const isReady = ref(false);
    const response = ref(null);
    const error = ref(null);
    const currTabSmall = ref(null);
    const tabsSmall = ref([]);
    const clientId = ref(props.id);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "View Single Client | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Retail (ePlans)", title: "Home", icon: "arrow_back", url: "retail", hash: null, });
      tabs.value.push({ id: 2, name: "Retail (ePlans)", title: "View", icon: null, url: null, hash: null });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("View"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // Get client
    onMounted(() => {
      // get client details
      getClient();
    });

    // get client
    const getClient = () => {
      (async () => {
        const { res, err } = await getItem("client", props.id);

        if (res.value) {
          if (res.value.data.cat != cat.value) {
            // refer back home
            router.push({
              name: "home",
            });
          } else {
            // assign
            response.value = res.value.data;
          }
        } else if (err.value) {
          error.value = err.value;
          // back to retail
          router.push({
            name: "retail",
          });
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // UI modification
    const maxRule = (text) => {
      let maxLength = 15;
      if (text.length > maxLength) {
        return ` ${text.substring(0, maxLength)} ... `;
      } else {
        return ` ${text} `;
      }
    };

    // handle add
    const handlePolicyAdd = (val) => {
      if (val) {
        // reload
        response.value = val;
        currTabSmall.value = "Plans -> All"; // go back to Plans
      } else {
        currTabSmall.value = null; // restart menu
      }
    };

    // handle reet
    const handlePolicyReset = (val) => {
      if (val) {
        // reload
        response.value = val;
      }
    };

    // handle back
    const handleBack = () => {
      if (!currTabSmall.value) {
        router.push({ name: "retail" }); // final back
      } else {
        currTabSmall.value = null; // restart menu
      }
    };

    // handle split
    const handleSplit = (val, type) => {
      let newVal;
      let parts;
      //set
      if (val.includes("->")) {
        parts = val.split("->").map((s) => s.trim());
      } else {
        parts = [val, null];
      }
      // close
      if (type == "head") {
        newVal = parts[0];
      } else if (type == "body") {
        newVal = parts[1];
      }
      // return
      return newVal;
    };

    // handle split
    const handleSplitForDisplay = (val) => {
      let newVal;
      let parts;
      //set
      if (val.includes("->")) {
        parts = val.split("->").map((s) => s.trim());
        // close
        if (parts[1].toLowerCase() == "active") {
          newVal = "a";
        } else if (parts[1].toLowerCase() == "(on-hold)") {
          newVal = "h";
        } else if (parts[1].toLowerCase() == "bin") {
          newVal = "d";
        } else if (parts[1].toLowerCase() == "lobby") {
          newVal = "p";
        }
      } else {
        newVal = "a";
      }
      // return
      return newVal;
    };

    // Title
    watchEffect(() => {
      if (response.value) {
        // load title
        setTimeout(() => {
          var elems = document.querySelectorAll(".tooltipped");
          M.Tooltip.init(elems, {});
        }, 2000);
      }
    });

    // Initiate
    onMounted(() => {
      // load tab
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Status" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Client Contract" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Spacer" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Principals -> Lobby" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Principals & Dependants -> Active" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Principals & Dependants -> (On-hold)" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Principals & Dependants -> Bin" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Spacer" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Plans -> All" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Plans -> Add" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Spacer" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Invoices -> All" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Spacer" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Downloader" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Profile" });
      tabsSmall.value.push({ id: tabsSmall.value.length + 1, title: "Settings" });
      // set default
      currTabSmall.value = null;
    });

    // handle curr status
    const handleActiveSmall = (tab) => {
      // update show and components
      currTabSmall.value = tab;
    };

    // handle emitted update
    const handleUpdate = (val) => {
      response.value = val;
    };

    // output
    const client = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      home,
      capWords,
      handleActive,
      currTab,
      tabs,
      authLevel,
      cat,
      maxRule,
      handleSplit,
      handleSplitForDisplay,
      handleActiveSmall,
      handleUpdate,
      handleBack,
      handlePolicyAdd,
      handlePolicyReset,
      isReady,
      error,
      currTabSmall,
      tabsSmall,
      clientId,
      client,
    };
  },
};
</script>

<style scoped>
/* from general class */
.card-dashboard:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  background-color: #ebf5fb;
  color: #000000 !important;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
color: #000000;
border: 1px solid #ddd;
background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>