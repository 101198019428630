<template>
    <div class="row">
        <div class="col s12">
            <LoadingDiv v-if="!isReady" />

            <div class="section" v-else>
                <form @submit.prevent="handleSubmit">

                    <div class="card-panel">
                        <div class="input-field">
                            <select v-model="scheme">
                                <option :value="scheme.id" v-for="scheme in schemes" :key="scheme.id">
                                    {{ scheme.name }}
                                </option>
                            </select>
                            <label>Scheme</label>
                        </div>
                    </div>

                    <div class="card-panel" v-if="scheme">
                        <div class="input-field">
                            <select v-model="scope">
                                <option value="claim">
                                    By Claims
                                </option>
                                <option value="pay">
                                    By Payment
                                </option>
                            </select>
                            <label>Report Scope</label>
                        </div>
                    </div>

                    <div class="card-panel" v-if="scheme && scope">
                        <div class="input-field" @click="periodStart = null">
                            <select v-model="periodStart">
                                <option :value="thisMonthDate">
                                    {{ thisMonth }} (Current month)
                                </option>
                                <option :value="pastMonth.date" v-for="pastMonth in pastInactiveMonths"
                                    :key="pastMonth.date">
                                    {{ pastMonth.month }}
                                </option>
                            </select>
                            <label>Report Period</label>
                        </div>
                    </div>


                    <p>
                        <br /><br />
                        <button class="waves-effect waves-light btn-large" :class="{
                            disabled:
                                !scheme || !scope || !periodStart || isSubmitting,
                        }">
                            <i class="material-icons right">download</i>{{ isSubmitting ? `Downloading` : "Download"
                            }}
                        </button>
                    </p>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import { onMounted, onUpdated, ref } from "vue";
import { useStore } from "vuex";
import getItems from "@/composables/getItems";
import {
    activeMonths,
    pastMonths,
} from "@/composables/fxLibrary/months";
import LoadingDiv from "@/components/LoadingDiv.vue";
import {
    toMonthYearFx,
    capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";
import {
    grossTotalSentFx,
    grossTotalApprovedFx,
    batchTotalSentFx,
    batchTotalApprovedFx,
    paymentStatusFx,
} from "@/composables/calFunction/cal";
import {
    downloadExcelFx,
} from "@/composables/reportFunction/downloader";
import M from "materialize-css";

export default {
    name: "ClaimsInsightComponent",
    components: { LoadingDiv },
    setup() {
        const isReady = ref(true);
        const error = ref(null);
        const isSubmitting = ref(false);
        const scheme = ref(null);
        const scope = ref(null);
        const thisMonth = ref(null);
        const thisMonthDate = ref(null);
        const pastInactiveMonths = ref([]);
        const periodStart = ref(null);

        // store
        const store = useStore();

        // select
        onMounted(() => {
            // activate select field
            const elemsSelect = document.querySelectorAll("select");
            M.FormSelect.init(elemsSelect, {});
        });

        // select
        onUpdated(() => {
            // activate select field
            const elemsSelect = document.querySelectorAll("select");
            M.FormSelect.init(elemsSelect, {});
        });

        // payment status
        const paymentStatus = (cluster) => {
            return paymentStatusFx(cluster);
        }
        // batch total approved
        const batchTotalApproved = (clusters) => {
            let batch = {};
            batch.clusters = clusters;
            return batchTotalApprovedFx(batch);
        };

        // batch total sent
        const batchTotalSent = (clusters) => {
            let batch = {};
            batch.clusters = clusters;
            return batchTotalSentFx(batch);
        };

        // total calculations (gross sent)
        const grossTotalSent = (encounters) => {
            return grossTotalSentFx(encounters);
        };

        // total calculations (gross approved)
        const grossTotalApproved = (encounters, db) => {
            return grossTotalApprovedFx(encounters, db);
        };

        //from functions
        const capWords = (val) => {
            return capWordsFx(val);
        };

        // convert to Month & Year
        const toMonthYear = (val) => {
            return toMonthYearFx(val);
        };

        // month initialization
        onMounted(() => {
            // active
            const { fx: active } = activeMonths();
            // active months
            thisMonth.value = active.value.thisMonth;
            thisMonthDate.value = active.value.thisMonthDate;

            // past
            const { fx: past } = pastMonths();
            // past month array
            pastInactiveMonths.value = past.value;
        });

        // clear
        const clearForm = () => {
            isReady.value = true;
            isSubmitting.value = false;
            periodStart.value = null;
            scheme.value = null;
            scope.value = null;
        };

        // handle submit
        const handleSubmit = async () => {
            if (scheme.value || scope.value || periodStart.value
            ) {
                isReady.value = false;

                let period;
                let searchParams;

                if (scope.value === 'claim') {
                    period = periodStart.value.slice(5, 7).replace(/^0/, '') + '_' + periodStart.value.slice(0, 4);
                } else {
                    period = periodStart.value // unchanged
                }

                // get records
                searchParams = `?s=${scheme.value}&t=${scope.value}&p=${period}`;
                const { res, err } = await getItems(`operator/insight/claims${searchParams}`);

                if (res.value) {

                    let paid = []; // hmo
                    let complete = []; // gov
                    let pending = [];

                    res.value.data.forEach((x) => {
                        if (x.payment && x.payment?.stage === 'c') {
                            paid.push({ ...x });
                        } else if (x.payment && x.payment?.stage === 'z') {
                            complete.push({ ...x });
                        } else {
                            pending.push({ ...x });
                        }

                    });


                    // download
                    let data = [];

                    // check complete
                    if (complete.length) {
                        // top
                        complete.forEach((x, y) => {

                            // all
                            data.push({
                                No: y + 1,
                                Provider: capWords(x.provider.name),
                                Period: `${toMonthYear(x.month_year)} ${x.scheme.name} Claims`,
                                Sent: grossTotalSent(x.encounters),
                                Approved: grossTotalApproved(x.encounters),
                                Comment: paymentStatus(x),
                            })

                        })

                        // space
                        data.push({})

                        // final paid
                        data.push({
                            No: "",
                            Provider: "",
                            Period: "Total",
                            Sent: batchTotalSent(complete),
                            Approved: batchTotalApproved(complete),
                            Comment: "",
                        })

                        // space
                        data.push({})
                        // space
                        data.push({})

                    }

                    // check paid
                    if (paid.length) {
                        // top
                        paid.forEach((x, y) => {

                            // all
                            data.push({
                                No: y + 1,
                                Provider: capWords(x.provider.name),
                                Period: `${toMonthYear(x.month_year)} ${x.scheme.name} Claims`,
                                Sent: grossTotalSent(x.encounters),
                                Approved: grossTotalApproved(x.encounters),
                                Comment: paymentStatus(x),
                            })

                        })

                        // space
                        data.push({})

                        // final paid
                        data.push({
                            No: "",
                            Provider: "",
                            Period: "Total",
                            Sent: batchTotalSent(paid),
                            Approved: batchTotalApproved(paid),
                            Comment: "",
                        })

                        // space
                        data.push({})
                        // space
                        data.push({})

                    }


                    // check pending
                    if (pending.length) {
                        // top
                        pending.forEach((x, y) => {

                            // all
                            data.push({
                                No: y + 1,
                                Provider: capWords(x.provider.name),
                                Period: `${toMonthYear(x.month_year)} ${x.scheme.name} Claims`,
                                Sent: grossTotalSent(x.encounters),
                                Approved: x.status === 'v' ? grossTotalApproved(x.encounters) : 0,
                                Comment: paymentStatus(x),
                            })

                        })

                        // space
                        data.push({})

                        // final unpaid
                        data.push({
                            No: "",
                            Provider: "",
                            Period: "Total",
                            Sent: batchTotalSent(pending),
                            Approved: "", // keep empty for pending
                            Comment: "",
                        })

                        // space
                        data.push({})
                        // space
                        data.push({})

                    }


                    if (data.length) {
                        let schemeFullName;
                        let description;
                        let month = periodStart.value

                        schemeFullName = store.state.user.schemes.find(x => scheme.value === x.id).name.toUpperCase();
                        if (scope.value === "claim") {
                            description = "By_Submission";
                        } else {
                            description = "By_Payment_Only";
                        }

                        // end 
                        clearForm();

                        // fire function
                        return downloadExcelFx(data, `Claims_Report_${schemeFullName}_${month}_${description}`);

                    }

                    // end 
                    clearForm();

                } else {
                // error set    
                error.value = err.value;
                // end 
                clearForm();
                }
               
            } else {
                // error
                clearForm();
            }
        };


        return {
            isReady,
            isSubmitting,
            handleSubmit,
            error,
            scheme,
            scope,
            thisMonth,
            thisMonthDate,
            pastInactiveMonths,
            periodStart,
            schemes: store.state.user.schemes,
        };
    },
};
</script>

<style></style>