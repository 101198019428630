import { ref } from 'vue';
import { adminAPI } from '@/laravel/config';

const putItem = async (table, id, val, destination) => {
    const res = ref(null);
    const err = ref(null);
    let endPoint = null;
    
    // checker
    if (destination) {
        // custom
      endPoint = destination;
    } else {
        // default
      endPoint = adminAPI;
    }

    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(val)
        };

        const response = await fetch(endPoint + `/${table}` + `/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error(`Network response was not OK: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        res.value = data;
    } catch (error) {
        err.value = error.message;
    }

    return { res, err };
};

export default putItem;