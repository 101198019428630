<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="card" v-if="currClient">
      <ul id="projects-collection" class="collection z-depth-4">
        <li class="collection-item avatar">
          <i
            class="material-icons cyan circle"
            v-if="mLr(currClient?.invoice_groups) < 50"
            >check_circle</i
          >
          <i class="material-icons red accent-2 circle" v-else>flag</i>
          <h6 class="collection-header m-0">
            {{ currClient.name }}
          </h6>
          <p class="chip grey lighten-4">{{ currClient.cat.toUpperCase() }}</p>
          <span class="secondary-content clickable"
            ><i
              class="material-icons white-text orange"
              title="Close"
              @click.prevent="handleBack()"
              >clear</i
            ></span
          >
        </li>
        <li class="collection-item">
          <div class="row">
            <div class="col s9">
              <h5 class="collections-title">
                <strong
                  >₦{{ invoicesTotal(currClient?.invoice_groups) }}</strong
                >
                Premium
                <br />
                <strong>₦{{ claimsTotal(currClient?.invoice_groups) }}</strong>
                Claims
              </h5>
            </div>
            <div class="col s3">
              Policy Period <br />
              <span class="spacer pointer teal lighten-3" title="Policy starts"
                ><b>{{
                  new Date(currClient?.invoice_groups[0].start).toLocaleString(
                    undefined,
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )
                }}</b></span
              ><br />
              <span class="spacer pointer blue lighten-2" title="Policy ends"
                ><b>{{
                  new Date(
                    currClient?.invoice_groups[
                      currClient?.invoice_groups.length - 1
                    ].end
                  ).toLocaleString(undefined, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}</b></span
              >
            </div>
          </div>
          <div class="row">
            <div class="col s12">
              <div class="progress">
                <div
                  class="determinate"
                  :class="{
                    'blue darken-1': mLr(currClient?.invoice_groups) == 0,
                    'green darken-1':
                      mLr(currClient?.invoice_groups) > 0 &&
                      mLr(currClient?.invoice_groups) < 50,
                    'purple darken-1':
                      mLr(currClient?.invoice_groups) >= 50 &&
                      mLr(currClient?.invoice_groups) < 75,
                    'orange darken-1':
                      mLr(currClient?.invoice_groups) >= 75 &&
                      mLr(currClient?.invoice_groups) < 100,
                    'red darken-1': mLr(currClient?.invoice_groups) >= 100,
                  }"
                  :style="`width: ${mLr(currClient?.invoice_groups)}%`"
                  :title="`Utilization marker (${mLr(
                    currClient?.invoice_groups
                  )}%)`"
                ></div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div
        class="card-panel"
        v-for="invoiceGroup in currClient.invoice_groups"
        :key="invoiceGroup.id"
      >
        <div class="row">
          <div class="col s12">
            <ul id="projects-collection" class="collection">
              <li class="collection-item">
                <div class="row">
                  <div class="col s9">
                    <h6 class="collections-title">
                      <strong
                        >₦{{ invoicesTotal(invoiceGroup, "single") }}</strong
                      >
                      Premium
                      <br />
                      <strong
                        >₦{{ claimsTotal(invoiceGroup, "single") }}</strong
                      >
                      Claims
                    </h6>
                  </div>
                  <div class="col s3">
                    <span class="top-spacer"></span>
                    <span
                      class="spacer pointer"
                      :class="{
                        'teal lighten-5': (invoiceGroup.status = 'c'),
                        'grey lighten-3': !(invoiceGroup.status = 'c'),
                      }"
                      title="Sub-policy starts"
                      >{{
                        new Date(invoiceGroup?.start).toLocaleString(
                          undefined,
                          {
                            month: "short",
                            day: "numeric",
                          }
                        )
                      }}
                      -
                    </span>
                    <span
                      class="spacer pointer"
                      :class="{
                        'teal lighten-4': (invoiceGroup.status = 'c'),
                        'grey lighten-2': !(invoiceGroup.status = 'c'),
                      }"
                      title="Sub-policy ends"
                      >{{
                        new Date(invoiceGroup?.end).toLocaleString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col s12">
                    <div class="progress">
                      <div
                        class="determinate"
                        :class="{
                          'blue lighten-3': mLr(invoiceGroup, 'single') == 0,
                          'green lighten-3':
                            mLr(invoiceGroup, 'single') > 0 &&
                            mLr(invoiceGroup, 'single') < 50,
                          'purple lighten-3':
                            mLr(invoiceGroup, 'single') >= 50 &&
                            mLr(invoiceGroup, 'single') < 75,
                          'orange lighten-3':
                            mLr(invoiceGroup, 'single') >= 75 &&
                            mLr(invoiceGroup, 'single') < 100,
                          'red lighten-3': mLr(invoiceGroup, 'single') >= 100,
                        }"
                        :style="`width: ${mLr(invoiceGroup, 'single')}%`"
                        :title="`Utilization marker (${mLr(
                          invoiceGroup,
                          'single'
                        )}%)`"
                      ></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
      
<script>
import { onMounted, ref, computed } from "vue";
import getItems from "@/composables/getItems";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "clientsInsightComponent",
  components: {
    LoadingDiv,
  },
  props: ["clientId"],
  emits: ["reset"],
  setup(props, context) {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);

    // MLR
    const mLr = (invoiceGroup, type = "all") => {
      let invoices = 0;
      let claims = 0;

      if (type == "all") {
        // sort for all
        invoiceGroup.forEach((z) => {
          z.invoices.forEach((x) => {
            if (x.bill && x.bill_type === "i") {
              invoices = Number(invoices) + Number(x.bill);
            }
          });
        });
        invoiceGroup.forEach((z) => {
          z.invoices.forEach((x) => {
            if (x.claims) {
              claims = Number(claims) + Number(x.claims);
            }
          });
        });
      } else {
        // sort for one
        invoiceGroup.invoices.forEach((x) => {
          if (x.bill && x.bill_type === "i") {
            invoices = Number(invoices) + Number(x.bill);
          }
        });
        invoiceGroup.invoices.forEach((x) => {
          if (x.claims) {
            claims = Number(claims) + Number(x.claims);
          }
        });
      }

      // return
      if (!claims && invoices) {
        return 0;
      } else if (claims && !invoices) {
        return Math.round(claims);
      } else if (!claims && !invoices) {
        return 0;
      } else {
        return Math.round((claims / invoices) * 100);
      }
    };

    // total invoices
    const invoicesTotal = (invoiceGroup, type = "all") => {
      let total = 0;
      if (type == "all") {
        invoiceGroup.forEach((z) => {
          z.invoices.forEach((x) => {
            if (x.bill && x.bill_type === "i") {
              total = Number(total) + Number(x.bill);
            }
          });
        });
      } else {
        invoiceGroup.invoices.forEach((x) => {
          if (x.bill && x.bill_type === "i") {
            total = Number(total) + Number(x.bill);
          }
        });
      }
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // total claims
    const claimsTotal = (invoiceGroup, type = "all") => {
      let total = 0;
      if (type == "all") {
        invoiceGroup.forEach((z) => {
          z.invoices.forEach((x) => {
            if (x.claims) {
              total = Number(total) + Number(x.claims);
            }
          });
        });
      } else {
        invoiceGroup.invoices.forEach((x) => {
          if (x.claims) {
            total = Number(total) + Number(x.claims);
          }
        });
      }
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = `operator/insight/client/${props.clientId}`;
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    });

    // handle
    const handleBack = () => {
      // send back
      context.emit("reset", true);
    };

    // output results
    const currClient = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      claimsTotal,
      invoicesTotal,
      mLr,
      handleBack,
      isReady,
      isLoading,
      error,
      currClient,
    };
  },
};
</script>
      
  <style scoped>
.spacer {
  padding: 2px;
}

.top-spacer {
  padding: 10px;
}

.pointer {
  cursor: default;
}

.clickable {
  cursor: pointer;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
      color: #000000;
      border: 1px solid #ddd;
      background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>