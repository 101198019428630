<template>
  <div class="row">
    <div class="col s12">
      <form @submit.prevent="handleNext">
        <div class="section">
          <div class="card grey lighten-4">
            <div class="card-content">
              <span class="card-title teal-text"
                ><span class="chip">1/6</span> Client<i
                  class="material-icons right"
                  v-if="client"
                  >check_circle</i
                ></span
              >
            </div>
            <div class="card-panel teal-text">
              <div class="row">
                <div class="col s12">
                  <div class="input-field">
                    <input type="text" id="client" v-model="client" />
                    <label for="client">Client Name</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-content">
              <span class="card-title teal-text"
                ><span class="chip">2/6</span> Contacts<i
                  class="material-icons right"
                  v-if="address && email && phone"
                  >check_circle</i
                ></span
              >
            </div>

            <div class="card-panel">
              <div class="row">
                <div class="col s12">
                  <div class="input-field">
                    <input type="text" id="address" v-model="address" />
                    <label for="address">Office Address</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-panel">
              <div class="row">
                <div class="col s7">
                  <div class="input-field">
                    <input
                      type="email"
                      id="email"
                      v-model="email"
                      class="validate"
                    />
                    <label for="email">Email Address</label>
                  </div>
                </div>
                <div class="col s5">
                  <div class="input-field">
                    <input
                      type="text"
                      id="phone"
                      v-model="phone"
                      pattern="[0-9]{10,13}"
                      class="validate"
                    />
                    <label for="phone">Phone Number</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-content">
              <span class="card-title teal-text"
                ><span class="chip">3/6</span> Scheme<i
                  class="material-icons right"
                  >check_circle</i
                ></span
              >
            </div>

            <div class="card-panel">
              <div class="row">
                <div class="col s12">
                  <div class="input-field">
                    <input type="text" value="Private Scheme" readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section center">
          <p>
            <button
              class="waves-effect waves-light btn-large"
              :class="{
                disabled:
                isSubmitting || !client || !address || !email || !phone,
              }"
            >
              <i class="material-icons right"
                >chevron_right</i
              >{{ isSubmitting ? "Creating Account" : "Next" }}
            </button>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
    
<script>
import { ref } from "vue";
import postItem from "@/composables/postItem";

export default {
  name: "ProfileOnboardComponent",
  emits: ["next"],
  props: ["cat"],
  setup(props, context) {
    const isSubmitting = ref(false);
    const error = ref(null);
    const client = ref(null);
    const address = ref(null);
    const email = ref(null);
    const phone = ref(null);

    // handle netxt
    const handleNext = async () => {
      if (client.value || address.value || email.value || phone.value) {

        // end submit hold
      isSubmitting.value = true;

        // round up
        const newVal = {
          scheme_id: null, // added on the server (looks for private scheme id)
          cat: props.cat,
          name: client.value,
          address: address.value,
          phone: phone.value,
          email: email.value,
        };

        // post item
        const { res, err } = await postItem("client", newVal);

        if (res.value) {
          // success
          // emit new parameters
          context.emit("next", {
            id: "profile",
            value: res.value.data,
          });
        } else {
          error.value = err.value;
          console.log(error.value);
        }
      } else {
        error.value = "All fields not selected"; // most unlikely
      }
      // end submit hold
      isSubmitting.value = false;
    };

    return {
      isSubmitting,
      handleNext,
      error,
      client,
      address,
      email,
      phone,
    };
  },
};
</script>
    
  <style>
</style>