<template>

    <LoadingDiv v-if="!isReady" />

    <template v-else>

        <div class="section">
            <div class="card-panel grey-text text-darken-2"
                :class="{ 'red lighten-5': error && editProvider === provider }" v-for="provider in providers"
                :key="provider.id">
                <h5>{{ capWords(provider.name) }} <span class="secondary-content clickable"
                        :class="{ 'blue-text': editProvider !== provider, 'red-text': editProvider === provider, }"
                        @click.prevent="handleEdit(provider)" :title="editProvider === provider ? 'Clear' : 'Edit'"><i
                            class="material-icons">edit </i></span></h5>

                <div class="row" v-if="editProvider === provider">
                    <div class="col s7">
                        <input id="name" type="text" v-model="bank" />
                        <label class="active" for="name">Bank</label>
                    </div>
                    <div class="col s5">
                        <input id="name" type="number" v-model="account" />
                        <label class="active" for="name">Account</label>
                    </div>
                    <div class="col s12">
                        <button class="waves-effect waves-light btn right" :class="{
                            disabled: isSubmitting || !bank || !account,
                        }" @click.prevent="handleSubmit()">
                            Update
                            <i class="material-icons right" title="go back">check_circle</i>
                        </button>
                    </div>

                </div>

            </div>
        </div>

        <div class="section center" v-if="!providers.length">
            <h6>No Provider <em>found</em>...</h6>
        </div>

    </template>

</template>


<script>
import { computed, onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import putItem from "@/composables/putItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import {
    capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";


export default {
    name: "FinanceAccountsUpdate",
    components: { LoadingDiv },
    setup() {
        const response = ref(null);
        const isReady = ref(false);
        const isSubmitting = ref(false);
        const error = ref(null);
        const bank = ref(null);
        const account = ref(null);
        const editProvider = ref(null);

        // Initiate
        onMounted(() => {
            // load
            loadProvider();
        });

        // loader
        const loadProvider = () => {
            // start   
            isReady.value = false;
            // get provider
            (async () => {
                const { res, err } = await getItems("cluster/finance/pending/account");

                if (res.value) {
                    response.value = res.value.data;
                } else {
                    error.value = err.value;
                }

                // page is ready
                isReady.value = true;
            })();
        }

        //from functions
        const capWords = (val) => {
            return capWordsFx(val);
        };

        // handle clear
        const handleClear = () => {
            editProvider.value = null;
            bank.value = null;
            account.value = null;
            error.value = null;
            isSubmitting.value = false;
        };

        // handle emitted update
        const handleEdit = (val) => {
            // start
            let isOk = true;

            // load only empties
            if (editProvider.value === val) {
                isOk = false;
            }

            // clear all
            handleClear();

            // update
            if (isOk) {
                editProvider.value = val;
            }
        };

        // handle submit
        const handleSubmit = async () => {
            if (bank.value && account.value) {

                // start
                isSubmitting.value = true;
                error.value = false;


                // prepare for take off
                const newVal = {
                    bank: bank.value,
                    account: account.value,
                };

                // post item
                const { res, err } = await putItem(
                    "provider",
                    editProvider.value.id,
                    newVal
                );

                if (res.value) {
                    //update all
                    handleClear();
                    // reload
                    loadProvider();
                } else {
                    error.value = err.value;
                }

                // end here
                isSubmitting.value = false;
            }

        }


        // output
        const providers = computed(() => {
            if (!response.value) {
                return [];
            } else {
                let providersOnly = [];
                response.value.forEach((x) => {
                    providersOnly.push(x.provider);
                });
                return providersOnly;
            }
        });

        return {
            capWords,
            handleEdit,
            handleSubmit,
            editProvider,
            bank,
            account,
            isReady,
            isSubmitting,
            error,
            providers,
        };
    },
};
</script>

<style scoped>
.pointer {
    cursor: default;
}

/* base class */
.tab-custom-small {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;
}

.tab-custom-small:hover {
    color: #000000 !important;
}

/* Removed
  color: #000000;
  border: 1px solid #ddd;
  background-color: #d1f3df; */
.tab-custom-active-small {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    border-top: 2px solid teal;
}

/* display */
.tab-custom-section {
    padding: 20px 0 0;
    border-top: 1px solid #ddd;
}
</style>