<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <!-- search layer -->
  <div class="grey lighten-4">
    <div class="container section"></div>
  </div>

  <div class="container">
    <br />
    <div class="section center">
      <span v-for="tab in tabsState" :key="tab.id" :class="{
        'tab-custom-active': tab.id == currState.id,
        'tab-custom': tab.id != currState.id,
      }" @click="handleActiveState(tab)">
        {{ tab.title }}
      </span>
    </div>

    <div class="row">
      <div class="col s12">
        <LoadingDiv v-if="!isReady" />

        <div v-else>
          <div class="section">
            <!-- Auth Code -->
            <div class="card" v-if="encounter">
              <span class="new badge left" data-badge-caption="Scheme">{{
                encounter.scheme.name.toUpperCase()
              }}</span>

              <!-- Enrollee -->
              <div class="card-content">
                <div class="row">
                  <div class="col s8">
                    <p>{{ encounter.client_name }}</p>
                    <h5>
                      {{ encounter.name }}
                      <sup><small><span class="black-text">{{
                        showAgeSex(encounter.dob, encounter.sex)
                            }}</span></small></sup>
                    </h5>
                    <h6>({{ encounter.enrollee_number }})</h6>
                  </div>
                  <div class="col s4">
                    <img :src="encounter.profile_img" width="96" class="circle responsive-img right"
                      v-if="encounter.profile_img" />
                    <img :src="`https://ui-avatars.com/api/?name=${encounter.name}&size=128`" width="96"
                      class="circle responsive-img right" v-else />
                  </div>
                </div>
              </div>

              <!-- Review (Auth) -->
              <template v-if="currState.title == 'Review'">
                <div class="card" :class="{
                  'blue-grey lighten-5': !error,
                  'red lighten-5': error,
                }">
                  <!-- Provider -->
                  <div class="card-panel blue-grey lighten-5">
                    <div class="row">
                      <div class="col s12">
                        <span class="card-title">{{
                          encounter.provider.name
                        }}</span>
                        <h6 class="teal-text">
                          {{
                            new Date(encounter.encounter_date).toLocaleString(
                              undefined,
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                weekday: 'long',
                              }
                            )
                          }}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <!-- Diagnosis -->
                  <div class="card-panel yellow lighten-1">
                    <span class="collections black-text">
                      <h6>
                        <i class="tiny material-icons">bookmark_border</i>
                        Diagnosis: {{ encounter.diagnosis }}
                        <span class="clickable" @click="handleEditDiagnosis" v-if="!isSubmitting">
                          <i class="material-icons secondary-content" v-if="!editDiagnosis">edit</i>
                          <i class="material-icons red-text secondary-content" v-else>edit</i>
                          </span>
                        <span class="pointable" v-else><i
                            class="material-icons grey-text secondary-content">edit</i></span>
                      </h6>
                    </span>

                    <span><a class="btn-floating btn-large halfway-fab waves-effect waves-light blue"
                        :class="{ 'disabled': isSubmitting }" :title="`${addAuth ? 'cancel' : 'Add Authorization'}`"
                        @click.prevent="handleAddAuth">
                        <i class="material-icons">{{ addAuth ? "cancel" : "add" }}</i>
                      </a>
                    </span>
                  </div>

                  <!-- Submitting Diagnosis  -->
                  <div class="progress" v-if="isSubmitting && editDiagnosis">
                    <div class="indeterminate"></div>
                  </div>

                  <!-- Update Diagnosis -->
                  <AddClaimSimple :editDiagnosis="editDiagnosis" :reset="error" @update="handleUpdate"
                    v-if="editDiagnosis" />

                  <!-- Approvals Display-->
                  <div class="card" v-if="auths.length">
                    <ol class="collection teal lighten-4">
                      <span v-for="(auth, index) in auths" :key="auth.id">
                        <!-- approved -->
                        <li class="collection-item teal lighten-4" v-if="auth.authorization == 'y'">
                          <b>{{ auth.item }}</b> x {{ auth.sent_qty }} (Charged:
                          ₦{{ auth?.sent_price ?
                            auth?.sent_price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
                          }}) -> (Approved: ₦{{ auth?.approved_price ?
                            auth?.approved_price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
                          }}) ->
                          <span class="blue-text tooltipped pointable" data-position="top"
                            :data-tooltip="auth.authorization_hmo_comment" v-if="auth.authorization_hmo_comment">({{
                              auth.authorization_code }})</span>
                          <span class="black-text" v-else>({{ auth.authorization_code }})</span>

                          <!-- Icon delete Magic -->
                          <span class="secondary-content green-text"
                            :class="{ 'clickable orange-text': iconDelete === auth, pointable: !(iconDelete === auth) }"
                            :title="`${iconDelete === auth ? 'Cancel Authorization' : 'Approved'}`" @click="handleAction({
                              type: 'decline',
                              auth: auth,
                              point: index,
                            })" @mouseenter="handleIconChange('delete', auth)"
                            @mouseleave="handleIconChange('reset', auth)">
                            <i class="material-icons" v-if="iconDelete === auth">cancel</i>
                            <i class="material-icons" v-else>check_circle</i>
                          </span>

                        </li>

                        <!-- declined -->
                        <li class="collection-item blue-grey lighten-4"
                          v-if="auth.authorization == 'z' && auth.status == 'd'">
                          <s>
                            <span class="tooltipped pointable" data-position="top"
                              :data-tooltip="auth.authorization_hmo_comment" v-if="auth.authorization_hmo_comment">
                              <b>{{ auth.item }}</b>
                            </span>

                            <span v-else>
                              <b>{{ auth.item }}</b>
                            </span>
                          </s>

                          <span class="secondary-content red-text pointable" title="Declined"><i
                              class="material-icons">cancel</i></span>
                        </li>
                        <div class="divider"></div>
                      </span>
                    </ol>
                  </div>

                  <!-- Submitting Authorization  -->
                  <div class="progress" v-if="isSubmitting && addAuth">
                    <div class="indeterminate"></div>
                  </div>

                  <!-- Requests -->
                  <span v-for="(auth, index) in auths" :key="auth.id">
                    <span v-if="auth.authorization == 'z' && auth.status == 'a'">
                      <div class="card-panel" v-if="auth.next == 'y'">
                        <form @submit.prevent="
                          handleAction({
                            type: action,
                            auth: auth,
                            point: index,
                          })
                          ">

                          <div class="row">
                            <!-- Approval -->
                            <template v-if="action == 'approve'">
                              <div class="input-field col s6">
                                <input v-model="item" id="item" type="text" readonly />
                                <label class="active teal-text" for="item">Item - {{ index + 1 }} <em>of</em> {{
                                  auths.length }}</label>
                              </div>
                              <div class="input-field yellow lighten-4 col s1">
                                <input v-model="qty" @input="handlePricing" id="qty" type="number" />
                              </div>
                              <div class="input-field col s2">
                                <input v-model="amt" id="amt" type="number" readonly />
                                <label class="active" for="amt">Amount</label>
                              </div>
                              <div class="input-field col s3">
                                <input v-model="comment" id="comment" type="text" :class="{
                                  validate: qty != auth.sent_qty && qty,
                                }" :required="qty != auth.sent_qty && qty" :readonly="qty == auth.sent_qty || !qty" />
                                <label :class="{
                                  'active teal-text':
                                    qty != auth.sent_qty && qty,
                                }" for="comment">Comment</label>
                              </div>
                            </template>

                            <!-- Decline Comment -->
                            <template v-else>
                              <div class="input-field col s12">
                                <input v-model="comment" id="comment" type="text" class="validate" required />
                                <label class="active" for="comment">Comment</label>
                              </div>
                            </template>

                            <!-- Approve -->
                            <div class="input-field col s12" v-if="action == 'approve'">
                              <a class="waves-effect waves-light btn red lighten-2" :class="{ disabled: isSubmitting }"
                                @click.prevent="
                                  handleAction({
                                    type: 'decline_confirm',
                                  })
                                  ">
                                <i class="medium material-icons left">clear</i>Decline
                              </a>

                              <button class="waves-effect waves-light btn teal lighten-1 right"
                                :class="{ disabled: isSubmitting }">
                                <i class="medium material-icons left">check_circle</i>{{ isSubmitting ? "Approve"
                                  : "Approve" }}
                              </button>
                            </div>

                            <!-- Decline Confirm -->
                            <div class="input-field col s12" v-else>
                              <a class="waves-effect waves-light btn black" :class="{ disabled: isSubmitting }"
                                @click.prevent="
                                  handleAction({
                                    type: 'back',
                                  })
                                  ">
                                <i class="medium material-icons left">chevron_left</i>Back
                              </a>
                              <button class="waves-effect waves-light btn red lighten-2 right"
                                :class="{ disabled: isSubmitting }">
                                <i class="medium material-icons left">highlight_off</i>{{ isSubmitting ? "Decline"
                                  : "Confirm" }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      <!-- Queue -->
                      <div class="card-panel" v-else>
                        <div class="row">
                          <div class="input-field col s6">
                            <input :value="auth.item" id="item" type="text" disabled />
                            <label class="active grey-text text-darken-2" for="item">Item - {{ index + 1 }}</label>
                          </div>
                          <div class="input-field grey lighten-4 col s1">
                            <input :value="auth.sent_qty" @input="handlePricing" id="qty" type="number" class="validate"
                              disabled />
                          </div>
                          <div class="input-field col s2">
                            <input :value="auth.sent_price" id="amt" type="number" disabled />
                            <label class="active" for="amt">Amount</label>
                          </div>
                          <div class="input-field col s3">
                            <input :value="auth.authorization_hmo_comment" id="comment" type="text" disabled />
                            <label class="active" for="comment">Comment</label>
                          </div>
                        </div>
                      </div>
                    </span>
                  </span>
                  <!-- End -->

                  <br />
                </div>
              </template>

              <!-- Add claim -->
              <AddClaimSimple :editDiagnosis="editDiagnosis" :reset="error" @update="handleUpdate" v-if="addAuth" />

              <!-- Benefits -->
              <BenefitsDiv id="7" v-if="currState.title == 'Benefits'" />

              <!-- History -->
              <HistoryAuth :enrolleeNumber="encounter.enrollee_number" v-if="currState.title == 'History'" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { ageSex } from "@/composables/fxLibrary/coreFx";
import getItemsOfItems from "@/composables/getItemsOfItems";
import putItem from "@/composables/putItem";
import postItem from "@/composables/postItem";
import SubHeading from "@/components/HeaderSubDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import AddClaimSimple from "@/components/claim/AddClaimSimpleComponent.vue";
import BenefitsDiv from "@/components/plan/ShowBenefitComponent.vue";
import HistoryAuth from "@/components/auth/HistoryAuthComponent.vue";
import M from "materialize-css";

export default {
  name: "authView",
  components: { SubHeading, LoadingDiv, AddClaimSimple, BenefitsDiv, HistoryAuth },
  props: ["encounterId"],
  setup(props) {
    const responseEncounter = ref(null);
    const responseAuths = ref([]);
    const isReady = ref(false);
    const isLoading = ref(true);
    const isSubmitting = ref(0);
    const editDiagnosis = ref(null);
    const addAuth = ref(false);
    const type = ref("auth"); // close claim after input
    const next = ref("next");
    const home = ref(null);
    const tabs = ref([]);
    const currTab = ref(null);
    const tabsState = ref([]);
    const currState = ref(null);
    const error = ref(null);
    const iconDelete = ref(null);
    const item = ref(null);
    const qty = ref(null);
    const amt = ref(null);
    const standard = ref(null);
    const comment = ref(null);
    const action = ref("approve");


    // router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Approval Authorization | EasyHMO";
    });

    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Pre-Auth", title: "Home", icon: "arrow_back", url: "auth", hash: null, });
      tabs.value.push({ id: 2, name: "Pre-Auth", title: "Review", icon: null, url: null, hash: null, });
      tabs.value.push({ id: 3, name: "Pre-Auth", title: "Add Auth", icon: "add_circle", url: "authAdd", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Review"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    onMounted(() => {
      // load tab
      tabsState.value.push({ id: 1, title: "Review" });
      tabsState.value.push({ id: 2, title: "Benefits" });
      tabsState.value.push({ id: 3, title: "History" });
      // set default
      currState.value = tabsState.value[0];
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // handle curr status
    const handleActiveState = (tab) => {
      // update show and components
      currState.value = tab;
    };

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // get function
    const getSingleItemFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          // encounter
          responseEncounter.value = res.value.data;
          // auths
          responseAuths.value = res.value.data.claims.filter(
            (x) => x.authorization != "n"
          );
          // sort auths
          responseAuths.value.sort((a, b) => {
            return a.authorization.localeCompare(b.authorization);
          });
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // starter
    onMounted(() => {
      // get items from function
      getSingleItemFromApi("encounter", props.encounterId);
    });

    // toolTip
    const toolTip = () => {
      var elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems, {});
    };

    // load after data loads and updates
    watchEffect(() => {
      if (responseAuths.value) {
        setTimeout(() => {
          toolTip();
        }, 2000);
      }
    });

    // populate as required
    watchEffect(() => {
      if (responseAuths.value.length) {
        // next
        handleNext();
      }
    });

    // switch icon
    const handleIconChange = (type, auth) => {
      if (type === "delete") {
        iconDelete.value = auth;
      }
      if (type === "reset") {
        iconDelete.value = false;
      }
    }

    // next
    const handleNext = () => {
      let newAuth = true;
      responseAuths.value.forEach((x) => {
        if (
          x.authorization == "z" &&
          x.status == "a"
        ) {
          if (newAuth) {
            // update values
            item.value = x.item;
            qty.value = x.sent_qty;
            amt.value = x.sent_price;
            standard.value = x.standard_price;
            comment.value = x.authorization_hmo_comment;
            // set next
            x.next = "y"; // yes
            // stop here
            newAuth = false;
          } else {
            // block others
            x.next = "n"; // no
          }
        }
      });
    }

    // pricing
    const handlePricing = () => {
      amt.value = (Number(qty.value) * Number(standard.value)).toFixed(2);
    };

    // clear variable
    const handleClear = () => {
      item.value = null;
      qty.value = null;
      amt.value = null;
      standard.value = null;
      comment.value = null;
      action.value = "approve";
      iconDelete.value = false;
    };

    // action
    const handleAction = async (val) => {
      // clear old errors
      error.value = false;

      if (qty.value || iconDelete.value) {
        // start
        let currVal = responseAuths.value[val.point];

        // operations
        if (val.type == "approve") {

          // new code
          let newAuthCode = `HMO-AUTH-${currVal?.id}`;

          // update
          let newVal = {
            approved_qty: qty.value,
            approved_price: amt.value,
            authorization_hmo_comment: comment.value,
            authorization: "y",
            authorization_code: newAuthCode,
            status: "a",
          };

          // start
          isSubmitting.value++;
          // clear variable
          handleClear();
          // load
          responseAuths.value[val.point] = {
            ...currVal,
            ...newVal,
            next: "n",
            sending: true,
          };

          // put item
          const { res, err } = await putItem("claim", currVal?.id, newVal);

          if (res.value) {
            // approved
            responseAuths.value[val.point].sending = false;
          } else {
            error.value = err.value;
            // reverse
            responseAuths.value[val.point] = currVal;
          }

          // end
          isSubmitting.value--;

        } else if (val.type == "decline_confirm") {

          // confirm decline
          action.value = "decline";
          error.value = false;

        } else if (val.type == "decline") {

          // update zero all figures
          let newVal = {
            sent_qty: 0,
            sent_price: 0,
            approved_qty: 0,
            approved_price: 0,
            standard_price: 0,
            authorization_hmo_comment: comment.value,
            authorization: "z", // as is
            status: "d",
          };

          // start
          isSubmitting.value++;
          // clear variable
          handleClear();
          // load
          responseAuths.value[val.point] = {
            ...currVal,
            ...newVal,
            next: "n",
            sending: true,
          };

          // post item
          const { res, err } = await putItem("claim", currVal?.id, newVal);

          if (res.value) {
            // declined
            responseAuths.value[val.point].sending = false;
          } else {
            error.value = err.value;
            // reverse
            responseAuths.value[val.point] = currVal;
          }

          // end
          isSubmitting.value--;

        } else if (val.type == "back") {

          // clear everything
          error.value = false;
          action.value = "approve";

        }

        // next
        handleNext();

      }
    };

    // handle auth
    const handleEditDiagnosis = () => {
      if (!editDiagnosis.value) {
        editDiagnosis.value = encounter.value.diagnosis;
      } else {
        editDiagnosis.value = null;
      }
    }

    // handle auth
    const handleAddAuth = () => {
      addAuth.value = !addAuth.value;
    }

    // handle update
    const handleUpdate = async (val) => {

      // clear old errors
      error.value = false;

      /* Auth
       Go Back
      */
      if (val?.id === 'clearAuth') {
        // start
        handleAddAuth();
      }

      /* Diagnosis
      Go Back
      */
      if (val?.id === 'clearDiagnosis') {
        // clear
        handleEditDiagnosis();
      }


      /* AUTH
        Add New Authorization
        to the server
      */
      if (val?.id === 'addNewAuth') {

        // start
        isSubmitting.value++;

        // new code (use encounter for newly created)
        let newAuthCode = `HMO-AUTH-${encounter.value.id}-${encounter.value.id}`;

        // update
        let newVal = {
          encounter_id: encounter.value.id,
          item: val.value.claim,
          sent_qty: val.value.qty,
          sent_price: val.value.amt,
          approved_qty: val.value.qty,
          approved_price: val.value.amt,
          standard_price: val.value.standard,
          authorization_code: newAuthCode,
          authorization: "y",
          status: "a",
        };

        // post update
        const { res, err } = await postItem("claim", newVal);

        if (res.value) {
          // success
          responseAuths.value.push({
            ...res.value.data,
            next: "n",
            sending: false,
          });

          // clear
          handleAddAuth();

        } else {
          error.value = err.value;
        }

        // clear
        isSubmitting.value--;

      }


      /* Diagnosis
         Update Current Diagnosis
         on the server
      */
      if (val?.id === 'resetDiagnosis') {

        // start
        isSubmitting.value++;

        // update
        let newVal = {
          diagnosis: val.value
        };

        // put item
        const { res, err } = await putItem("encounter", encounter.value.id, newVal);

        if (res.value) {
          // success
          responseEncounter.value.diagnosis = res.value.data.diagnosis;

          // clear
          handleEditDiagnosis();

        } else {
          error.value = err.value;
        }

        // clear
        isSubmitting.value--;

      }

    }

    // output encounter
    const encounter = computed(() => {
      if (!responseEncounter.value) {
        return null;
      } else {
        return responseEncounter.value;
      }
    });

    // output auths
    const auths = computed(() => {
      if (!responseAuths.value.length) {
        return [];
      } else {
        return responseAuths.value;
      }
    });


    return {
      showAgeSex,
      handleIconChange,
      handleActive,
      handleActiveState,
      handlePricing,
      handleAction,
      handleEditDiagnosis,
      handleAddAuth,
      handleUpdate,
      next,
      type,
      editDiagnosis,
      isReady,
      isLoading,
      isSubmitting,
      iconDelete,
      home,
      tabs,
      tabsState,
      currTab,
      currState,
      error,
      addAuth,
      item,
      qty,
      amt,
      action,
      comment,
      encounter,
      auths,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -15px;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
color: #000000;
border: 1px solid #ddd;
background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}

.custom-hr {
  width: 50%;
  /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc;
  /* Adjust the color and style as needed */
  margin: 20px 20px;
  /* Adjust margin as needed */
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}

.clickable {
  cursor: pointer;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}
</style>
