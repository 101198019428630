<template>

<!-- UI -->
<nav class="custom-nav grey"></nav>

<nav class="nav-extended grey">
  <div class="nav-wrapper container">
    <a href="#!" class="brand-logo" @click.prevent="handleActive(home)">{{ home?.name }}</a>
    <ul class="right hide-on-med-and-down">
      <li v-for="tab in tabs" :key="tab.id" :class="{ 'active indigo lighten-2': tab.id == currTab.id }"><a href="#"
          @click.prevent="handleActive(tab)">{{ tab.title
          }}</a>
      </li>
    </ul>
  </div>
  <div class="nav-content container">
    <br /><br />
  </div>
  <div class="nav-wrapper grey darken-1 container">
    <form>
      <div class="input-field">
        <input id="search" type="search" v-model="searchItem" placeholder="Search Providers by Name">
        <label class="label-icon" for="search"><i class="material-icons">search</i></label>
        <i
              class="material-icons white-text orange"
              @click="handleClearSearch"
              v-if="searchItem"
              >clear</i
            >
      </div>
    </form>
  </div>
</nav>

<!-- Loader -->
<div class="container progress" v-if="isLoading && isReady">
  <div class="indeterminate"></div>
</div>

<!-- search layer -->
<div class="grey lighten-4">
  <div class="container section"></div>
</div>

<!-- View Marker -->
<div id="view"></div>


<!-- Main -->
  <div class="container">

    <LoadingDiv v-if="!isReady" />

    <div v-else>
      <div class="section black-text center">
        <br />
        <h5 v-if="searchItem">
          <span class="black blue-text chip">Search <i class="tiny material-icons">check_circle</i></span>
          <span class="yellow lighten-2 black-text chip"><em>{{ searchItem }}</em></span>
        </h5>
        <h5 v-else>
          <span class="black blue-text chip">Display <i class="tiny material-icons">check_circle</i></span>
          <span class="yellow lighten-2 black-text chip"><em>All Providers</em></span>
        </h5>
      </div>

      <div class="section">
        <div class="card-panel" v-for="provider in providers" :key="provider.id">
          <span class="spacer pointer black-text secondary-content chip" :class="{
            'grey lighten-4':
              inDays(provider?.encounters[0].encounter_date) < 0,
            'teal lighten-4':
              inDays(provider?.encounters[0].encounter_date) > 0 &&
              inDays(provider?.encounters[0].encounter_date) <= 42,
            'yellow lighten-4':
              inDays(provider?.encounters[0].encounter_date) > 42 &&
              inDays(provider?.encounters[0].encounter_date) <= 84,
            'red lighten-4':
              inDays(provider?.encounters[0].encounter_date) > 84,
          }" title="last active date" v-if="provider?.encounters.length">
            <i class="tiny material-icons">account_circle</i>
            {{
              new Date(provider?.encounters[0].encounter_date).toLocaleString(
                undefined,
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }
              )
            }}
          </span>

          <span class="spacer pointer black-text secondary-content chip" :class="{
            'grey lighten-4':
              inDays(provider?.created_at) < 0,
            'teal lighten-4':
              inDays(provider?.created_at) > 0 &&
              inDays(provider?.created_at) <= 42,
            'yellow lighten-4':
              inDays(provider?.created_at) > 42 &&
              inDays(provider?.created_at) <= 84,
            'red lighten-4':
              inDays(provider?.created_at) > 84,
          }" title="last active date" v-else>
            <i class="tiny material-icons">account_circle</i>
            {{
              new Date(provider?.created_at).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
          </span>
          <h5>{{ provider.name }}</h5>
          <h6 class="grey-text darken-4">{{ provider.address }}</h6>
          <p>{{ provider.phone }} {{ provider.user?.email }}</p>
        </div>
      </div>

      <div class="section center" v-if="!providers.length">
        <h6>No Provider <em>found</em>...</h6>
      </div>

      <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getItems from "@/composables/getItems";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";

export default {
  name: "enrolleeHome",
  components: { LoadingDiv, PaginationDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const currUrl = ref(null);
    const currHook = ref(null);
    const serverTime = ref(null);
    const error = ref(null);
    const searchItem = ref(null);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Hospitals | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Hospitals", title: "Home", icon: null, url: "hospital", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    const inDays = (val) => {
      if (val && serverTime.value) {
        const date1 = new Date(val);
        const date2 = new Date(serverTime.value);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      } else {
        return -1;
      }
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          serverTime.value = res.value.server;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // get function
    const getSearchItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "provider";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    });

    // restart
    const handleClearSearch = () => {
      // start laoding
      isLoading.value = true;
      // null search
      searchItem.value = null;
      // set current Url
      currUrl.value = "provider";
      // set current Hook
      currHook.value = "?";
      // re-get items from function
      getItemsFromApi(currUrl.value);
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`); //
    };

    // search watcher
    watchEffect(() => {
      if (searchItem.value) {
        // start laoding
        isLoading.value = true;
        // set current Url
        currUrl.value = `provider/list/search?q=${searchItem.value}`;
        // set current Hook
        currHook.value = "&";
        // get searchItem items from function
        getSearchItemsFromApi(currUrl.value);
      }
    });

    // output results
    const providers = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleActive,
      home,
      currTab,
      tabs,
      numberBadge,
      handleClearSearch,
      handlePage,
      inDays,
      isReady,
      isLoading,
      error,
      searchItem,
      providers,
      allPages,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -10px;
}

.spacer {
  padding: 8px;
}

.top-spacer {
  padding: 10px;
}

.pointer {
  cursor: default;
}
</style>