<template>
  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" color="red lighten-2" @active="handleActive" />

<!-- search layer -->
<div class="grey lighten-4">
  <div class="container section"></div>
</div>

<!-- Top -->
<div id="top"></div>

    <div class="container">
      <LoadingDiv v-if="!isReady" />
  
      <template v-else>

        <br />

      <div class="section" v-if="currTab?.title.toLowerCase().includes('home')">
        <div class="card-panel">
          <div v-for="tab in tabs" :key="tab.id" @click="handleActive(tab)">
            <div class="card-dashboard grey-text text-darken-2" v-if="tab.title.toLowerCase() !== 'home'">
              <i class="medium material-icons blue-text text-lighten-3 secondary-content">chevron_right</i>
              <h5>{{ tab.title }}</h5>
            </div>
          </div>
        </div>
      </div>

          <!-- Invoices -->
          <InvoicesDiv
            v-if="currTab?.title.toLowerCase().includes('invoices')"
          />
  
          <!-- Claims -->
          <ClaimsDiv
            v-if="currTab?.title.toLowerCase().includes('claims')"
          />
        <!-- </div> -->
      </template>
    </div>
  </template>
    
  <script>
  import { onMounted, ref } from "vue";
  import SubHeading from "@/components/HeaderSubDiv.vue";
  import LoadingDiv from "@/components/LoadingDiv.vue";
  import InvoicesDiv from "@/components/compliance/ComplianceInvoicesComponent.vue";
  import ClaimsDiv from "@/components/compliance/ComplianceClaimsComponent.vue";
  
  export default {
    name: "financeHome",
    components: {
      SubHeading,
      LoadingDiv,
      InvoicesDiv,
      ClaimsDiv,
    },
    setup() {
      const home = ref(null);
      const isReady = ref(true);
      const error = ref(null);
      const currTab = ref(null);
      const tabs = ref([]);
  
      // start
      onMounted(() => {
        // page name
        document.title = "Compliance | EasyHMO";
      });
  
      // Initiate
      onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Compliance", title: "Home", icon: null, url: null, hash: null, });
      tabs.value.push({ id: 2, name: "Compliance", title: "Claims Review", icon: null, url: null, hash: null, });
      tabs.value.push({ id: 3, name: "Compliance", title: "Invoices Payment", icon: null, url: null, hash: null, });
      
      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));

      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
      });
  
      // handle curr status
      const handleActive = (tab) => {
        // update show and components
        currTab.value = tab;
      };
  
 
      return {
        home,
        handleActive,
        isReady,
        error,
        currTab,
        tabs,
      };
    },
  };
  </script>
    
    <style scoped>
  /* from general class */
  .card-dashboard:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
    background-color: #ebf5fb;
    color: #000000 !important;
  }
  
  .pointer {
    cursor: default;
  }
  
  /* base class */
  .tab-custom {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;
  }
  
  .tab-custom:hover {
    color: #000000 !important;
  }
  
  /* Removed
    color: #000000;
    border: 1px solid #ddd;
    background-color: #d1f3df; */
  .tab-custom-active {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid orange;
  }
  
  /* display */
  .tab-custom-section {
    padding: 20px 0 0;
    border-top: 1px solid #ddd;
  }
  </style>