<template>
  <div class="section">
    <div class="card grey lighten-4">
      <!-- Heading -->
      <div class="card-content">
        <span class="card-title teal-text"
          >Update Policy<i
            class="material-icons right"
            v-if="
              policyName && policyDesc && premiumIndividual && premiumFamily
            "
            >check_circle</i
          ></span
        >
      </div>

      <!-- Policy Name -->
      <div class="card-panel teal-text">
        <div class="row">
          <div class="col s12">
            <span class="black-text">Policy Name</span>
          </div>
        </div>
        <div class="row">
          <div
            class="col s12"
            :class="{ 'red lighten-5': error && !policyName }"
          >
            <div class="input-field">
              <input type="text" id="policyName" v-model="policyName" />
              <label class="active" for="policyName">Policy</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="card-panel teal-text">
        <div class="row">
          <div class="col s12">
            <span class="black-text">Policy Description</span>
          </div>
        </div>
        <div class="row">
          <div
            class="col s12"
            :class="{ 'red lighten-5': error && !policyDesc }"
          >
            <div class="input-field">
              <input type="text" id="policyDesc" v-model="policyDesc" />
              <label class="active" for="policyDesc">Description</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Premium -->
      <div class="card-panel teal-text">
        <div class="row">
          <div class="col s12">
            <span class="black-text">Premiums</span>
          </div>
        </div>
        <div class="row">
          <div class="col s1"><span class="chip">₦</span></div>
          <div
            class="col s4"
            :class="{
              'red lighten-5': error && !premiumIndividual,
            }"
          >
            <div class="input-field">
              <input
                type="number"
                id="premiumIndividual"
                v-model="premiumIndividual"
                class="validate"
                required
              />
              <label class="active" for="premiumIndividual"
                >Premium (Individual)</label
              >
            </div>
          </div>

          <div class="col s1"></div>

          <div class="col s1"><span class="chip">₦</span></div>
          <div
            class="col s4"
            :class="{ 'red lighten-5': error && !premiumFamily }"
          >
            <div class="input-field">
              <input
                type="number"
                id="premiumFamily"
                v-model="premiumFamily"
                class="validate"
                required
              />
              <label class="active" for="premiumFamily">Premium (Family)</label>
            </div>
          </div>

          <div class="col s1"></div>
        </div>
      </div>

      <!-- Benefits -->
      <div class="card-panel teal-text">
        <div class="row">
          <div class="col s12">
            <span class="black-text">Plan</span>
          </div>
        </div>
        <div class="row">
          <div
            class="col s12"
          >
            <div class="file-field input-field">
              <div class="btn" :class="{ disabled: isSubmitting }">
                <span>New Benefits</span>
                <input type="file" @change="handleFile" />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path" v-model="inputFile" type="text" />
                <p class="red-text" v-if="fileError">
                  {{ fileError }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section center">
    <p>
      <a
        class="waves-effect waves-light btn-large grey"
        :class="{
          disabled: isSubmitting,
        }"
        @click="handleBack"
      >
        <i class="material-icons left">chevron_left</i>Back
      </a>
      <button
        class="waves-effect waves-light btn-large black"
        :class="{
          disabled: isSubmitting,
        }"
        @click="handleUpdate"
      >
        <i class="material-icons left">add</i>
        {{ isSubmitting ? `Updating (${uploadProgress})` : "Update" }}
      </button>
    </p>
  </div>
</template>
    
<script>
import { ref, onMounted, watchEffect } from "vue";
import useStorage from "@/composables/useStorage";
import postItem from "@/composables/postItem";

export default {
  name: "PolicyEditOnboardComponent",
  props: ["policy"],
  emits: ["complete"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isSubmitting = ref(false);
    const error = ref(null);
    const next = ref(false);
    const policyName = ref(null);
    const policyDesc = ref(null);
    const premiumIndividual = ref(null);
    const premiumFamily = ref(null);
    const checkIndividual = ref(null);
    const checkFamily = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // starter
    onMounted(() => {
      // get policies
      // null all
      policyName.value = props.policy.name;
      policyDesc.value = props.policy.description;
      premiumIndividual.value = props.policy.premiumIndividual;
      premiumFamily.value = props.policy.premiumFamily;

      // checkers
      checkIndividual.value = props.policy.premiumIndividual;
      checkFamily.value = props.policy.premiumFamily;
    });

    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // handle add
    const handleUpdate = async () => {
      if (premiumIndividual.value && premiumFamily.value && policyDesc.value) {
        // Start Uploading
        isSubmitting.value = true;

        // initiate for update
        let plan = null;
          let individual = null;
          let family = null;
          let scope = null;

          // new plan
          plan = {
            id: props.policy.id,
            name: policyName.value,
            description: policyDesc.value,
          };

          // update individual premium
          if (checkIndividual.value != premiumIndividual.value) {
            individual = {
              plan_id: null, // update this on server
              amount: premiumIndividual.value,
              type: "i",
            };
          }

          // update family premium
          if (checkFamily.value != premiumFamily.value) {
            family = {
              plan_id: null, // update this on serve
              amount: premiumFamily.value,
              type: "f",
            };
          }

          // scope
          scope = 0; // get proper client id

        // File (Benefits)
        if (file.value) {
          // Start Uploading
          uploadError.value = null;

          // Cloud Function
          const fx = "planLoader";

          // round up
          const cloudInput = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "update",
          };

          // upload
          uploadFile(file.value, fx, cloudInput);
        } else {
          // update without File
          // (without File)
         
          // Start Uploading
          uploadProgress.value = "100%";

          // round up
          const newVal = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "update",
            benefits: [], // empty array (no Benefit change required)
          };

          // post item
          const { res, err } = await postItem("plan/benefits/bulk", newVal);

          if (res.value) {
            // success
            handleNext(res.value.inserted_rows);
          } else {
            error.value = err.value;
            isSubmitting.value = false;
          }
        }
      } else {
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // success
        handleNext(data.value.succ.inserted_rows);
      }
      if (uploadError.value) {
        // restart
        // handleReset();
        // stop
        error.value = uploadError.value;
        isSubmitting.value = false;
      }
    });

    // handle submit
    const handleNext = (total) => {
      // emit new parameters
      context.emit("complete", {
        id: "update",
        complete: true,
        data: {
          id: props.policy.id,
          status: props.policy.status,
          scheme_id: 1,
          premiumIndividual: premiumIndividual.value,
          premiumFamily: premiumFamily.value,
          name: policyName.value,
          description: "Standard Shelf Plan",
          benefitTotal: total,
          benefits: props.policy.benefits,
        },
      });
    };

    // handle submit
    const handleBack = () => {
      // emit new parameters
      context.emit("complete", {
        id: "update",
        complete: false,
        data: null,
      });
    };

    return {
      isSubmitting,
      handleUpdate,
      handleBack,
      handleNext,
      handleFile,
      fileError,
      uploadError,
      file,
      inputFile,
      uploadProgress,
      error,
      next,
      premiumIndividual,
      premiumFamily,
      policyName,
      policyDesc,
    };
  },
};
</script>
    
<style>
</style>