<template>

<SubHeading :home="home" :tabs="tabs" :currTab="currTab" color="teal" @active="handleActive" />

<!-- search layer -->
<div class="grey lighten-4">
  <div class="container section"></div>
</div>

  <div class="container">
        <!-- <div class="card-content">
          <router-link :to="{ name: 'tpa' }">
            <i class="medium material-icons blue-grey-text text-darken-2 right">monetization_on</i>
          </router-link>

          <h3 class="header blue-grey-text text-darken-2">
            <router-link :to="{ name: 'tpa' }"
              ><i class="small material-icons" title="Go Back"
                >subdirectory_arrow_left</i
              ></router-link
            >
            <small>
            <span
              class="tooltipped pointer"
              data-position="bottom"
              :data-tooltip="newClient.name"
              v-if="newClient"
              >{{ maxRule(newClient.name) }}</span
            >
            <span v-else> Add Client <span class="blue-grey-text text-lighten-2"><small>(TPA)</small></span></span>
          </small>
          </h3>
        </div> -->
<!-- 
    <div class="card-content">
      <div class="row">
        <div class="col s4">
          <h3 class="header">
            <router-link :to="{ name: 'tpa' }" class="blue-grey-text text-darken-2">TPA</router-link>
          </h3>
          <h5 class="header blue-grey-text text-darken-2 ups bar-over-text">
            <small>
              <span class="blue-grey-text text-darken-2 tooltipped pointer" data-position="bottom"
                data-tooltip="New Client" v-if="newClient">
                <i class="tiny material-icons teal-text text-lighten-2">monetization_on</i> {{
                  newClient.name.toUpperCase() }}
              </span>
              <span v-else> Add Client... </span>
            </small>
          </h5>
        </div>
        <div class="col s8">
          <span v-for="tab in tabs" :key="tab.id" class="right" :class="{
            'tab-custom-active': tab.id == currTab.id,
            'tab-custom': tab.id != currTab.id,
          }" @click="handleActive(tab)">
            <span v-if="tab.icon"><i class="tiny material-icons cyan-text">{{ tab.icon }}</i></span> 
            {{ tab.title }}
            <span v-if="tab.id == currTab.id"><i class="tiny material-icons blue-grey-text text-darken-2">check_circle</i></span>
          </span>

        </div>
      </div>

    </div> -->

    <br />
    <ProfileDiv :cat="cat" @next="handleNext" v-if="next == 'start'" />
    <PolicyDiv
      :cat="cat"
      :newClient="newClient"
      @next="handleNext"
      v-if="next == 'policy'"
    />
    <StatusDiv
      :cat="cat"
      :newClient="newClient"
      @next="handleNext"
      v-if="next == 'status'"
    />
    <SettingsDiv
      :cat="cat"
      :newClient="newClient"
      @next="handleNext"
      v-if="next == 'settings'"
    />
  </div>
</template>
  
<script>
import { ref, onMounted, watchEffect } from "vue";
import { useRouter } from "vue-router";
import SubHeading from "@/components/HeaderSubDiv.vue";
import ProfileDiv from "@/components/client/onboard/ProfileOnboardComponent.vue";
import PolicyDiv from "@/components/client/onboard/PolicyOnboardComponent.vue";
import StatusDiv from "@/components/client/onboard/StatusOnboardComponent.vue";
import SettingsDiv from "@/components/client/onboard/SettingsOnboardComponent.vue";
import M from "materialize-css";

export default {
  name: "tpaClientAdd",
  components: { SubHeading, ProfileDiv, PolicyDiv, StatusDiv, SettingsDiv },
  setup() {
    const home = ref(null);
    const cat = ref("tpa");
    const next = ref("start");
    const newClient = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Add Client | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "TPA", title: "Home", icon: "arrow_back", url: "tpa", hash: null, });
      tabs.value.push({ id: 2, name: "TPA", title: "View", icon: "visibility", url: "tpa", hash: "#view"});
      tabs.value.push({ id: 3, name: "TPA", title: "Add Client", icon: null, url: null, hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Add"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
      router.push({ name: tab.url, hash: tab.hash });
      }else{
      router.push({ name: tab.url });
      }
    };

    // Title
    watchEffect(() => {
      if (newClient.value) {
        // load title
        setTimeout(() => {
          var elems = document.querySelectorAll(".tooltipped");
          M.Tooltip.init(elems, {});
        }, 2000);
      }
    });

    // UI modification
    const maxRule = (text) => {
      let maxLength = 15;
      if (text.length > maxLength) {
        return ` ${text.substring(0, maxLength)} ... `;
      } else {
        return ` ${text} `;
      }
    };

    // handle next
    const handleNext = (val) => {
      if (val.id == "profile") {
        // new client name
        newClient.value = val.value;
        // next
        next.value = "policy";
      } else if (val.id == "policy") {
        next.value = "status";
      } else if (val.id == "status") {
        next.value = "settings";
      } else if (val.id == "settings") {
        //submit here
        router.push({ name: "tpaView", params: { id: newClient.value.id } });
      }
    };

    return {
      home,
      handleActive,
      currTab,
      tabs,
      cat,
      next,
      maxRule,
      handleNext,
      newClient,
    };
  },
};
</script>
  
<style>
.pointer {
  cursor: default;
}
</style>