// add
function addModules() {

    // start
    let tabs = [];
    
    // all
    tabs.push({
        id: tabs.length + 1,
        to: "supervisor",
        card: "card-1",
        icon: "check_circle",
        title: "Supervisor",
        description: "Review and approve claims.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "hod",
        card: "card-1",
        icon: "check_circle",
        title: "HOD",
        description: "Review and approve claims.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "compliance",
        card: "card-1",
        icon: "check_circle",
        title: "Compliance",
        description: "Review and approve claims. View invoices.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "finance",
        card: "card-1",
        icon: "account_balance",
        title: "Finance",
        description: "Pay claims. Confirm client payments.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "enrollee",
        card: "card-1",
        icon: "people",
        title: "Enrollees",
        description: "view active enrollees.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "auth",
        card: "card-2",
        icon: "insert_comment",
        title: "Pre-Authorizations",
        description: "manage pre-authorization.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "claim",
        card: "card-1",
        icon: "description",
        title: "Claims",
        description: "manage claims.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "private",
        card: "card-2",
        icon: "business",
        title: "HMO Clients",
        description:
            "Manage (HMO) clients, contracts, enrollees, invoices and more.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "public",
        card: "card-1",
        icon: "group_add",
        title: "Schemes",
        description:
            "Manage all State Social Health Insurance Schemes. Upload and review monthly lists.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "retail",
        card: "card-1",
        icon: "shopping_cart",
        title: "Retail Clients",
        description:
            "Manage (Retail) clients, contracts, enrollees, invoices and more.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "tpa",
        card: "card-2",
        icon: "monetization_on",
        title: "TPA (Retainer) Clients",
        description:
            "Manage (TPA) clients, contracts, enrollees, invoices and more.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "client",
        card: "card-2",
        icon: "business",
        title: "Clients",
        description: "View all clients",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "provider",
        card: "card-1",
        icon: "local_hospital",
        title: "Providers",
        description: "manage the providers.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "hospital",
        card: "card-2",
        icon: "label",
        title: "Hospitals",
        description: "view primary care hospital.",
        notification: null,
        special: false,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "report",
        card: "card-2",
        icon: "show_chart",
        title: "Insights",
        description: "View latest utilization patterns by clients, providers.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "advisory",
        card: "card-2",
        icon: "work",
        title: "Advisory",
        description: "View upcoming invoices for prompt payment.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "adder",
        card: "card-2",
        icon: "add_circle",
        title: "Adders",
        description: "Quickly add dependants to active enrollees.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "settings",
        card: "card-1",
        icon: "settings",
        title: "Settings",
        description:
            "Load Shelf Plans, Standard Tariffs, Create Users and other settings.",
        notification: null,
        special: true,
    });

    tabs.push({
        id: tabs.length + 1,
        to: "bypass",
        card: "card-1",
        icon: "file_upload",
        title: "Bypass",
        description:
            "Bypass the standard invoicing system - Directly add enrollees to the monthly list.",
        notification: null,
        special: true,
    });

    // return
    return tabs;
}

export {
    addModules,
}