<template>

  <div id="ctn-preloader" class="ctn-preloader">
    <div class="animation-preloader">
      <div class="spinner"></div>
      <div class="txt-loading">
        <span data-text-preloader="e" class="letters-loading">
          e
        </span>
        <span data-text-preloader="a" class="letters-loading">
          a
        </span>
        <span data-text-preloader="s" class="letters-loading">
          s
        </span>
        <span data-text-preloader="y" class="letters-loading">
          y
        </span>
        <span data-text-preloader="H" class="letters-loading">
          H
        </span>
        <span data-text-preloader="M" class="letters-loading">
          M
        </span>
        <span data-text-preloader="O" class="letters-loading">
          O
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";


export default {
  name: "LoadingHome",
  setup() {

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Loading ...";
    });


    // start
    onMounted(() => {
      // refer back home
      setTimeout(() => {

        router.push({
          name: "home",
        });

      }, 2000);


    });


    return {};
  },
};
</script>

<style>
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #fff;
}

.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1.5s infinite linear;
  border-radius: 50%;
  border: 3px solid rgb(101 92 151 / 6%);
  border-top-color: #655C97;
  border-bottom-color: #655C97;
  height: 170px;
  margin: 0 auto 45px auto;
  width: 170px;
}

/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #655c97;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 70px;
  position: absolute;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  color: rgb(101 92 151 / 14%);
  position: relative;
  font-size: 70px;
  line-height: 70px;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

/* Animación del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}
</style>