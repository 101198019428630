<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <!-- search layer -->
  <div class="grey lighten-4">
    <div class="container section"></div>
  </div>


  <div class="container">
    <div class="row">
      <div class="col s12">

        <!-- View Marker -->
        <div id="view"></div>

        <!-- Invoices -->
        <LoadingDiv v-if="!isReady" />

        <div class="section" v-else>

          <div class="center">
            <span v-for="tab in tabsState" :key="tab.id" :class="{
              'tab-custom-active': tab.state === currState,
              'tab-custom': tab.state !== currState,
            }" @click="handleActiveState(tab.state)">
              {{ tab.title }}
            </span>
            <br /><br /><br />
          </div>

          <!-- Invoices -->
          <div class="card teal lighten-5">
            <!-- Start -->
            <div v-for="(invoice, index) in invoices" :key="invoice.id">
              <!-- Principal -->
              <div class="card-panel" :class="{
                'amber lighten-5': invoice.status == 'p',
                'teal lighten-5': invoice.status == 'c',
                'purple lighten-5': invoice.status == 'z',
                'grey lighten-3': invoice.status == 'd',
              }" v-if="
                (currInvoice == invoice || !currInvoice) &&
                (invoice.status == 'p' || invoice.status == 'z')
              ">
                <div class="row">
                  <div class="col s1">
                    <h5>{{ index + 1 }}.</h5>
                  </div>
                  <div class="col s10">
                    <p>
                      <span v-if="
                        invoice.status == 'p' &&
                        invoice.start < currServerDate
                      ">Delayed Invoice<i class="tiny material-icons">schedule</i></span>
                      <span v-if="
                        invoice.status == 'p' &&
                        invoice.start > currServerDate
                      ">Pending Invoice</span>
                      <span class="orange-text text-darken-4" v-if="invoice.status == 'z'">Unpaid (Grace Period)</span>
                      <span v-if="invoice.status == 'c'">Completed Payment<i
                          class="tiny material-icons teal-text">check_circle</i></span>
                      <span class="red-text" v-if="invoice.status == 'd'">Deleted Invoice</span>
                    </p>
                    <h6>
                      {{ invoice.client.name }}
                      <span class="chip orange">{{
                        invoice.client.cat.toUpperCase()
                      }}</span>
                    </h6>
                    <h5>
                      ₦{{
                        changeToNaira(invoice.input_total, invoice.output_total)
                      }}
                      <span class=""><small>
                          {{
                            new Date(invoice.start).toLocaleString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                          }}
                        </small>

                        -

                        <small>
                          {{
                            new Date(invoice.end).toLocaleString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                          }}
                        </small>
                      </span>
                    </h5>
                  </div>
                  <div class="col s1">
                    <span class="right" v-if="!currInvoice">
                      <a class="btn-floating btn-medium waves-effect waves-light right" :class="{
                        red: invoice.start < currServerDate,
                        'light-blue lighten-2': !(
                          invoice.start < currServerDate
                        ),
                      }"><i class="material-icons" @click="handleView(invoice)"
                          title="view details">chevron_right</i></a>
                    </span>
                    <span class="right" v-else>
                      <a class="btn-floating btn-medium waves-effect waves-light light-blue right"><i
                          class="material-icons" @click="handleClear()" title="go back">keyboard_return</i></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Invoice details -->
          <InvoiceDiv :currInvoice="currInvoice" v-if="currInvoice" />

          <!-- Pages -->
          <template v-else>
            <div class="section center" v-if="!invoices.length">
              <h6>No Invoices <em>found</em>...</h6>
            </div>

            <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItems from "@/composables/getItems";
import SubHeading from "@/components/HeaderSubDiv.vue";
import InvoiceDiv from "@/components/invoice/InvoiceDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "advisoryHome",
  components: { SubHeading, LoadingDiv, PaginationDiv, InvoiceDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const error = ref(null);
    const currInvoice = ref(false);
    const currUrl = ref(null);
    const currHook = ref(null);
    const currServerDate = ref(null);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);
    const tabsState = ref([]);
    const currState = ref(null);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Advisory | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Advisory", title: "Home", icon: null, url: "advisory", hash: null, });
      tabs.value.push({ id: 2, name: "Advisory", title: "View Invoices", icon: "visibility", url: "advisory", hash: "#view" });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // start
    onMounted(() => {
      // load tab
      tabsState.value.push({ id: 1, title: "All Upcoming Invoices", state: "a" });
      tabsState.value.push({ id: 2, title: "Due Invoices", state: "d" });

      // set default
      currState.value = "a";
    });

    // review
    const handleActiveState = (val) => {
      // switch to display
      if (val == "a") {
        currState.value = "a";
      } else if (val == "d") {
        currState.value = "d";
      }
    };

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash && currTab.value.title !== "View") {
        router.push({ name: tab.url, hash: tab.hash });
      } else if (tab.title === "Home") {
        // home
        router.push({ name: tab.url });
        // reset default
        handleClear();
      }
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          currServerDate.value = res.value.server;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // to naira
    const changeToNaira = (i, o) => {
      // sort value
      let val = i - o;
      // conclude
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // starter
    onMounted(() => {
      // start from the top
      getInvoices();
    });

    // Invoices
    const getInvoices = () => {
      // loader
      isReady.value = false;
      // set current Url
      currUrl.value = "group/invoices/due";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // switch page
    const handlePage = (page) => {
      // loader
      isReady.value = false;
      // get new page items from function
      getItemsFromApi(
        `${currUrl.value}${currHook.value}page=${page}`
      );
    };

    // enrollee ction
    const handleClear = () => {
      currInvoice.value = null;
      // reset default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));
    };

    // enrollee ction
    const handleView = (val) => {
      currInvoice.value = val;
      if (val) {
        // set view
        currTab.value = tabs.value.find(x => x.title.includes("View"));
      }
    };

    // output results
    const invoices = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleActive,
      handleActiveState,
      home,
      currTab,
      tabs,
      tabsState,
      currState,
      handlePage,
      handleClear,
      handleView,
      changeToNaira,
      currServerDate,
      currInvoice,
      isReady,
      error,
      invoices,
      allPages,
    };
  },
};
</script>

<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}
</style>
