<template>
  <form @submit.prevent="handleSubmit">
    <div class="card-panel">
      <div class="input-field">
        <label class="active teal-text" for="plan">Plan</label>
        <select id="plan" v-model="plan" class="validate" required>
          <option v-for="plan in plans" :key="plan.id" :value="plan.id">
            {{ plan.name }}
          </option>
        </select>
         <!-- Loader -->
     <div class="progress" v-if="!isReady">
      <div class="indeterminate"></div>
    </div>
      </div>
    </div>

    <div class="card-panel">
      <div class="input-field">
        <label class="active teal-text" for="type">Type</label>
        <select id="type" v-model="type" class="validate" required>
          <option value="i">Individual</option>
          <option value="f">Family</option>
        </select>
      </div>
    </div>

    <div class="card-content">
      <p>
        <br />
        <a
          class="waves-effect waves-light btn-large black"
          :class="{
            disabled: isSubmitting,
          }"
          @click="handleBack"
        >
          <i class="material-icons left">chevron_left</i>Back
        </a>
        <button
          class="waves-effect waves-light btn-large"
          :class="{
            disabled:
              !isReady ||
              (enrollee.plan_id == plan && enrollee.type == type) ||
              isSubmitting,
          }"
        >
          <i class="material-icons right">check_circle</i
          >{{ isSubmitting ? "Updating" : "Update" }}
        </button>
      </p>
    </div>
  </form>
</template>
      
<script>
import { onMounted, onUpdated, ref, computed } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import putItem from "@/composables/putItem";
import M from "materialize-css";

export default {
  name: "EnrolleePlanEditOnboardComponent",
  props: ["enrollee"],
  emits: ["reset"],
  setup(props, context) {
    const plan = ref(null);
    const type = ref(null);
    const response = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const error = ref(null);

    // get function
    const getItemsFromApi = (table, match) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, match);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // start from the top
      getItemsFromApi("plan/client/plans", props.enrollee.client_id);
    });

    // select
    onMounted(() => {
      // register current values
      plan.value = props.enrollee.plan_id;
      type.value = props.enrollee.type;
    });

    // select
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // select
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // handle back
    const handleBack = () => {
      context.emit("reset", null);
    };

    //handle submit
    const handleSubmit = async () => {
      // Materialize CSS handled all custome errors \\

      // start
      isSubmitting.value = true;
      error.value = null;

      // round up
      const newVal = {
        plan_id: plan.value,
        type: type.value,
      };

      // post item
      const { res, err } = await putItem(
        "operator/client/enrollee/plan/update",
        props.enrollee.id,
        newVal
      );

      if (res.value) {
        // start
        let principal;
        // next
        principal = {
          ...props.enrollee,
          ...res.value.data,
        };

        // update dependants
        principal.dependants = principal.dependants.map((x) => {
          return {
            ...x,
            plan_id: principal.plan_id,
          }; // Return a new object
        });

        console.log(principal);

        // go back
        context.emit("reset", principal);
      } else {
        error.value = err.value;
      }

      // show button back
      isSubmitting.value = false;
    };

    // all enrollees
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleBack,
      handleSubmit,
      error,
      plan,
      type,
      isReady,
      isSubmitting,
      isLoading,
      plans,
    };
  },
};
</script>
      
<style scoped>
.progress {
  margin-top: -10px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>