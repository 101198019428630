<template>

<SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

<!-- search layer -->
<div class="grey lighten-4">
  <div class="container section"></div>
</div>

<br />


  <div class="container">
    <!-- <div class="card-content">
      <router-link :to="{ name: 'provider' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >local_hospital</i
        >
      </router-link>

      <h3 class="header blue-grey-text text-darken-2">
        <router-link :to="{ name: 'provider' }"
          ><i class="small material-icons" title="Go Back"
            >subdirectory_arrow_left</i
          ></router-link
        >

        <small> Add Provider</small>
      </h3>
    </div> -->

<!-- 
    <div class="card-content">
      <div class="row">
        <div class="col s4">
          <h3 class="header">
            <router-link :to="{ name: 'provider' }" class="blue-grey-text text-darken-2">Providers</router-link>
          </h3>
        </div>
        <div class="col s8">
          <span v-for="tab in tabs" :key="tab.id" class="right" :class="{
            'tab-custom-active': tab.id == currTab.id,
            'tab-custom': tab.id != currTab.id,
          }" @click="handleActive(tab)">
            <span v-if="tab.icon"><i class="tiny material-icons cyan-text">{{ tab.icon }}</i></span>
            {{ tab.title }}
            <span v-if="tab.id == currTab.id"><i
                class="tiny material-icons blue-grey-text text-darken-2">check_circle</i></span>
          </span>

        </div>
      </div>

    </div> -->

    <LoadingDiv v-if="!isReady" />

    <div class="row" v-else>
      <div class="col s12">
        <form @submit.prevent="handleSubmit">
          <div class="section">
            <div class="card-panel">
              <div class="input-field">
                <input
                  id="name"
                  type="text"
                  v-model="name"
                  class="validate"
                  required
                />
                <label for="name">Provider Name</label>
              </div>
            </div>

            <div class="card-panel">
              <div class="input-field">
                <input
                  id="md"
                  type="text"
                  v-model="md"
                  class="validate"
                  required
                />
                <label for="md">MD's Name</label>
              </div>
            </div>

            <div class="card-panel">
              <div class="input-field">
                <input
                  id="address"
                  type="text"
                  v-model="address"
                  class="validate"
                  required
                />
                <label for="address">Provider Address</label>
              </div>
            </div>

            <div class="card-panel">
              <div class="input-field">
                <input
                  id="phone"
                  type="text"
                  v-model="phone"
                  class="validate"
                  required
                />
                <label for="phone">Provider Phone</label>
              </div>
            </div>

            <div class="card-panel">
              <div class="input-field">
                <input
                  id="email"
                  type="email"
                  v-model="email"
                  class="validate"
                  required
                />
                <label for="email">Provider Email</label>
              </div>
            </div>

            <div class="card-panel">
              <div class="input-field">
                <select v-model="plan">
                  <option value="" disabled selected>Select HMO Plan</option>
                  <option v-for="plan in plans" :key="plan.id" :value="plan.id">
                    {{ plan.name }}
                  </option>
                </select>
                <label>Provider Banding</label>
              </div>
            </div>

            <div class="card-panel">
              <div class="file-field input-field">
                <div class="btn grey darken-1">
                  <span>Add Custom Tariff</span>
                  <input type="file" @change="handleFile" />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" v-model="inputFile" type="text" />
                  <p class="red-text" v-if="fileError">{{ fileError }}</p>
                </div>
              </div>
            </div>

            <p v-if="uploadError"><br />{{ uploadError }}</p>

            <p>
              <br /><br />
              <button
                class="waves-effect waves-light btn-large"
                :class="{
                  disabled:
                    !name ||
                    !md ||
                    !address ||
                    !phone ||
                    !email ||
                    !plan ||
                    isSubmitting,
                }"
              >
                <i class="material-icons right" v-if="!isSubmitting"
                  >check_circle</i
                >{{
                  isSubmitting
                    ? `Submitting (${uploadProgress})`
                    : "Add Provider"
                }}
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onMounted, onUpdated, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import getItems from "@/composables/getItems";
import postItem from "@/composables/postItem";
import SubHeading from "@/components/HeaderSubDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import M from "materialize-css";

export default {
  name: "providerAdd",
  components: { SubHeading, LoadingDiv },
  setup() {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const response = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const error = ref(null);
    const name = ref(null);
    const md = ref(null);
    const address = ref(null);
    const phone = ref(null);
    const email = ref(null);
    const plan = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // Title
    onMounted(() => {
      // page name
      document.title = "Add Provider | EasyHMO";
    });

    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Provider", title: "Home", icon: "arrow_back", url: "provider", hash: null, });
      tabs.value.push({ id: 2, name: "Provider", title: "View", icon: "visibility", url: "provider", hash: "#view", });
      tabs.value.push({ id: 3, name: "Provider", title: "Add Provider", icon: null, url: "providerAdd", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Add"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // starter
    onMounted(() => {
      // get plans
      getItemsFromApi("plan/private");
    });

    // drop down
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      uploadProgress.value = "0%";
    };

    // file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    const handleSubmit = async () => {
      // Start Uploading
      isSubmitting.value = true;

      // check file or no file creation
      if (file.value) {
        uploadError.value = null;

        // Cloud Function
        const fx = "tariffLoader";

        // Start
        let schedule = {
          plan_id: plan.value,
          type: "c", // c=customized, s=standard
        };

        // Continue
        let newProvider = {
          schedule_id: null,
          name: name.value,
          md: md.value,
          email: email.value,
          phone: phone.value,
          address: address.value,
        };

        // Round up
        const cloudInput = {
          schedule: schedule,
          provider: null,
          newProvider: newProvider,
        };

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        // Submit Single
        uploadProgress.value = "100%"; // so its not blank
        // start
        let newProvider = {
          schedule_id: null,
          name: name.value,
          md: md.value,
          email: email.value,
          phone: phone.value,
          address: address.value,
        };

        // round up
        const newVal = {
          plan: plan.value, // server will find applicable standard schedule
          provider: newProvider,
        };

        // post item
        const { res, err } = await postItem("provider/add", newVal);

        if (res.value) {
          // final
          router.push({
            name: "providerView",
            params: { id: res.value.data.id },
          });

          // clear all
          clearForm();
        } else {
          error.value = err.value;
          isSubmitting.value = false;
        }
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        //do stuff...
        // data.succ.Success, data.succ.inserted_rows, data.succ.schedule, data.succ.provider, data.succ.Error

        router.push({
          name: "providerView",
          params: { id: data.value.succ.provider.id },
        });

        // clear all
        clearForm();
      }
      if (uploadError.value) {
        // clear all
        clearForm();
      }
    });

    // output results
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleActive,
      home,
      currTab,
      tabs,
      handleFile,
      handleSubmit,
      isReady,
      error,
      name,
      md,
      address,
      phone,
      email,
      isSubmitting,
      plans,
      plan,
      fileError,
      uploadError,
      file,
      uploadProgress,
      inputFile,
    };
  },
};
</script>

<style scoped>
.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
color: #000000;
border: 1px solid #ddd;
background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>