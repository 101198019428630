<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="section card">
      <div class="card-content center">
        <div class="row">
          <div class="col s2"></div>
          <div class="col s2"><span>Current Status</span></div>
          <div class="col s8">
            <!-- Switch -->
            <div class="switch">
              <label>
                <span :class="{ 'orange-text': !status, 'grey-text': status }"
                  >Pending</span
                >
                <input v-model="status" disabled type="checkbox" />
                <span class="lever"></span>
                <span :class="{ 'grey-text': !status, 'teal-text': status }"
                  ><b>Active</b></span
                >
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s2"></div>
          <div class="col s2"><span>{{ cat == 'tpa' ? 'TPA' : 'Policy' }} Starts</span></div>
          <div class="col s8">
            {{
              new Date(history.start).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
          </div>
          <div class="col s2"></div>
          <div class="col s2"><span>{{ cat == 'tpa' ? 'TPA' : 'Policy' }}  Ends</span></div>
          <div class="col s8">
            {{
              new Date(history.end).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
          </div>
        </div>
      </div>

      <div
        class="card-panel grey-text text-darken-1 grey lighten-5 center"
        v-for="old in history.history"
        :key="old.id"
      >
        <div class="row" v-if="old.value != history.policyDate">
          <div class="col s2"></div>
          <div class="col s2"><span>{{ cat == 'tpa' ? 'TPA' : 'Policy' }}  Start</span></div>
          <div class="col s8">
            {{
              new Date(
                `${old.value.toString().slice(0, 4)}-${old.value
                  .toString()
                  .slice(4, 6)}-01`
              ).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
              })
            }}
          </div>
          <div class="col s2"></div>
          <div class="col s2"><span>{{ cat == 'tpa' ? 'TPA' : 'Policy' }}  End</span></div>
          <div class="col s8">
            {{
              new Date(
                `${Number(old.value.toString().slice(0, 4)) + 1}-${
                  Number(old.value.toString().slice(4, 6)) - 1
                }-01`
              ).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
              })
            }}
          </div>
        </div>
      </div>

      <div class="card-content"></div>
    </div>
  </template>
</template>
    
<script>
import { computed, onMounted, ref } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "StatusClientComponent",
  components: { LoadingDiv },
  props: ["cat", "clientId"],
  setup(props) {
    const response = ref(null);
    const isReady = ref(false);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const show = ref(false);

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // starter
    onMounted(() => {
      // start from the top
      getStatus();
    });

    // prinipals
    const getStatus = () => {
      // loader
      isReady.value = false;
      // set current Url
      currUrl.value = "operator/client/status/info";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value, props.clientId);
    };

    // handle download
    const handleDownload = (val) => {
      console.log(val);
    };

    // handle show
    const handleShow = (val) => {
      show.value = val;
    };

    // enrollee ction
    const handleView = (enrollee) => {
      console.log(enrollee);
    };

    // output pages
    const status = computed(() => {
      if (!response.value) {
        return false;
      } else {
        if (response.value.status > 0) {
          return true;
        }
        return false;
      }
    });

    // output pages
    const history = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleShow,
      handleView,
      handleDownload,
      isReady,
      error,
      show,
      status,
      history,
    };
  },
};
</script>
    
  
<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>