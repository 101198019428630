<template>
  <div class="">
    <br />
    <br />
    <br />
    <br />
    <div class="container valign-wrapper">
      <div class="card-panel">
        <div class="col s12" :class="{ 'teal lighten-5': user, 'grey lighten-4': !user }">
          <!-- Error -->
          <span class="chip white-text red lighten-2" v-if="error">{{ error }}</span>

          <h2 v-if="!user">Login to <br />your account</h2>
          <h2 v-else>easyHMO <span class="indigo darken-2 white-text">&nbsp;2.0&nbsp;</span></h2>

        </div>
        <div class="col s2"><br /></div>
        <div class="col s4">

          <span class="right" v-if="!user">
            <form @submit.prevent="handleSignIn">
              <input type="text" title="username" placeholder="Username" v-model="username" />
              <input type="password" title="password" placeholder="Password" v-model="password" />
              <br /><br />
              <button type="submit" class="btn" v-if="!isSubmitting">
                Login
              </button>
              <LoadingSmallDiv class="left" v-else />
            </form>
          </span>

          <UpdateUserDiv :user="user" @updated="handleUpdate" v-else />

        </div>
      </div>
    </div>
  </div>
  <p><br /><br /><br /><br /><br /></p>
</template>

<script>
import { onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { clearCache } from "@/composables/cache/logout";
import UpdateUserDiv from "@/components/login/UpdateUserComponent.vue";
import LoadingSmallDiv from "@/components/LoadingSmallDiv.vue";

export default {
  name: "LoginHome",
  components: { UpdateUserDiv, LoadingSmallDiv },
  setup() {
    const isSubmitting = ref(false);
    const user = ref(null);
    const username = ref(null);
    const password = ref(null);
    const error = ref(null);

    // vue router
    const router = useRouter();

    // store
    const store = useStore();

    // start
    onMounted(() => {
      // page name
      document.title = "Login";
    });

    // reset login
    onMounted(() => {
      // clean up
      clearCache();
    });


    // get in
    const getIn = (userVal) => {

      // load user
      store.commit("loadUser", userVal);

      // refer loading
      router.push({
        name: "loading",
      });

    }

    // signup
    const handleSignIn = () => {
      // start
      isSubmitting.value = true;
      error.value = null;

      (async () => {
        const { res } = await getItems(`authority/hmo/signin?username=${username.value}&password=${password.value}`);
        if (res.value) {
          if (res.value?.success) {

            if (res.value.data.status === 'a') {

              // moveee
              getIn(res.value.data);

            } else if (res.value.data.status === 'p') {

              // load user for update
              user.value = res.value.data;

            } else {
              error.value = "Inactive Account!";
            }

          } else {
            error.value = "Sorry, wrong Login Credentials!";
          }

        } else {
          error.value = "Something went wrong! Check your Internet Connection and try again.";
        }
        // end end
        isSubmitting.value = false;
      })();

    };

    // send updated folk
    const handleUpdate = (userVal) => {
      getIn(userVal);
    }

    return {
      handleSignIn,
      handleUpdate,
      isSubmitting,
      user,
      username,
      password,
      error,
    };
  },
};
</script>

<style>
.spacer {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 25px;
  /* Add padding to the left */
  padding-right: 25px;
  /* Add padding to the right */
}
</style>