<template>
    <div class="section">

        <!-- Modal Structure -->
        <!-- <div id="modal1" class="modal"> -->
        <div class="modal-content">
            <h4>{{ title }}</h4>
            <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
            <a href="#!" @click.prevent="handleConfirm(false)"
                class="modal-close waves-effect waves-green btn-flat">Cancel</a>
            <a href="#!" @click.prevent="handleConfirm(true)"
                class="modal-close waves-effect waves-green btn-flat">Confirm</a>
        </div>
        <!-- </div> -->

    </div>
</template>

<script>
import { onMounted, onUpdated } from "vue";
import M from "materialize-css";

export default {
    name: "modalComponent",
    props: ["title", "message"],
    emits: ["confirm"],
    setup(props, context) {

        // alert
        onMounted(() => {
            // modal
            const elems = document.querySelectorAll(".modal");
            M.Modal.init(elems, {});
        });

        // alert
        onUpdated(() => {
            // modal
            const elems = document.querySelectorAll(".modal");
            M.Modal.init(elems, {});
        });

        // Confirmation
        const handleConfirm = (val) => {
            context.emit("confirm", val);
        }


        return {
            handleConfirm,
        };
    },
};
</script>

<style></style>