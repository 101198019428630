<template>
  <nav class="white">
    <div class="nav-wrapper">
      <div class="input-field blue-text text-darken-2">
        <input type="search" :id="searchId" class="autocomplete" :class="{ 'yellow lighten-5': emptyField }"
          v-model="searchItem" @focus="handleClear" />
        <i class="material-icons black-text" @click="handleDefault" title="cancel" v-if="emptyField">cancel</i>
        <i class="material-icons black-text" @click="handleFilter" title="filter" v-else>filter_list</i>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, watch, watchEffect } from "vue";
import M from "materialize-css";

export default {
  props: ["inputId", "data", "startAgain"],
  setup(props, context) {
    const searchItem = ref(Object.keys(props.data)[0]);
    const searchId = ref(props.inputId);
    const emptyField = ref(false);
    const reset = ref(props.startAgain);

    // clear field
    const handleClear = () => {
      searchItem.value = null;
      emptyField.value = true;
    };

    // filter
    const handleFilter = () => {
      // clear
      handleClear();
       // open
       openDropDown();
    }

    // searching feedbacks
    watchEffect(() => {
      if (searchItem.value) {
        context.emit("searching", {
          id: searchId.value,
          value: searchItem.value,
        });
      }
    });

    watchEffect(() => {
      if (props.startAgain) {
        searchItem.value = props.startAgain;
        reset.value = props.startAgain;
        emptyField.value = false; // insurance that delivers
        context.emit("clear", {
          id: searchId.value,
          value: null,
        });
      } else {
        reset.value = null;
      }
    });

    // reset field
    const handleDefault = () => {
      searchItem.value = Object.keys(props.data)[0];
      context.emit("selected", { id: searchId.value, value: searchItem.value, special: true });
      emptyField.value = false;
    };

    // autocomlete function
    const initializeAutocomplete = () => {
      const elem = document.getElementById(searchId.value);
      M.Autocomplete.init(elem, {
        data: props.data,
        onAutocomplete: function (val) {
          searchItem.value = val;
          context.emit("selected", { id: searchId.value, value: val, special: false });
          emptyField.value = false;
        },
        minLength: 0,
      });
    };

    // update autocomplete data function
    const openDropDown = () => {
      const elem = document.getElementById(searchId.value);
      const instance = M.Autocomplete.getInstance(elem);

      // if empty (fail quietly)
      if (instance) {
        instance.updateData(props.data);
        setTimeout(() => {
          instance.open();
        }, 100);

      }
    };

    // update autocomplete data function
    const updateAutocompleteData = (newVal) => {
      const elem = document.getElementById(searchId.value);
      const instance = M.Autocomplete.getInstance(elem);

      // if empty (fail quietly)
      if (instance) {
        instance.updateData(newVal);
        if (emptyField.value && reset.value === null) {
          instance.open();
        }
      }
    };

    // watch for changes
    watch(props, (newVal) => {
      updateAutocompleteData(newVal.data);
    });

    // initiate autocomplete
    onMounted(() => {
      initializeAutocomplete();
    });

    return {
      handleFilter,
      handleClear,
      handleDefault,
      emptyField,
      searchItem,
      searchId,
    };
  },
};
</script>
<style></style>