<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <div class="grey lighten-4">
    <div class="container">
      <!-- FilterAuth -->
      <FilterAuth @search="handleSearch" />
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col s12">

        <!-- Vetting Marker -->
        <div id="review"></div>

        <!-- Auths -->
        <LoadingDiv v-if="!isReady" />

        <div v-else>
          <div class="section black-text center">
            <br />
            <h5 v-if="searchTitle" v-html="searchTitle"></h5>
            <h5 v-else>
              Displaying <span class="blue-text">Everything!</span>
              <i class="material-icons">check</i>
            </h5>
          </div>

          <div class="section">
            <div class="card-panel">
              <div class="row">
                <div class="col s1">#</div>
                <div class="col s3">
                  Name <small>(Age/Sex)</small><br />ID <small>(Client)</small>
                </div>
                <div class="col s2">Provider</div>
                <div class="col s2">Item <small>(qty)</small></div>
                <div class="col s2">Auth Code</div>
                <div class="col s1">Request Date</div>
                <div class="col s1"><br /></div>
              </div>
            </div>

            <div class="progress" v-if="isLoading">
              <div class="indeterminate"></div>
            </div>

            <div class="card" v-for="(claim, index) in claims" :key="claim.id">
              <div class="card-panel" :class="{
                'blue-grey lighten-5': (index + 1) % 2 === 1,
              }">
                <div class="row">
                  <div class="col s1">
                    <p>{{ numberBadge(index) }}</p>
                  </div>
                  <div class="col s3">
                    <h6>
                      {{ claim.encounter.name }}
                      <small><span class="blue-text">{{
                        ageSex(claim.encounter.dob, claim.encounter.sex)
                          }}</span></small>
                      <br />
                      <small><b>{{ claim.encounter.enrollee_number }}</b></small>
                      <br />
                      <small>({{ claim.encounter.client_name }})</small>
                    </h6>
                  </div>
                  <div class="col s2">
                    <p>{{ claim.encounter.provider.name }}</p>
                  </div>
                  <div class="col s2">
                    <p>
                      {{ claim.item }}
                      <small>
                        <span class="black-text">(<b>{{ claim.sent_qty }}</b>)</span></small><br />
                      <span v-if="claim.sent_price" :class="{
                        'amber-text text-darken-4':
                          claim.authorization === 'z',
                        'black-text': claim.authorization !== 'z',
                      }">₦{{
                        Number(claim.sent_price)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                      </span>
                    </p>
                  </div>
                  <div class="col s2">
                    <p>
                      <span class="chip" v-if="claim.authorization === 'z'">Pending</span>
                      <span v-else>{{ claim.authorization_code }}<br />
                        <span v-if="claim.sent_price" class="chip yellow lighten-3">₦{{
                          Number(claim.sent_price)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                        </span></span>
                    </p>
                  </div>
                  <div class="col s1">
                    <p :class="{
                      'grey white-text': claim.authorization === 'z',
                      'indigo lighten-4 black-text': claim.authorization !== 'z',
                    }">
                      &nbsp;{{
                        new Date(claim.created_at).toLocaleString(undefined, {
                          // year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}&nbsp;
                    </p>
                  </div>
                  <div class="col s1">
                    <span v-if="claim.authorization === 'z'">
                      <a class="btn-floating pulse red right"><i class="material-icons" title="review"
                          @click="handleEdit(claim)">edit</i></a>
                    </span>
                    <span v-else>
                      <a class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"><i
                          class="material-icons" @click="handleEdit(claim)" title="details">chevron_right</i></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section center" v-if="!claims.length">
            <h6>No Authorization <em>found</em>...</h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import getItems from "@/composables/getItems";
import SubHeading from "@/components/HeaderSubDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import FilterAuth from "@/components/auth/FilterAuthComponent.vue";
import { getCache, addCache } from "@/composables/cache/cache";

export default {
  name: "authHome",
  components: { SubHeading, LoadingDiv, PaginationDiv, FilterAuth },
  setup() {
    const response = ref(null);
    const searchTitle = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "All Authorizations | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Pre-Auth", title: "Home", icon: null, url: "auth", hash: null, });
      tabs.value.push({ id: 2, name: "Pre-Auth", title: "Review", icon: "edit", url: "auth", hash: "#review" });
      tabs.value.push({ id: 3, name: "Pre-Auth", title: "Add Auth", icon: "add_circle", url: "authAdd", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // display function
    const ageSex = (dob, sex) => {
      let ageSex;
      let birthDateObj = new Date(dob);
      let today = new Date();
      let age = today.getFullYear() - birthDateObj.getFullYear();

      if (
        today.getMonth() < birthDateObj.getMonth() ||
        (today.getMonth() === birthDateObj.getMonth() &&
          today.getDate() < birthDateObj.getDate())
      ) {
        age--;
      }

      if (dob && sex) {
        ageSex = `(${sex.toUpperCase()}/${age})`;
      } else if (dob) {
        ageSex = `(${age}yrs)`;
      } else if (sex) {
        ageSex = `(${sex.toUpperCase()})`;
      }

      return ageSex;
    };

    // get function
    const getItemsFromApi = (table) => {

      // get item from store (cache)
      response.value = getCache(table);

      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          // add cache
          addCache(table, response.value);
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // handle search (emitted from component)
    const handleSearch = (val) => {
      // start loading
      isLoading.value = true;
      // set current Url
      currUrl.value = val.currUrl;
      // set current Hook
      currHook.value = val.currHook;
      // set search title
      searchTitle.value = val.searchTitle;
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // handle Edit
    const handleEdit = (claim) => {
      // Send to Auth View
      router.push({
        name: "authView",
        params: {
          encounterId: claim.encounter.id,
        },
      });
    };

    // output results
    const claims = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleActive,
      home,
      currTab,
      tabs,
      ageSex,
      numberBadge,
      handlePage,
      handleSearch,
      handleEdit,
      isReady,
      isLoading,
      error,
      searchTitle,
      claims,
      allPages,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -15px;
  /* Adjust the value as needed to move the progress bar upwards */
}

.custom-hr {
  width: 50%;
  /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc;
  /* Adjust the color and style as needed */
  margin: 20px 20px;
  /* Adjust margin as needed */
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}

.clickable {
  cursor: pointer;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}
</style>