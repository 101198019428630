<template>
  <div class="section">

    <div class="card grey lighten-5">
      <!-- Search -->
      <div class="card-panel" :class="{ 'red lighten-5': error, 'grey lighten-4': !error }">
        <h5>Enter Enrollee ID</h5>
        <br />
        <nav class="white">
          <div class="nav-wrapper">
            <form @submit.prevent="handleSearch">
              <div class="input-field blue-text text-darken-2">
                <input type="search" v-model="enrolleeId" placeholder="Enrollee ID" @focus="handleClear" />
                <label class="label-icon" for="search"><i class="material-icons black-text">search</i></label>
                <i class="material-icons" @click="handleSearch">send</i>
              </div>
            </form>
          </div>
        </nav>
      </div>

      <div class="progress" v-if="isLoading">
        <div class="indeterminate"></div>
      </div>

      <!-- Confirmation for more than multiple enrollees -->
      <div class="card yellow lighten-5 grey-text text-darken-2" v-for="enrollee in enrolleeGroup" :key="enrollee.id">
        <div class="card-content">
          <div class="row">
            <div class="col s10">
              <span class="new badge left grey darken-2" data-badge-caption="Scheme">{{
                enrollee?.scheme.name.toUpperCase()
                }}</span>
              <br />
              <h5>{{ capWords(enrollee?.name) }} <small>({{ enrollee?.enrollee_number }})</small></h5>
            </div>
            <div class="col s2">
              <a class="btn-floating btn-large waves-effect waves-light light-blue lighten-2 pulse right"><i
                  class="material-icons" @click.prevent="handleStart(enrollee)" title="details">chevron_right</i></a>
            </div>
          </div>
        </div>
      </div>

      <br />
    </div>

  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { capWordsFx } from "@/composables/fxLibrary/coreFx";
import getItemsOfItems from "@/composables/getItemsOfItems";

export default {
  name: "StartAuthComponent",
  emits: ["next"],
  setup(props, context) {
    const response = ref(null);
    const enrolleeGroup = ref(null);
    const enrolleeId = ref(null);
    const serverDate = ref(null);
    const error = ref(false);
    const isLoading = ref(false);

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // get function
    const getSearchItemsFromApi = (table, match) => {
      // loading starts
      isLoading.value = true;
      // clear error
      error.value = null;

      (async () => {
        const { res } = await getItemsOfItems(table, match);

        if (res.value && res.value.status) {
            // set server date
            serverDate.value = res.value.server;
            // display options
          if (res.value.data.length === 1) {
            response.value = res.value.data[0];
          } else {
            enrolleeGroup.value = res.value.data;
          }
        } else {
          error.value = `Enrollee not on current List!`;
          response.value = null; // clear standing response
        }

        // page is ready, loading is complete
        isLoading.value = false;
      })();
    };

    // search
    const handleSearch = () => {
      // loading starts
      isLoading.value = true;
      // val
      let val = `q=${enrolleeId.value.trim()}`;
      // search: 0 = provider_id
      getSearchItemsFromApi("operator/enrollee/encounter/checker", `0?${val}`);
    };

    // start
    const handleStart = (val) => {
      // set
      response.value = val;
    }

    // clear
    const handleClear = () => {
      enrolleeGroup.value = [];
      enrolleeId.value = null;
      response.value = null;
      error.value = false;
    };

    // next
    watchEffect(() => {
      if (response.value) {
        context.emit("next", {
          value: response.value,
          serverDate: serverDate.value,
        });
      }
    });

    return {
      capWords,
      handleStart,
      handleSearch,
      handleClear,
      error,
      isLoading,
      enrolleeGroup,
      enrolleeId,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -15px;
}
</style>