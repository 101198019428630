<template>
  <div class="container">

    <div class="card-content">
      <div class="row">
        <div class="col s4">
          <h3 class="header">
            <router-link :to="{ name: 'public' }" class="blue-grey-text text-darken-2">Schemes</router-link>
          </h3>
          <h5 class="header blue-grey-text text-darken-2 ups bar-over-text">
            <small> Add List</small>
          </h5>
        </div>
        <div class="col s8">
          <span v-for="tab in tabs" :key="tab.id" class="right" :class="{
            'tab-custom-active': tab.id == currTab.id,
            'tab-custom': tab.id != currTab.id,
          }" @click="handleActive(tab)">
            <span v-if="tab.icon"><i class="tiny material-icons cyan-text">{{ tab.icon }}</i></span>
            {{ tab.title }}
            <span v-if="tab.id == currTab.id"><i
                class="tiny material-icons blue-grey-text text-darken-2">check_circle</i></span>
          </span>

        </div>
      </div>

    </div>

    <div class="row">
      <div class="col s12">
        <LoadingDiv v-if="!isReady" />

        <div class="section" v-else>
          <form @submit.prevent="handleSubmit">
            <div class="card-panel">
              <div class="file-field input-field">
                <div class="btn black">
                  <span>New List</span>
                  <input type="file" @change="handleFile" />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" v-model="inputFile" type="text" />
                  <p class="red-text" v-if="fileError">{{ fileError }}</p>
                </div>
              </div>
            </div>

            <div class="card-panel" v-if="file">
              <div class="input-field">
                <select v-model="scheme">
                  <option value="" disabled selected></option>
                  <option :value="client?.scheme.id">
                    {{ client?.scheme.name }}
                  </option>
                </select>
                <label>Scheme of List</label>
              </div>
            </div>

            <div class="card-panel" v-if="file && scheme">
              <div class="input-field" @click="(periodStart = null), (periodEnd = null)">
                <select v-model="periodStart">
                  <option value="" disabled selected></option>
                  <option :value="nextMonthDate">{{ nextMonth }}</option>
                  <option :value="thisMonthDate">
                    {{ thisMonth }} (Current month)
                  </option>
                  <option value="">-</option>
                  <option :value="pastMonth.date" v-for="pastMonth in pastInactiveMonths" :key="pastMonth.date">
                    {{ pastMonth.month }}
                  </option>
                </select>
                <label>Period of List</label>
              </div>
            </div>

            <div class="card-panel" v-if="file && scheme && periodStart">
              <div class="input-field">
                <select v-model="periodEnd">
                  <option value="" disabled selected></option>
                  <option :value="endMonth.date" v-for="endMonth in endMonths" :key="endMonth.date">
                    {{ endMonth.month }}
                  </option>
                </select>
                <label>Select End Month</label>
              </div>
            </div>

            <p v-if="uploadError"><br />{{ uploadError }}</p>

            <p>
              <br /><br />
              <button class="waves-effect waves-light btn-large" :class="{
                disabled:
                  !scheme || !periodStart || !periodEnd || isSubmitting,
              }">
                <i class="material-icons right">upload</i>{{ isSubmitting ? `Uploading (${uploadProgress})` : "Upload"
                }}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUpdated, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getItem from "@/composables/getItem";
import useStorage from "@/composables/useStorage";
import {
  activeMonths,
  pastMonths,
  generateEndMonths,
} from "@/composables/fxLibrary/months";
import LoadingDiv from "@/components/LoadingDiv.vue";
import M from "materialize-css";

export default {
  name: "publicAdd",
  components: { LoadingDiv },
  props: ["id"],
  setup(props) {
    const cat = ref("schemes");
    const isReady = ref(false);
    const client = ref(null);
    const error = ref(null);
    const isSubmitting = ref(false);
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const scheme = ref(null);
    const thisMonth = ref(null);
    const nextMonth = ref(null);
    const thisMonthDate = ref(null);
    const nextMonthDate = ref(null);
    const pastInactiveMonths = ref([]);
    const endMonths = ref([]);
    const periodStart = ref(null);
    const periodEnd = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    const currTab = ref(null);
    const tabs = ref([]);

    // router
    const router = useRouter();

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // title
    onMounted(() => {
      // page name
      document.title = "Bypass | EasyHMO";
    });

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, title: "Home", icon: "arrow_back", url: "private", hash: null, });
      tabs.value.push({ id: 2, title: "Add List", icon: null, url: null, hash: null });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Add"));
    });

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // get client
    const getClient = () => {
      (async () => {
        const { res, err } = await getItem("client", props.id);

        if (res.value) {
          if (res.value.data.cat != cat.value) {
            // refer back home
            router.push({
              name: "home",
            });
          } else {
            // assign
            client.value = res.value.data;
          }
        } else {
          error.value = err.value;
          // back to public
          router.push({
            name: "public",
          });
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // select
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // Get client
    onMounted(() => {
      // get client details
      getClient();
    });

    // month initialization
    onMounted(() => {
      // active
      const { fx: active } = activeMonths();
      // active months
      thisMonth.value = active.value.thisMonth;
      thisMonthDate.value = active.value.thisMonthDate;
      nextMonth.value = active.value.nextMonth;
      nextMonthDate.value = active.value.nextMonthDate;

      // past
      const { fx: past } = pastMonths();
      // past month array
      pastInactiveMonths.value = past.value;
    });

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      uploadProgress.value = "0%";
      scheme.value = null;
      periodStart.value = null;
      periodEnd.value = null;
    };

    // file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
      scheme.value = null;
      periodStart.value = null;
      periodEnd.value = null;
    };

    // watch periodStart pick
    watchEffect(() => {
      if (periodStart.value) {
        // finish
        const { fx: endings } = generateEndMonths(periodStart.value);
        // end month array
        endMonths.value = endings.value;
      } else {
        endMonths.value = [];
      }
    });

    // handle submit
    const handleSubmit = async () => {
      if (
        file.value &&
        scheme.value &&
        (periodStart.value || periodEnd.value)
      ) {
        // Start Uploading
        isSubmitting.value = true;
        uploadError.value = null;

        // Cloud Function
        const fx = "enrolleeLoader";

        // Round up
        const cloudInput = {
          scheme: scheme.value,
          start: periodStart.value,
          end: periodEnd.value,
        };

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        // error
        clearForm();
      }
    };

    // watch tariff upload
    watchEffect(() => {
      if (completed.value) {
        // clear
        clearForm();
        // data.succ.Success, data.value.succ.inserted_rows, data.succ.Error
        router.push({
          name: "public",
          rows: data.value.succ.inserted_rows,
        });
      }
      if (uploadError.value) {
        // clear
        clearForm();
      }
    });

    return {
      handleActive,
      currTab,
      tabs,
      cat,
      handleFile,
      isReady,
      isSubmitting,
      handleSubmit,
      error,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      scheme,
      thisMonth,
      thisMonthDate,
      nextMonth,
      nextMonthDate,
      pastInactiveMonths,
      endMonths,
      periodStart,
      periodEnd,
      client,
    };
  },
};
</script>

<style></style>