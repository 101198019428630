<template>
    <form @submit.prevent="handleUpdate">

        <div class="card-content section">
            <br />
            <div class="row">
                <div class="col s10">
                    <div class="file-field input-field">
                        <div class="btn grey">
                            <span>Photo</span>
                            <input type="file" @change="handleFile" />
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path" v-model="inputFile" type="text" />
                            <p v-if="fileError">{{ fileError }}</p>
                        </div>
                    </div>
                </div>
                <div class="col s2">
                    <LoadingSmallDiv v-if="isLoading" />
                    <span v-else>
                        <img :src="user?.profile_img" width="64" class="circle responsive-img" v-if="user?.profile_img" />
                        <span v-else>
                        <img :src="img" width="64" class="circle responsive-img" v-if="img" />
                        <img :src="`https://ui-avatars.com/api/?name=${user.name}&size=128`" width="64" class="circle responsive-img"
                            v-else />
                        </span>   
                    </span>
                </div>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="name" class="validate" required />
                <label class="active teal-text" for="name">Fullname</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="slug" class="validate" required />
                <label class="active teal-text" for="slug">Slug</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="staff_id" class="validate" />
                <label class="active teal-text" for="staff_id">Staff ID</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="department" class="validate" required />
                <label class="active teal-text" for="department">Department</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="designation" class="validate" required />
                <label class="active teal-text" for="designation">Designation</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="official_line" class="validate" />
                <label class="active teal-text" for="official_line">Official Line</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="email" v-model="email" class="validate" />
                <label class="active teal-text" for="email">Email</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="password" class="validate" required />
                <label class="active red-text" for="password">Change Password</label>
            </div>
        </div>

        <div class="card-panel">
            <div class="input-field">
                <input type="text" v-model="password_confirm" class="validate" required />
                <label class="active black-text" for="password_confirm">Confirm Password</label>
            </div>
        </div>


            <p>
                <span class="chip red white-text" v-if="error">{{ error }}</span>
                <br /><br />

                <button class="waves-effect waves-light btn-large" :class="{
                    disabled: isSubmitting,
                }">
                    <i class="material-icons right">check_circle</i>{{ isSubmitting ? "Updating" : "Update" }}
                </button>

            </p>

    </form>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import putItem from "@/composables/putItem";
import useStorage from "@/composables/useStorage";
import LoadingSmallDiv from "@/components/LoadingSmallDiv.vue";


export default {
    name: "UpdateUserComponent",
    components: { LoadingSmallDiv },
    props: ["user"],
    emits: ["updated"],
    setup(props, context) {
        const file = ref(null);
        const inputFile = ref(null);
        const fileError = ref(null);
        const name = ref(null);
        const slug = ref(null);
        const designation = ref(null);
        const department = ref(null);
        const staff_id = ref(null);
        const official_line = ref(null);
        const password = ref(null);
        const password_confirm = ref(null);
        const email = ref(null);
        const isSubmitting = ref(false);
        const isLoading = ref(false);
        const error = ref(null);
        const img = ref(null);
        const types = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

        // storage
        const { uploadFile, uploadProgress, uploadError, data, completed } =
            useStorage();

        // select
        onMounted(() => {
            // set other variables
            name.value = props.user.name;
            slug.value = props.user.slug;
            email.value = props.user.email;
            staff_id.value = props.user.staff_id;
            designation.value = props.user.designation;
            department.value = props.user.department;
            official_line.value = props.user.official_line;
        });

        // clear
        const clearForm = () => {
            file.value = null; //clear file value
            inputFile.value = null; // Clear the file input value
            isSubmitting.value = false;
            isLoading.value = false;
            uploadProgress.value = "0%";
        };

        // handle file
        const handleFile = (e) => {
            const selected = e.target.files[0];
            if (selected && types.includes(selected.type)) {
                file.value = selected;
                inputFile.value = file.value.name;
                fileError.value = null;
            } else {
                file.value = null;
                inputFile.value = null;
                fileError.value = "Please select an Image (.JPG, .PNG, .GIF)";
            }
        };

        //hand
        watchEffect(() => {
            if (file.value) {
                // start ploading
                isLoading.value = true;
                uploadError.value = null;

                // upload image
                uploadFile(file.value);
            }
        });

        //handle submit
        const handleUpdate = async () => {
            // Materialize CSS handled all custome errors \\

            // clear error
            error.value = null;

            // cont
            if (password.value === password_confirm.value) {

               // start
            isSubmitting.value = true;
            error.value = false;
            uploadProgress.value = false;

            // variable
            const newVal = {
                name: name.value,
                profile_img: img.value ? img.value : props.user?.profile_img,
                email: email.value,
                slug: slug.value,
                staff_id: staff_id.value,
                designation: designation.value,
                department: department.value,
                official_line: official_line.value,
                password: password.value,
                status: "a", // make active
            };

            // post item
            const { res } = await putItem(
                "authority/hmo/update",
                props.user.id,
                newVal
            );

            if (res.value) {
                // go back
                context.emit("updated", res.value.data);
            } else {
                error.value = "Something went wrong!";
            }

            // end here
            // clear
            clearForm();

            } else {

             error.value = "Password and Confirmation doesn't match!"   

            }
            
        };

        // watch tariff upload
        watchEffect(() => {
            if (completed.value) {
                // set image
                img.value = data.value;
                // clear
                clearForm();
                // data.succ.Success, data.succ.inserted_rows, data.succ.Error
                // console.log(data); // might not be necessary
            }
            if (uploadError.value) {
                // clear
                clearForm();
            }
        });


        return {
            handleFile,
            handleUpdate,
            isSubmitting,
            isLoading,
            uploadProgress,
            fileError,
            uploadError,
            error,
            img,
            file,
            inputFile,
            name,
            slug,
            designation,
            department,
            staff_id,
            official_line,
            email,
            password,
            password_confirm,
        };
    },
};
</script>

<style></style>