<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <!-- search layer -->
  <div class="grey lighten-4">
    <div class="container section"></div>
  </div>

  <div class="container">
    <br />
    <div class="center">
      <span v-for="tab in tabsState" :key="tab.id" :class="{
        'tab-custom-active': tab.id === currState,
        'tab-custom': tab.id !== currState,
      }" @click="handleActiveState(tab.id)">
        {{ tab.title }}
      </span>
      <br /><br /><br />
    </div>

    <!-- Clients -->
    <ClientsInsight v-if="currState == 1" />
    <!-- Schemes -->
    <SchemesInsight v-if="currState == 2" />
    <!-- Report -->
    <ReportInsight v-if="currState == 3" />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import SubHeading from "@/components/HeaderSubDiv.vue";
import ClientsInsight from "@/components/insight/ClientsInsightComponent.vue";
import SchemesInsight from "@/components/insight/SchemesInsightComponent.vue";
import ReportInsight from "@/components/insight/ReportInsightComponent.vue";

export default {
  name: "reportHome",
  components: { SubHeading, ClientsInsight, SchemesInsight, ReportInsight },
  setup() {
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);
    const tabsState = ref([]);
    const currState = ref(null);

    // start
    onMounted(() => {
      // page name
      document.title = "Insights | EasyHMO";
    });

    // handle back
    const handleBack = () => {
      // restart menu
      currTab.value = 1;
    };

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Insights", title: "Home", icon: null, url: "report", hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Home"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // start
    onMounted(() => {
      // load tab
      tabsState.value.push({ id: 1, title: "Clients" });
      tabsState.value.push({ id: 2, title: "Schemes" });
      tabsState.value.push({ id: 3, title: "Quarterly Report" });
      // set default
      currState.value = 1;
    });

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
    };

    // handle curr status
    const handleActiveState = (tab) => {
      // update show and components
      currState.value = tab;
    };

    return {
      home,
      handleActive,
      handleActiveState,
      handleBack,
      currTab,
      currState,
      tabs,
      tabsState,
    };
  },
};
</script>

<style scoped>
.spacer {
  padding: 2px;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
  color: #000000;
  border: 1px solid #ddd;
  background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>