<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="grey-bg-custom">
      <div class="container">
        <div class="row">
          <div class="col s12">

            <div class="section">
              <h5 class="header">Added Tariffs</h5>
            </div>

            <!-- Completed -->
            <div class="section">
              <div class="card-panel" :class="{ 'red lighten-5': error && editPlan === plan }" v-for="(plan, id) in plans"
                :key="plan.id">
                <!-- Edit -->
                <template v-if="editPlan === plan">
                  <form @submit.prevent="handleUpload(plan)">
                    <div class="row">
                      <div class="col s1">
                        <span class="chip red darken-2 white-text">{{
                          id + 1
                        }}</span>
                      </div>
                      <div class="col s9">
                        <h5 class="black-text">
                          {{ plan.name }}
                          <span class="teal-text"><small>{{
                            plan.scheme.type === "p"
                              ? "Private Scheme"
                              : "Social Scheme"
                          }}</small></span>
                        </h5>
                      </div>
                      <div class="col s2">
                        <a class="btn-floating btn-medium waves-effect waves-light orange right"><i
                            class="material-icons" title="close" @click="handleReset()">close</i></a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s1"><br /></div>
                      <div class="col s9">
                        <div class="file-field input-field">
                          <div class="btn black">
                            <span>Tariff</span>
                            <input type="file" @change="handleFile" />
                          </div>
                          <div class="file-path-wrapper">
                            <input class="file-path" v-model="inputFile" type="text" />
                            <p class="red-text" v-if="fileError">
                              {{ fileError }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col 2">
                        <button class="btn-floating btn-medium waves-effect waves-light black right">
                          <i class="material-icons" title="Upload tariff">upload</i>
                        </button>
                      </div>
                    </div>
                  </form>
                </template>
                <!-- View -->
                <div class="row" v-else>
                  <div class="col s1">
                    <span class="chip grey darken-2 white-text">{{
                      id + 1
                    }}</span>
                  </div>
                  <div class="col s9">
                    <h5>
                      {{ plan.name }}
                    </h5>
                    <h6>
                      {{
                        plan.scheme.type === "p"
                          ? "Private Scheme"
                          : "Social Scheme"
                      }}
                    </h6>
                    <p>
                      <span class="chip teal lighten-4" v-if="plan.schedules.length">Active Standard Schedule</span>
                      <span class="chip grey lighten-3" v-else>No Standard Schedule</span>
                    </p>
                  </div>
                  <div class="col s2">
                    <a class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"><i
                        class="material-icons" title="edit" @click="handleEdit(plan)">edit</i></a>
                  </div>
                </div>
              </div>

              <!-- Empty -->
              <div v-if="!plans.length">
                <span class="chip">No Tariff(s) <em>added</em></span>
              </div>
            </div>

            <!-- Submit -->
            <div class="section center">
              <p>
                <br /><br />
                <button class="waves-effect waves-light btn-large" @click="handleNext">
                  <i class="material-icons right">call_made</i>Next
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </template>
</template>

<script>
import { ref, computed, onMounted, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import useStorage from "@/composables/useStorage";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "TariffSetupComponent",
  components: { LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    const response = ref(null);
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isReady = ref(false);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(null);
    const next = ref(false);
    const editPlan = ref(null);
    const success = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // start
    onMounted(() => {
      // get items
      loadPlans();
    });

    // handle plans loader
    const loadPlans = () => {
      // loasder on
      isReady.value = false;
      // start
      (async () => {
        const { res, err } = await getItems("plan/setup");

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    }


    // handle add
    const handleReset = () => {
      // clear files
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      uploadProgress.value = "0%";
      uploadError.value = null;
      data.value = null;
      completed.value = null;
      // next
      next.value = false;
      // clear error
      error.value = false;
      // clear loading
      isLoading.value = false;
      // clear submitting
      isSubmitting.value = false;
      // clear edit plan
      editPlan.value = null;
    };


    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
        error.value = false;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
        error.value = false;
      }
    };

    // upload
    const handleUpload = (val) => {
      if (file.value) {
        // Start Uploading
        isReady.value = false;
        // others
        isSubmitting.value = true;
        isLoading.value = true;
        uploadError.value = null;

        // Cloud Function
        const fx = "tariffLoader";

        // Start
        let schedule = {
          plan_id: val.id,
          type: "s", // c=customized, s=standard
        };

        // Round up
        const cloudInput = { schedule: schedule, provider: null, newProvider: null };

        // upload
        uploadFile(file.value, fx, cloudInput);

        // rotator will be cleared below 
        // (watchEffect)
      } else {
        // error
        error.value = true;
        // clear rotator
        isReady.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // reset
        handleReset();
        // update plan
        loadPlans(); // also clears rotator
      }
      if (uploadError.value) {
        // error
        error.value = true;
        // clear rotator
        isReady.value = true;
      }
    });

    // handle edit
    const handleEdit = (plan) => {
      // clear
      handleReset();
      // set
      editPlan.value = plan;
    };


    // handle submit
    const handleNext = () => {
      // next
      // emit new parameters
      context.emit("next", {
        step: 4,
        isComplete: true,
      });
    };


    // output results (plans)
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });


    return {
      isReady,
      isLoading,
      isSubmitting,
      editPlan,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      error,
      handleEdit,
      handleReset,
      handleNext,
      handleUpload,
      handleFile,
      success,
      next,
      plans,
    };
  },
};
</script>

<style>
.grey-bg-custom {
  background-color: #f2f2f2;
}

.hover-text {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}
</style>