<template>
    <div class="card">

        <div class="card-content grey lighten-5">
            <span class="card-title black-text">
                <span class="row">
                    {{ capWords(cluster.provider.name) }}
                    <br />
                    <span class="chip"><b>{{ cluster.scheme.name.toUpperCase() }}</b></span>
                    <span class="chip"><b>{{ cluster.month }} {{ cluster.year }} Claims</b></span>
                    <span class="chip teal lighten-2"><b>Submitted</b></span>
                    <span class="secondary-content clickable" title="close"><i class="material-icons white-text orange"
                            @click="handleRestart">clear</i></span>
                </span>
            </span>
        </div>


        <!-- main  -->
        <div class="cardjj">
            <div class="card-content white">
                <table class="highlight responsive-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name<br /><small>(Enrollee ID)</small></th>
                            <th>Diagnosis</th>
                            <th>Sent Amount</th>
                            <th>Encounter</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        <!-- Encounters -->
                        <tr v-for="(encounter, index) in cluster?.encounters" :key="encounter.id">
                            <td>{{ index + 1 }}</td>
                            <td>
                                {{ encounter.name }}<br />
                                ({{ encounter.enrollee_number }})
                            </td>
                            <td>{{ encounter.diagnosis }}</td>
                            <td>
                                <b>₦{{ totalSent(encounter) }}</b>
                            </td>
                            <td>
                                {{
                                    new Date(encounter.encounter_date).toLocaleString(
                                        undefined,
                                        {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        }
                                    )
                                }}
                            </td>
                            <td>
                                <i class="material-icons grey-text pointable" title="submitted">check_circle</i>
                            </td>
                        </tr>

                        <!-- Total -->
                        <tr v-if="cluster?.encounters.length">
                            <td colspan="2"></td>
                            <td>Submitted Total:</td>
                            <td class="teal-text text-darken-2"><b>₦{{ grandTotalSent(cluster?.encounters) }}</b>
                            </td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>

                        <!-- No encounters yet -->
                        <tr v-else>
                            <td colspan="6" class="center">No Claims Added Yet!</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
import {
    totalSentFx,
    grossTotalSentFx,
} from "@/composables/calFunction/cal";
import { capWordsFx, } from "@/composables/fxLibrary/coreFx";

export default {
    name: "SummaryClaimComponent",
    props: ["cluster"],
    emits: ["restart"],
    setup(props, context) {

        //from functions
        const capWords = (val) => {
            return capWordsFx(val);
        };

        // total calculations
        const totalSent = (encounter) => {
            return totalSentFx(encounter);
        }

        // total calculations
        const grandTotalSent = (encounter) => {
            return grossTotalSentFx(encounter);
        }

        // restart (clear)
        const handleRestart = () => {
            context.emit("restart", true);
        }


        return {
            handleRestart,
            capWords,
            grandTotalSent,
            totalSent,
        };
    },
};
</script>

<style scoped>
.custom-hr {
    width: 50%;
    border: none;
    border-top: 2px solid #ccc;
    margin: 20px 20px;
}

.progress {
    margin-top: -16px;
}

.styled-text {
    background-color: #fafad2;
    padding: 2px;
}

.capsule {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #f0f0f0;
}

.clickable {
    cursor: pointer;
}

.pointable {
    cursor: default;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.pointable:hover {
    opacity: 0.8;
}
</style>