<template>

    <LoadingDiv v-if="!isReady" />

    <template v-else>
        <div class="section" v-for="cluster in clusters" :key="cluster.id">
            <div class="card">
                <span class="new badge teal darken-2 left" data-badge-caption="Scheme">{{
                    cluster.scheme.name.toUpperCase()
                }}</span>

                <div class="card-content grey lighten-4">
                    <div class="row">
                        <div class="col s8">
                            <p>{{ displayMonthYear(cluster.month_year) }}</p>
                            <h5>{{ capWords(cluster.provider.name) }}</h5>
                            <h6>
                                <i class="tiny material-icons">save</i> {{ cluster.encounters.length }} Claims
                            </h6>
                        </div>
                        <div class="col s4">
                            <img :src="`https://ui-avatars.com/api/?name=${cluster?.slug}&background=random&size=128`"
                                width="128" class="circle responsive-img right" :title="cluster?.slug" />
                        </div>
                    </div>
                </div>

                <div class="row teal lighten-5">
                    <div class="col s8">
                        <span class="chip">Last <em>Saved</em> on
                            {{
                                new Date(cluster.updated_at).toLocaleString(undefined, {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                })
                            }}
                        </span>
                    </div>
                    <div class="col s4">
                        <a class="waves-effect waves-light btn-large right" :class="{ 'disabled': savedCluster }"
                            @click.prevent="handleReStart(cluster)"><i class="material-icons disable right">send</i>
                            Continue</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="section center" v-if="!clusters?.length">
            <h6>No <em>(Saved)</em> Claims <em>found</em>...</h6>
        </div>
    </template>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import getItems from "@/composables/getItems";
import { capWordsFx, } from "@/composables/fxLibrary/coreFx";
import { getClusterMonthYear, getFullMonthName } from "@/composables/fxLibrary/months";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
    name: "savedClaimHMO",
    components: { LoadingDiv },
    emits: ["restart"],
    setup(props, context) {
        const isReady = ref(false);
        const isLoading = ref(false);
        const response = ref(null);
        const error = ref(null);

        // store
        const store = useStore();

        // get function
        const getItemsFromApi = (table) => {

            (async () => {
                const { res, err } = await getItems(table);

                if (res.value) {
                    response.value = res.value.data;
                } else {
                    error.value = err.value;
                }

                isReady.value = true;
                isLoading.value = false;
            })();
        };

        // start
        onMounted(() => {
            // params u=user_id, s=scope, c=cluster_id
            let queryVal = `u=${store.state.user.id}&s=hmo&c=`;
            // Saved by User
            getItemsFromApi(`cluster/hmo/saved?${queryVal}`);
        });

        //from functions
        const capWords = (val) => {
            return capWordsFx(val);
        };

        // display mont year
        const displayMonthYear = (val) => {
            return getClusterMonthYear(val);
        }

        // restart
        const handleReStart = async (val) => {

            // loading
            isReady.value = false;

            // params u=user_id, s=scope, c=cluster_id
            let queryVal = `u=${store.state.user.id}&s=""&c=${val.id}`;

            // Saved by cluster
            const { res, err } = await getItems(`cluster/hmo/saved?${queryVal}`);

            if (res.value) {
                // reload cluster
                let restartcluster = { ...res.value.data[0] }; // ...val

                // convert month_year
                let monthYear = restartcluster.month_year.split("_");
                // month
                restartcluster.month = { search: Number(monthYear[0]), name: getFullMonthName(monthYear[0] - 1) };
                // year
                restartcluster.year = { search: Number(monthYear[1]), name: monthYear[1] };

                // uptimize claims
                restartcluster.encounters.forEach((encounter) => {
                    encounter.claims.forEach((claim) => {
                        claim.claim = claim.item
                        claim.qty = claim.sent_qty
                        claim.amt = claim.sent_price
                        claim.standard = claim.standard_price
                    })
                })

                // save payload
                store.commit("loadCluster", restartcluster);

                // restart
                context.emit("restart", true);

            } else {
                error.value = err.value;
            }

            // end 
            isReady.value = true;
        }

        // output results
        const clusters = computed(() => {
            if (!response.value) {
                return [];
            } else {
                return response.value;
            }
        });


        return {
            handleReStart,
            user: store.state.user,
            savedCluster: store.state.cluster,
            displayMonthYear,
            capWords,
            isReady,
            isLoading,
            response,
            error,
            clusters,
        };
    },
};
</script>

<style scoped>
.custom-hr {
    width: 50%;
    border: none;
    border-top: 2px solid #ccc;
    margin: 20px 20px;
}
</style>