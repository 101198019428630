<template>
  <input
    type="text"
    :id="searchId"
    class="autocomplete"
    v-model="searchItem"
    @focus="handleClear"
    :disabled="disabled"
  />
  <label class="active" :for="searchId">Item</label>
</template>
  
<script>
import { ref, onMounted, watch, watchEffect } from "vue";
import M from "materialize-css";

export default {
  props: ["inputId", "data", "startAgain", "startEdit", "disabled"],
  emits: ["selected", "searching", "clear"],
  setup(props, context) {
    const searchItem = ref(null);
    const searchId = ref(props.inputId);

    // clear field
    const handleClear = () => {
      searchItem.value = null;
      context.emit("searching", {
        item: null,
      });
    };

    // searching feedbacks
    watchEffect(() => {
      if (searchItem.value) {
        context.emit("searching", {
          item: searchItem.value,
        });
      }
    });

    // clear search input
    watchEffect(() => {
      if (props.startAgain) {
        searchItem.value = null;
        context.emit("clear", {
          item: false, // prevent an infinity loop
        });
      }
    });

    // edit
    watchEffect(() => {
      if (props.startEdit) {
        searchItem.value = props.startEdit;
        context.emit("clear", {
          item: false, // prevent an infinity loop
        });
      }
    });

    // autocomlete function
    const initializeAutocomplete = () => {
      const elem = document.getElementById(searchId.value);
      M.Autocomplete.init(elem, {
        data: props.data,
        onAutocomplete: function (val) {
          searchItem.value = val;
          context.emit("selected", { item: val });
        },
        minLength: 3,
      });
    };

    // update autocomplete data function
    const updateAutocompleteData = (newVal) => {
      // update action
      const elem = document.getElementById(searchId.value);
      const instance = M.Autocomplete.getInstance(elem);

      // if empty (fail quietly)
      if (instance) {
        instance.updateData(newVal);
      }
    };

    // watch for changes
    watch(props, (newVal) => {
      updateAutocompleteData(newVal.data);
    });

    // initiate autocomplete
    onMounted(() => {
      initializeAutocomplete();
    });

    return {
      handleClear,
      searchItem,
      searchId,
    };
  },
};
</script>
<style>
</style>
  