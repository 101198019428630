<template>
  <div class="section">
    <div class="card">
      <div class="card-content" v-if="enrollee">
        <!-- Main -->
        <div class="row">
          <div class="col s8">
            <p>{{ capWords(enrollee.client_name) }}</p>
            <h5>
              {{ capWords(enrollee.name) }}
              <sup>{{ showAgeSex(enrollee.dob, enrollee.sex) }}</sup>
            </h5>
            <p>({{ enrollee.enrollee_number }})</p>
            <span v-if="!forDelete">
              <span v-if="enrollee && 'encounter_date' in enrollee">
                <p>
                  <br />
                  <span class="chip blue-grey lighten-4">
                    {{
                      new Date(enrollee.encounter_date).toLocaleString(undefined, {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    }}
                  </span>

                  <span class="chip teal lighten-4" v-if="enrollee && 'encounter_date_end' in enrollee">
                    {{
                      new Date(enrollee.encounter_date_end).toLocaleString(undefined, {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    }}
                  </span> <i class="tiny material-icons red-text clickable" title="Reset date"
                    @click="handleDateReset()">cancel</i>
                </p>
              </span>
              <span v-else>
                <p>Encounter Dates <em>not</em> set</p>
              </span>
            </span>
          </div>

          <div class="col s4">
            <img :src="enrollee.profile_img" width="96" class="circle responsive-img right"
              v-if="enrollee.profile_img" />
            <img :src="`https://ui-avatars.com/api/?name=${enrollee.name}&background=random&size=128`" width="96"
              class="circle responsive-img right" v-else />
          </div>
        </div>

        <div class="row grey lighten-4" v-if="forDelete">
          <p><br /></p>
          <div class="col s12">
            <a class="waves-effect waves-light btn-large teal left" title="Delete All"
              :class="{ 'disabled': isSubmitting }" @click.prevent="handleDeleteEncounter('back')">
              <i class="medium material-icons left">chevron_left</i>Go back
            </a>

            <a class="waves-effect waves-light btn-large red right" title="Delete All"
              :class="{ 'disabled': isSubmitting }" @click.prevent="handleDeleteEncounter('confirm_delete')">
              <i class="medium material-icons left">delete</i>Confirm Delete for {{ capWords(enrollee.name) }}
            </a>
          </div>
          <p><br /></p>
        </div>
      </div>


      <!-- Search Enrollee -->
      <template v-if="!enrollee">
        <template v-if="!enrolleeSearch">

          <div class="card-panel" :class="{ 'red lighten-5': error }">
          <div class="row">
            <div class="col s3">
              <div class="input-field">
                <input type="text" id="enrolleeId" v-model="enrolleeId" />
                <label for="enrolleeId">Enrollee ID</label>
              </div>
            </div>
            <div class="col s6">
              <div class="input-field">
                <input type="text" id="enrolleeName" v-model="enrolleeName" />
                <label for="enrolleeName">Enrollee Name</label>
              </div>
            </div>
            <div class="col s3">
              <p>
                <button class="waves-effect waves-light btn-large right" :class="{
                  disabled: !enrolleeId || !enrolleeName,
                }" @click="handleSearch()">
                  <i class="material-icons right" v-if="!isLoading">chevron_right</i> Next
                </button>
              </p>
            </div>
            <div clas="row" v-if="error">
              <div class="col s12">
                <span class="left badge red white-text text-darken-2">{{ error }}</span>
              </div>
            </div>
          </div>
        </div>
        </template>

        <template v-else>
        <div class="card-panel" :class="{ 'red lighten-5': error }">
          <div class="row">
            <div class="col s9">
              <div class="input-field">
                <input type="text" id="enrolleeId" v-model="enrolleeId" @focus="handleClearID('id')" />
                <label for="enrolleeId">Enrollee ID</label>

                <div class="progress" v-if="isLoading">
                  <div class="indeterminate"></div>
                </div>
              </div>
            </div>
            <div class="col s3">
              <p>
                <button class="waves-effect waves-light btn-large right" :class="{
                  disabled: !enrolleeId || isLoading,
                }" @click="handleSearch">
                  <i class="material-icons right" v-if="!isLoading">chevron_right</i>{{ isLoading ? "Searching..." :
                    "Search" }}
                </button>
              </p>
            </div>
            <div clas="row" v-if="error">
              <div class="col s12">
                <span class="left badge red white-text text-darken-2">{{ error }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Confirmation for more multiple enrollees -->
        <div class="card yellow lighten-4" v-for="enrollee in enrolleeGroup" :key="enrollee.id">
          <div class="card-content">
            <div class="row">
              <div class="col s10">
                <p class="grey-text text-darken-2">{{ cluster.month.name }} {{ cluster.year.name }} List</p>
                <h5>{{ capWords(enrollee?.name) }} <small>({{ enrollee?.enrollee_number }})</small></h5>
                <span class="new badge left grey darken-2" data-badge-caption="Scheme">{{
                  enrollee?.scheme.name.toUpperCase()
                }}</span>
              </div>
              <div class="col s2">
                <a class="btn-floating btn-large waves-effect waves-light light-blue lighten-2 pulse right"><i
                    class="material-icons" @click.prevent="handleStart(enrollee)" title="details">chevron_right</i></a>
              </div>
            </div>
          </div>
        </div>
      </template>
      </template>
      <!-- End Search Enrollee -->

      <!-- Encounter Starts: Date Form -->
      <div class="card-panel" :class="{ 'red lighten-5': error }" v-if="enrollee && !('encounter_date' in enrollee)">
        <div class="row">
          <div class="col s12">
            <span class="card-title"><em>Starts</em> Encounter</span>
          </div>
          <div class="col s3">
            <div class="input-field">
              <input type="text" id="enounterDate" v-model="encounterDate" @focus="handleClearID('date')" />
              <label for="encounterDate">Day</label>
            </div>
          </div>
          <div class="col s3">
            <div class="input-field">
              <input type="text" id="month" :value="cluster.month.name" disabled />
              <label class="active" for="month">Month</label>
            </div>
          </div>
          <div class="col s3">
            <div class="input-field">
              <input type="text" id="year" :value="cluster.year.name" disabled />
              <label class="active" for="year">Year</label>
            </div>
          </div>
          <div class="col s3">
            <p>
              <button class="waves-effect waves-light btn-large right" :class="{
                disabled: !encounterDate || isLoading,
              }" @click="handleEncounterDate">
                <i class="material-icons right">{{
                  isLoading ? "hourglass_empty" : "chevron_right"
                }}</i>{{ isLoading ? "Going..." : "Next" }}
              </button>
            </p>
          </div>
        </div>
      </div>

      <!-- Encounter Ends: Date Form -->
      <div class="card-panel" :class="{ 'red lighten-5': error }"
        v-if="enrollee && 'encounter_date' in enrollee && !('encounter_date_end' in enrollee)">
        <div class="row">
          <div class="col s12">
            <span class="card-title"><em>Ends</em> Encounter</span>
          </div>
          <div class="col s3">
            <div class="input-field">
              <input type="text" id="enounterDateEnd" v-model="encounterDateEnd" @focus="handleClearID('date')" />
              <label class="active" for="encounterDateEnd">Day</label>
            </div>
          </div>
          <div class="col s3">
            <div class="input-field">
              <FilterSimpleDiv inputId="filterDurationMonth" fieldName="Select Month" :data="filterDurationMonthData"
                :placeHolder="selectedDurationMonth?.name" :search="false" @selected="handlePick" />
            </div>
          </div>
          <div class="col s3">
            <div class="input-field">
              <FilterSimpleDiv inputId="filterDurationYear" fieldName="Select Year" :data="filterDurationYearData"
                :placeHolder="selectedDurationYear?.name" :search="false" @selected="handlePick" />
            </div>
          </div>
          <div class="col s3">
            <p>
              <button class="waves-effect waves-light btn-large right" :class="{
                disabled: !encounterDateEnd || !selectedDurationMonth || !selectedDurationYear || isLoading,
              }" @click="handleEncounterDateEnd">
                <i class="material-icons right">{{
                  isLoading ? "hourglass_empty" : "chevron_right"
                }}</i>{{ isLoading ? "Going..." : "Next" }}
              </button>
            </p>
          </div>
        </div>
      </div>

      <!-- Displays -->
      <template v-if="enrollee && 'encounter_date' in enrollee && 'encounter_date_end' in enrollee && !forDelete">
        <!-- Diagnosis -->
        <div id="display" class="row teal lighten-5" v-if="diagnosisList.length">
          <div class="col s12">
            <div class="card-content yellow lighten-1">
              <span class="black-text text-lighten-5">
                <p>
                  Diagnosis: {{ diagnosisList.join(", ").toUpperCase() }}
                  <a href="#" class="secondary-content edit-black-item right">
                    <i class="small material-icons" :class="{
                      'black-text': !resetDiagnosis,
                      'blue-text': resetDiagnosis,
                    }" title="edit diagnosis" @click.prevent="handleReviewDiagnosis">edit</i>
                  </a>
                </p>
              </span>
            </div>
          </div>
        </div>

        <!-- Claims -->
        <div class="card" v-if="claimsDisplayCard">
          <div class="card-content">
            <span class="card-title">Item(s)</span>

            <!-- List claims -->
            <ol id="item-list" class="collection">
              <li class="collection-item" v-for="claim in claims" :key="claim.id">
                <i class="tiny material-icons grey-text text-darken-2 pointer" title="saved"
                  v-if="enrollee?.encounter_date_set">save</i>
                &nbsp; <b>{{ capWords(claim.claim) }}</b> x {{ claim.qty }} (<em>Sent Amount</em>: ₦{{
                  claim.amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}) <span v-if="claim?.authorization_code">-> ({{ claim.authorization_code }})</span>

                <a href="#" class="secondary-content cancel-item" @click.prevent="handleDelete(claim)" title="delete"><i
                    class="material-icons" :class="{
                      'grey-text': deleteId != claim.id,
                      'red-text': deleteId == claim.id,
                    }">cancel</i></a>

                <a href="#" class="secondary-content edit-item" @click.prevent="handleEdit(claim)" title="edit"><i
                    class="material-icons" :class="{
                      'grey-text': editId != claim.id,
                      'blue-text': editId == claim.id,
                    }">edit</i></a>
              </li>
            </ol>

            <!-- Claims Submit -->
            <div v-if="claimsSubmitCard">
              <!-- Total -->
              <ul id="item-list" class="collection">
                <li class="collection-item pull-right">
                  <b>Total</b> (Sent Amount):
                  <b><span class="blue-text text-darken-2">₦{{ totalSent() }}</span></b>
                </li>
              </ul>

              <br />

              <!-- Submit -->
              <a class="waves-effect waves-light btn-large green right"
                :class="{ pulse: submitPulse && enrollee?.status === 'o', 'disabled': isLoading || deleteId }"
                @click.prevent="handleSubmit(true)">
                <i class="large material-icons right">check_circle</i>{{ enrollee?.status === 'o' ? 'Complete' : 'Okay'
                }}
              </a>

              <a class="waves-effect waves-light btn-large black" :class="{ 'disabled': isLoading || deleteId }"
                @click.prevent="handleNext('moreClaims')">
                <i class="medium material-icons left">add</i>Add Item</a>

              <a class="waves-effect waves-light btn-large red left" title="Delete All"
                :class="{ 'disabled': isLoading || deleteId }" @click.prevent="handleDeleteEncounter('delete')">
                <i class="medium material-icons left">delete</i>X</a>
            </div>
          </div>
        </div>

        <!-- Clais hook -->
        <span id="review"></span>

        <!-- Add claim -->
        <AddManualClaim :turl="{ sid: cluster?.scheme_id, pid: cluster?.provider_id }" :type="type" :next="next"
          :editDiagnosis="resetDiagnosis" :claimItem="claim" :reload="enrollee" @update="handleUpdate"
          @clear="handleClear" />

      </template>


    </div>
  </div>
</template>


<script>
import { ref, onMounted, computed, watch, watchEffect } from "vue";
import { ageSex, capWordsFx } from "@/composables/fxLibrary/coreFx";
import { totalSentInputFx } from "@/composables/calFunction/cal";
import { useRouter } from "vue-router";
import getItemsOfItems from "@/composables/getItemsOfItems";
import AddManualClaim from "@/components/claim/AddManualClaimComponent.vue";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";

export default {
  name: "EnrolleeClaimComponent",
  props: ["cluster", "reload"],
  emits: ["next"],
  components: { AddManualClaim, FilterSimpleDiv },
  setup(props, context) {
    const enrolleeSearch = ref(false); //use manual for now
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const response = ref(null);
    const responseDurationYearDefault = ref(null);
    const responseDurationMonthDefault = ref(null);
    const enrolleeGroup = ref([]);
    const enrolleeId = ref(null);
    const enrolleeName = ref(null);
    const encounterDate = ref(null);
    const encounterDateEnd = ref(null);
    const error = ref(false);
    const editId = ref(null);
    const editClaim = ref(null);
    const forDelete = ref(false);
    const deleteId = ref(null);
    const deleteClaim = ref(null);
    const submitPulse = ref(false);
    const selectedItem = ref(null);
    const selectedDurationYear = ref(null);
    const selectedDurationMonth = ref(null);
    const claimsDisplayCard = ref(false);
    const claimsSubmitCard = ref(false);
    const diagnosisList = ref([]);
    const resetDiagnosis = ref(false);
    const type = ref("claim"); // keep claim open after input
    const next = ref("next");
    const claimsList = ref([]);
    const claim = ref(null);
    const cache = ref({});

    // vue router
    const router = useRouter();

    // Duration (Month)
    const filterDurationMonthData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseDurationMonthDefault.value) {
        if (responseDurationMonthDefault.value.key == "filterDurationMonth") {
          valPlus = responseDurationMonthDefault.value.value.reduce(
            (newArr, x) => {
              newArr[x.period] = null;
              return newArr;
            },
            {}
          );
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // Duration (Year)
    const filterDurationYearData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseDurationYearDefault.value) {
        if (responseDurationYearDefault.value.key == "filterDurationYear") {
          valPlus = responseDurationYearDefault.value.value.reduce(
            (newArr, x) => {
              newArr[x.period] = null;
              return newArr;
            },
            {}
          );
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // filter (Initialize)
    onMounted(() => {
      // initialize filterDuration
      let val = [
        { period: "January", month: 1 },
        { period: "February", month: 2 },
        { period: "March", month: 3 },
        { period: "April", month: 4 },
        { period: "May", month: 5 },
        { period: "June", month: 6 },
        { period: "July", month: 7 },
        { period: "August", month: 8 },
        { period: "September", month: 9 },
        { period: "October", month: 10 },
        { period: "November", month: 11 },
        { period: "December", month: 12 },
      ];
      // set
      responseDurationMonthDefault.value = {
        key: "filterDurationMonth",
        value: val,
      };
    });

    // filter (Initialize)
    onMounted(() => {
      // initialize filterDuration
      let val = [
        { period: props.cluster.year.search, year: props.cluster.year.search },
        { period: props.cluster.year.search + 1, year: props.cluster.year.search + 1 },
      ];
      // set
      responseDurationYearDefault.value = {
        key: "filterDurationYear",
        value: val,
      };
    });

    // start
    onMounted(() => {
      // check reload
      if (props.reload) {
        // re-set
        response.value = props.reload;
      }
    });

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // total calculations
    const totalSent = () => {
      let encounter = enrollee.value
      encounter.claims = claims.value;
      return totalSentInputFx(encounter);
    }

    // get function
    const getSearchItemsFromApi = (table, match) => {
      // loading starts
      isLoading.value = true;
      // clear error
      error.value = null;

      (async () => {
        const { res } = await getItemsOfItems(table, match);

        if (res.value && res.value.status) {
          if (res.value.data.length === 1) {
            // display the options (go straight)
            response.value = res.value.data[0];
          } else {
            // display the options
            enrolleeGroup.value = res.value.data;
          }
        } else {
          error.value = `Enrollee not on ${props.cluster.month.name} ${props.cluster.year.name} List!`;
          response.value = null; // clear standing response
        }

        // page is ready, loading is complete
        isLoading.value = false;
      })();
    };

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // start
    const handleStart = (val) => {
      // set
      response.value = val;
    }

    // search
    const handleSearch = () => {

      if (enrolleeSearch.value) {

      // loading starts
      isLoading.value = true;
      // val
      let date = props.cluster.month_year.split("_");
      let val = `q=${enrolleeId.value.trim()}&m=${date[0]}&y=${date[1]}`;
      // search: 0 = provider_id
      getSearchItemsFromApi("operator/enrollee/encounter/checker", `0?${val}`);

      } else {
      
      // build pseudo enrollee
      let newVal = {
        id: Date.now(),
        name: enrolleeName.value,
        enrollee_number: enrolleeId.value,
        dob: null,
        sex: null,
        client_name: null,
        scheme_id: props.cluster.scheme_id,
        profile_img: null,
      };

      // push start
      handleStart(newVal);

      }
    
    };

    // search
    const handleEncounterDate = () => {
      // Create a new Date object
      var date = new Date(
        Date.UTC(
          props.cluster.year.search,
          props.cluster.month.search - 1,
          encounterDate.value,
          0,
          0,
          0
        )
      );

      // Check validity of Date
      if (
        date.getFullYear() == props.cluster.year.search &&
        date.getMonth() == props.cluster.month.search - 1 &&
        date.getDate() == encounterDate.value
      ) {
        // Format the date
        var formattedDate = date.toISOString().slice(0, 19).replace("T", " ");

        // Set encounter date
        response.value.encounter_date = formattedDate;

        // start encounter day
        encounterDateEnd.value = date.getDate();

        // cluster month
        let clusterMonth = responseDurationMonthDefault.value.value.find(x => x.month === props.cluster.month.search);

        // month
        selectedDurationMonth.value = {
          name: clusterMonth.period,
          search: props.cluster.month.search,
        };

        // year
        selectedDurationYear.value = {
          name: props.cluster.year.search,
          search: props.cluster.year.search,
        };

      } else {
        error.value = "Date is Invalid!";
      }
    };

    // search
    const handleEncounterDateEnd = () => {
      // Create a new Date object
      var date = new Date(
        Date.UTC(
          selectedDurationYear.value.search,
          selectedDurationMonth.value.search - 1,
          encounterDateEnd.value,
          0,
          0,
          0
        )
      );

      // Check validity of Date
      if (
        date.getFullYear() == selectedDurationYear.value.search &&
        date.getMonth() == selectedDurationMonth.value.search - 1 &&
        date.getDate() == encounterDateEnd.value
      ) {
        // Format the date
        var formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
        // check is date is properly set
        if (new Date(formattedDate) < new Date(response.value.encounter_date)) {
          // Error
          error.value = "Encounter Date End is Invalid!";
        } else {

          // Set end-date
          response.value.encounter_date_end = formattedDate;

          // silent update (saved)
          handleSubmit(false);

        }

      } else {
        error.value = "Date is Invalid!";
      }
    };

    // date reset
    const handleDateReset = () => {
      // memory (aproko Vue)
      cache.value.encounter_date = response.value.encounter_date;
      // delete start date
      delete response.value.encounter_date;
      // memory (aproko Vue)
      cache.value.encounter_date_end = response.value.encounter_date_end;
      // delete end date
      delete response.value.encounter_date_end;
      // clear date values
      handleClearID("date");
    }

    // haandle clear
    const handleClearID = (val) => {
      if (val == "id") {
        enrolleeGroup.value = [];
        enrolleeId.value = null;
        response.value = null;
        error.value = false;
      }

      if (val == "date") {
        encounterDate.value = null;
        encounterDateEnd.value = null;
        error.value = false;
      }
    };

    // handle Emitted update
    const handleUpdate = (val) => {
      claimsDisplayCard.value = val.claimsDisplayCard;
      claimsSubmitCard.value = val.claimsSubmitCard;
      claimsList.value = [...val.claimsList];
      diagnosisList.value = [...val.diagnosisList];
      submitPulse.value = val.submitPulse;
      deleteId.value = val.deleteId;
      cache.value = val.cache;
    };

    // watch 
    // slide between review and display
    watch(editId, (newValue) => {
      if (newValue) {
        router.push({ hash: "#review" });
      } else {
        router.push({ hash: "#display" });
      }
    });

    // handle edit
    const handleEdit = (val) => {
      claim.value = { action: "edit", claim: val };
      editId.value = val.id;
    };

    // handle delete
    const handleDelete = (val) => {
      claim.value = { action: "delete", claim: val };
    };

    // handle remove (passed as props)
    const handleReviewDiagnosis = () => {
      resetDiagnosis.value = true;
    };

    // handle next
    const handleNext = (val) => {
      next.value = val;

      // scroller
      router.push({ hash: "#review" });
    };

    // handle clear (emiited)
    const handleClear = (val) => {
      if (val.id == "resetDiagnosis") {
        resetDiagnosis.value = val.value;
      } else if (val.id == "claim") {
        claim.value = val.value;
      } else if (val.id == "next") {
        next.value = val.value;
      } else if (val.id == "editId") {
        editId.value = val.value;
      }
    };

    // enrollee delete
    const handleDeleteEncounter = (val) => {
      if (val === "delete") {

        // send to confirm
        forDelete.value = true;
        // active buttons
        isSubmitting.value = false;

      } else if (val === "confirm_delete") {

        // disable buttons
        isSubmitting.value = true;
        // set as delete
        response.value.status = "d";
        // submit
        handleSubmit(true);

      } else if (val === "back") {

        // clear
        forDelete.value = false;
        // active buttons
        isSubmitting.value = false;

      }
    }

    // final submit
    const handleSubmit = (submit) => {
      if (!enrollee.value) {
        error.value = "Enrollee and/or Claims not completed"; // most unlikely
        // end
        isLoading.value = false;
      } else {
        // start
        let encounter = enrollee.value;

        // protect saved (pending) at all cost
        // online saved encounter has encounter_date_set
        if (encounter.status !== "d") {
          if (!encounter?.encounter_date_set) {
            // check final submit
            if (submit) {
              // clear screen
              isLoading.value = true;
              encounter.status = 'c'; // c=completed
            } else {
              isLoading.value = false;
              encounter.status = 'o'; // o=on-going
            }
          }
        }

        // check editId
        if (!encounter?.editId) {
          // add
          encounter.editId = Date.now(); // one time unique ref if edit is required
        }

        // other setups (claims, diagnosis)
        encounter.claims = claims.value;
        encounter.diagnosis = diagnosisList.value.join(", ");
        encounter.old_diagnosis = cache.value?.diagnosis;
        encounter.old_encounter_date = cache.value?.encounter_date;
        encounter.old_encounter_date_end = cache.value?.encounter_date_end;
        // clear cache
        cache.value = {};

        // send out  
        setTimeout(function () {
          context.emit("next", {
            encounter: encounter,
            submit: submit,
          });

          // end
          isLoading.value = false;
        }, 1000);
      }
    };

    // output results
    const enrollee = computed(() => {
      if (!response.value) {
        return null;
      } else {
        return response.value;
      }
    });

    // output claims results
    const claims = computed(() => {
      if (!claimsList.value) {
        return [];
      } else {
        return claimsList.value.filter(x => x.status === 'a');
      }
    });

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;
      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterDurationMonth") {
          //set
          if (!selectedItem.value.value) {
            selectedDurationMonth.value = null;
          } else {
            responseDurationMonthDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedDurationMonth.value = {
                  name: x.period,
                  search: x.month,
                };
              }
            });
          }
        } else if (selectedItem.value.id == "filterDurationYear") {
          //set
          if (!selectedItem.value.value) {
            selectedDurationYear.value = null;
          } else {
            responseDurationYearDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedDurationYear.value = { name: x.period, search: x.year };
              }
            });
          }
        }
      }
    });


    // monitor diagnosis
    watch(() => diagnosisList.value, (newDiagnosis, oldDiagnosis) => {

      if (JSON.stringify(newDiagnosis) !== JSON.stringify(oldDiagnosis)) {

        if (!props?.reload) {
          // don't submit, 
          // just handle emit 
          // update process
          if (newDiagnosis.length) {
            handleSubmit(false);
          }

        } else {
          // don't submit, 
          // just handle emit 
          // update process
          if (newDiagnosis.join(", ") !== props?.reload.diagnosis) {
            handleSubmit(false);
          }

        }

      }

    }, { immediate: false }
    );


    // monitor claims
    watch(() => claims.value, (newClaim, oldClaim) => {

      if (newClaim?.length || oldClaim?.length) {

        if (!props?.reload) {
          // don't submit, 
          // just handle emit 
          // update process
          if (newClaim?.length) {
            handleSubmit(false);
          } else {
            handleSubmit(true); // clear if empty
          }

        } else {
          if (JSON.stringify(newClaim) !== JSON.stringify(props?.reload.claims)) {
            // don't submit, 
            // just handle emit 
            // update process
            if (newClaim?.length) {
              handleSubmit(false);
            } else {
              handleSubmit(true); // clear if empty
            }
          }

        }

      }

    }, { immediate: false }
    );


    return {
      error,
      isLoading,
      isSubmitting,
      capWords,
      showAgeSex,
      handleStart,
      handleSearch,
      handlePick,
      handleDateReset,
      handleEncounterDate,
      handleEncounterDateEnd,
      handleClearID,
      handleEdit,
      handleReviewDiagnosis,
      handleDelete,
      handleUpdate,
      handleNext,
      handleDeleteEncounter,
      handleSubmit,
      handleClear,
      totalSent,
      editId,
      deleteId,
      forDelete,
      claimsDisplayCard,
      claimsSubmitCard,
      selectedItem,
      filterDurationMonthData,
      filterDurationYearData,
      selectedDurationMonth,
      selectedDurationYear,
      resetDiagnosis,
      diagnosisList,
      editClaim,
      deleteClaim,
      submitPulse,
      claimsList,
      next,
      type,
      claim,
      claims,
      enrolleeGroup,
      enrolleeId,
      enrolleeName,
      enrollee,
      encounterDate,
      encounterDateEnd,
      enrolleeSearch,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -10px;
}

.clickable {
  cursor: pointer;
}

.counter {
  display: block;
  border-radius: 50%;
  background-color: #f2f3f4;
  padding: 0.5em;
}

.spacer5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.spacer10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>