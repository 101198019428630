<template>
  <div class="container white lighten-5 z-depth-4">
    <br />

    <div class="row">
      <div class="col s12" v-for="tab in tabs" :key="tab.id">
        <router-link :to="{ name: tab.to }" class="grey-text text-darken-3">
          <div :class="`card-dashboard ${tab.card}`">
            <i class="medium material-icons blue-grey-text text-darken-2 left">{{ tab.icon }}</i>
            <h4><span :class="{ 'brown-text text-darken-2': tab?.special }">{{ tab.title }}</span> <span
                class="chip yellow lighten-3 green-text text-darken-2" v-if="tab.notification">
                {{ tab.notification }}
              </span></h4>
          </div>
        </router-link>
      </div>
    </div>

    <!-- spacer -->
    <div v-if="tabs.length < 4">
      <p><br /><br /><br /></p>
    </div>

    <br />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import { addModules } from "@/composables/cache/modules";
import { useStore } from "vuex";

export default {
  name: "HomeView",
  setup() {
    const tabs = ref([]);

    // store
    const store = useStore();

    // title
    onMounted(() => {
      // page name
      document.title = "EasyHMO Admin";
    });

    // display
    onMounted(() => {
      // load allowed
      let sourceTabs = addModules();
      // filter
      if (store.state.user) {
        tabs.value = sourceTabs.filter(x => store.state.user.modules.includes(x.to));
      } else {
        tabs.value = [];
      }
    });


    // display
    onMounted(() => {
      // get alerts
      alerts();
    });


    // get function
    const alerts = () => {

      (async () => {
        const { res } = await getItems(`operator/starter?u=${store.state.user?.id}`);

        if (res.value) {
          res.value.data.forEach((x) => {
            tabs.value.forEach((z) => {
              if (x.name === z.to) {
                z.notification = x.message;
              }
            })
          })
        }

      })();
    };

    return {
      tabs,
    };
  },
};
</script>

<style>
.card-dashboard {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  margin: 20px;
  cursor: pointer;
}

.card-dashboard:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card-dashboard h4 {
  font-weight: 510;
  font-size: 2em;
  color: #2e4053;
}

.card-dashboard img {
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

.card-1 {
  background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

.card-2 {
  background-image: url(https://ionicframework.com/img/getting-started/components-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

.card-3 {
  background-image: url(https://ionicframework.com/img/getting-started/theming-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

@media (max-width: 990px) {
  .card-dashboard {
    margin: 20px;
  }
}
</style>