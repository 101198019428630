<template>
  <LoadingDiv v-if="isLoading" />

  <template v-else>
    <div class="row">
      <div class="col s12">
        <div class="section">
          <div class="card grey lighten-4">
            <!-- Heading -->
            <div class="card-content">
              <span class="card-title teal-text"
                ><span class="chip">4/6</span> Policy<i
                  class="material-icons right"
                  v-if="plans.length"
                  >check_circle</i
                ></span
              >
            </div>

            <!-- Selected -->
            <div
              class="card-content grey lighten-5 teal-text"
              v-for="(plan, id) in plans"
              :key="plan.id"
            >
              <div class="divider" v-if="id > 0"></div>

              <br />

              <div class="row">
                <div class="col s9">
                  <span class="black-text card-title">{{ plan.name }}</span>
                </div>
                <div class="col s3">
                  <span class="secondary-content card-title right">
                    <a href="#"
                      ><i
                        class="material-icons orange-text lighten-4"
                        title="clear"
                        @click.prevent="handleRemove(plan.id)"
                        >clear</i
                      ></a
                    >
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col s3">
                  <p>
                    <b>{{ plan.name }}</b>
                  </p>
                  <label class="active">Policy Name</label>
                </div>
                <div class="col s3">
                  <p v-if="cat == 'hmo'">{{ plan.desc }}</p>
                  <p v-else>
                    Reference Plan
                  </p>
                  <label class="active">Policy Description</label>
                </div>
                <div class="col s3">
                  <p v-if="cat == 'hmo'">
                    <b>₦{{ toMoney(plan.premiumIndividual) }}</b>
                  </p>
                  <p v-else>
                    <b>N/A</b>
                  </p>
                  <label class="active">(Individual) Premium</label>
                </div>
                <div class="col s3">
                  <p v-if="cat == 'hmo'">
                    <b>₦{{ toMoney(plan.premiumFamily) }}</b>
                  </p>
                  <p v-else>
                    <b>N/A</b>
                  </p>
                  <label class="active">(Family) Premium</label>
                </div>
              </div>
            </div>

            <!-- Select Policy -->
            <div class="card-panel teal-text">
              <div class="row">
                <div class="col s12">
                  <span class="black-text">Policy Type</span>
                </div>
              </div>
              <div class="row">
                <div
                  class="col s12"
                  :class="{ 'red lighten-5': error && !selectedPlan }"
                >
                  <div class="input-field">
                    <FilterSimpleDiv
                      inputId="filterPlan"
                      fieldName="Select Policy"
                      :clear="resetPlan"
                      :data="filterPlanData"
                      :search="false"
                      @selected="handlePick"
                      @clear="handleClear"
                    />
                  </div>
                </div>
              </div>
            </div>

            <template v-if="selectedPlan">
              <!-- Premium -->
              <div class="card-panel teal-text" v-if="cat != 'tpa'">
                <div class="row">
                  <div class="col s12">
                    <span class="black-text">Premiums</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col s1"><span class="chip">₦</span></div>
                  <div
                    class="col s4"
                    :class="{
                      'red lighten-5': error && !premiumIndividual,
                    }"
                  >
                    <div class="input-field">
                      <input
                        type="number"
                        id="premiumIndividual"
                        v-model="premiumIndividual"
                        class="validate"
                        required
                      />
                      <label class="active" for="premiumIndividual"
                        >Premium (Individual)</label
                      >
                    </div>
                  </div>

                  <div class="col s1"></div>

                  <div class="col s1"><span class="chip">₦</span></div>
                  <div
                    class="col s4"
                    :class="{ 'red lighten-5': error && !premiumFamily }"
                  >
                    <div class="input-field">
                      <input
                        type="number"
                        id="premiumFamily"
                        v-model="premiumFamily"
                        class="validate"
                        required
                      />
                      <label class="active" for="premiumFamily"
                        >Premium (Family)</label
                      >
                    </div>
                  </div>

                  <div class="col s1"></div>
                </div>
              </div>

              <!-- Custom Benefits -->
              <template
                v-if="
                  premiumIndividual != checkIndividual ||
                  premiumFamily != checkFamily || cat == 'tpa' 
                "
              >
                <!-- Benefits -->
                <div class="card-panel teal-text">
                  <div class="row">
                    <div class="col s12">
                      <span class="black-text"
                        >{{ selectedPlan.name }} (Customized) Plan</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col s12">
                      <div class="file-field input-field">
                        <div class="btn">
                          <span>Add Benefits</span>
                          <input type="file" @change="handleFile" />
                        </div>
                        <div class="file-path-wrapper">
                          <input class="file-path validate" type="text" />
                          <p class="red-text" v-if="fileError">
                            {{ fileError }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>

        <div class="section center">
          <p>
            <button
              class="waves-effect waves-light btn-large black"
              :class="{
                disabled: isSubmitting || !selectedPlan,
                pulse: selectedPlan && pulse,
              }"
              @click="handleAdd"
            >
              <i class="material-icons left">add</i>
              {{ (isSubmitting && selectedPlan) ? `Adding (${uploadProgress})` : "Add" }}
            </button>

            <button
              class="waves-effect waves-light btn-large"
              :class="{
                disabled: isSubmitting || !plans.length, 
              }"
              @click="handleNext"
            >
              <i class="material-icons right">chevron_right</i
              >{{ (isSubmitting && !selectedPlan) ? "Loading Plans" : "Next" }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </template>
</template>
    
<script>
import { ref, onMounted, computed, watchEffect } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import useStorage from "@/composables/useStorage";
import postItem from "@/composables/postItem";
import getItems from "@/composables/getItems";
import LoadingDiv from "@/components/LoadingDiv.vue";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";

export default {
  name: "PolicyOnboardComponent",
  components: { LoadingDiv, FilterSimpleDiv },
  props: ["cat", "newClient"],
  emits: ["next"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const pulse = ref(false);
    const error = ref(null);
    const responsePlanDefault = ref(null);
    const originalPlanDefault = ref(null);
    const selectedItem = ref(null);
    const selectedPlan = ref(null);
    const resetPlan = ref(false);
    const next = ref(false);
    const policy = ref([]);
    const custom = ref(false);
    const premiumIndividual = ref(null);
    const premiumFamily = ref(null);
    const checkIndividual = ref(null);
    const checkFamily = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // toMoney
    const toMoney = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // Plan
    const filterPlanData = computed(() => {
      // start
      let val = {
        "Plans Loading...": null,
      };

      let valPlus;

      if (responsePlanDefault.value) {
        if (responsePlanDefault.value.key == "filterPlan") {
          valPlus = responsePlanDefault.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...valPlus };
        }
      }

      // return final
      return val;
    });

    // starter
    onMounted(() => {
      // get started
      getPlans();
    });

    const getPlans = async () => {
      // get policies
      getSearchItemsFromApi("plan/private", "filterPlan");
    };

    // search function
    const getSearchItemsFromApi = (table, key) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          // search
          responsePlanDefault.value = { key: key, value: res.value.data };
          // original
          originalPlanDefault.value = { key: key, value: res.value.data };
        }
        // end
        isLoading.value = false;
      })();
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // handle add
    const handleReset = () => {
      // clear files
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      uploadProgress.value = "0%";
      uploadError.value = null;
      data.value = null;
      completed.value = null;
      // null all
      selectedItem.value = null;
      selectedPlan.value = null;
      custom.value = false;
      premiumIndividual.value = null;
      premiumFamily.value = null;
      // clear component
      resetPlan.value = true;
      // clear error
      error.value = false;
      // clear loading
      isLoading.value = false;
      // clear submitting
      isSubmitting.value = false;
      // clear pulse
      pulse.value = false;
    };

    // handle update
    const handUpdate = () => {
      // check for duplicates
      let duplicate = false;

      policy.value.forEach((x) => {
        if (x.plan_id == selectedPlan.value.plan_id) {
          duplicate = true;
        }
      });

      // if no duplicate
      if (!duplicate) {
        // review description
        if (
          premiumIndividual.value != checkIndividual.value ||
          premiumFamily.value != checkFamily.value
        ) {
          selectedPlan.value.desc = "Customized Plan";
        }
        // load
        policy.value.push({
          ...selectedPlan.value,
          premiumIndividual: premiumIndividual.value,
          premiumFamily: premiumFamily.value,
        });
      }
    };

    // handle add
    const handleAdd = async () => {
      if (
        selectedPlan.value &&
        premiumIndividual.value &&
        premiumFamily.value
      ) {
        
        // Start Uploading
        isSubmitting.value = true;

        // zero premiums for tpa cat
        if (props.cat == "tpa") {
          premiumIndividual.value = 0;
          premiumFamily.value = 0;
        }

        // initiate for update
        let plan = null;
        let individual = null;
        let family = null;
        let scope = props.newClient.id; // get client id from props

        // new plan
        plan = {
          id: selectedPlan.value.plan_id, // 1 for test
        };

        // update individual premium
        if (checkIndividual.value != premiumIndividual.value) {
          individual = {
            plan_id: null, // update this on server
            amount: premiumIndividual.value,
            scope: scope,
            type: "i",
          };
        }

        // update family premium
        if (checkFamily.value != premiumFamily.value) {
          family = {
            plan_id: null, // update this on server
            amount: premiumFamily.value,
            scope: scope,
            type: "f",
          };
        }

        // File (Benefits)
        if (file.value) {
          // Start Uploading
          uploadError.value = null;

          // Cloud Function
          const fx = "planLoader";

          // round up
          const cloudInput = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "update",
          };

          // upload
          uploadFile(file.value, fx, cloudInput);
        } else {
          // update without File
          // (without File)

          // Start Uploading
          uploadProgress.value = "100%";

          // round up
          const newVal = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "update",
            benefits: [], // empty array (no Benefit change required)
          };

          // post item
          const { res, err } = await postItem("plan/benefits/bulk", newVal);

          if (res.value) {
            // success
            handUpdate();
            // restart
            handleReset();
          } else {
            error.value = err.value;
            isSubmitting.value = false;
          }
        }
      } else {
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        console.log(data.value);
        // success
        handUpdate();
        // restart
        handleReset();
      }
      if (uploadError.value) {
        // restart
        // handleReset();
        // stop
        error.value = uploadError.value;
        isSubmitting.value = false;
      }
    });

    // clear (null reset)
    const handleClear = (val) => {
      if (val.id == "filterPlan") {
        resetPlan.value = val.value;
      }
    };

    // handle remove
    const handleRemove = async (val) => {
      // filter delete
      let newPolicy = policy.value.filter((x) => x.id != val);

      // update
      policy.value = newPolicy;

      // clean up
      handleReset();
    };

    // plan display filter watcher
    watchEffect(() => {
      if (responsePlanDefault.value && originalPlanDefault.value) {
        // start update list
        const filteredPlans = originalPlanDefault.value.value.filter(
          (plan) => !policy.value.some((active) => active.plan_id === plan.id)
        );

        // complete update list
        responsePlanDefault.value.value = filteredPlans;
      }
    });

    // handle submit
    const handleNext = async () => {
      if (!policy.value.length) {
        error.value = "No Policy Added!"; // most unlikely
      } else {

      //check if plan is yet to be submitted
      if (selectedPlan.value) {
        // notify submit
        pulse.value = true;
      }else{
        // Good to go!

        // start
        isSubmitting.value = true;

        // initiate
        let client = props.newClient.id; // get client id from props
        let plans = [];

        // load
        policy.value.forEach((x) => {
          plans.push(x.plan_id);
        });

        // add selected plans to client
        const newVal = {
          client: client,
          plans: plans,
        };

        // post item
        const { res, err } = await postItem("plan/client/plans", newVal);

        if (res.value) {
          // success
          // emit new parameters
          context.emit("next", {
            id: "policy",
            value: true,
          });
        } else {
          error.value = err.value;
          isSubmitting.value = false;
        }
        
      }
        
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;

      // chek for reset condition
      if (selectedItem.value.id == "filterPlan" && val.value == null) {
        // clear every here
        handleReset();
      }

      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterPlan") {
          //set
          if (!selectedItem.value.value) {
            selectedPlan.value = null;
          } else {
            responsePlanDefault.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                premiumIndividual.value = x.premiums[0].amount;
                premiumFamily.value = x.premiums[1].amount;
                checkIndividual.value = x.premiums[0].amount;
                checkFamily.value = x.premiums[1].amount;
                // load
                selectedPlan.value = {
                  id: Date.now(),
                  star: x.id,
                  plan_id: x.id,
                  scheme_id: x.scheme_id,
                  name: x.name,
                  desc: x.description,
                };
                // clear
                custom.value = false;
              }
            });
          }
        }
      }
    });

    // output results (plans)
    const plans = computed(() => {
      if (!policy.value.length) {
        return [];
      } else {
        return policy.value;
      }
    });

    return {
      isLoading,
      isSubmitting,
      pulse,
      error,
      handleNext,
      handleAdd,
      handleRemove,
      handleClear,
      handlePick,
      handleFile,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      toMoney,
      selectedItem,
      filterPlanData,
      next,
      selectedPlan,
      resetPlan,
      custom,
      premiumIndividual,
      premiumFamily,
      checkIndividual,
      checkFamily,
      plans,
    };
  },
};
</script>
    
<style>
</style>