<template>

  <SubHeading :home="home" :tabs="tabs" :currTab="currTab" @active="handleActive" />

  <!-- search layer -->
  <div class="grey lighten-4">
    <div class="container section"></div>
  </div>

  <!-- Warning -->
  <ModalNotify id="notify" class="modal" :title="notifyTitle" :message="notifyMessage" @ok="handleOk"
    v-if="notifyTitle && notifyMessage" />

  <!-- Beautiful side link -->
  <!-- <a class="waves-effect waves-light btn-large btn right modal-trigger" data-target="notify">
    Open
  </a> -->

  <!-- main -->
  <div class="container" id="top">
    <div class="section">
      <LoadingDiv v-if="!isReady" />

      <template v-else>

        <div class="center">
          <span v-for="tab in tabsState" :key="tab.id" :class="{
            'tab-custom-active': tab.state === currState,
            'tab-custom': tab.state !== currState,
          }" @click="handleState(tab.state)">
            {{ tab.title }}
          </span>
          <br /><br /><br />
        </div>

        <!-- Error -->
        <p class="chip red lighten-2 white-text" v-if="error">{{ error }}</p>

        <!-- Success -->
        <SubmitSummary :cluster="success" @restart="handleRestart" v-if="success" />


        <!-- Main Displays  -->
        <div v-if="currState === 'c'">
          <template v-if="cluster">
            <div class="section">
              <div class="card">
                <span class="new badge left" data-badge-caption="Scheme">
                  {{ cluster.scheme.name.toUpperCase() }}
                </span>
                <div class="card-content">
                  <span class="card-title black-text">
                    <span class="row">
                      <div class="col s10">
                        {{ capWords(cluster.provider.name) }}
                        <span class="chip"><b>{{ cluster.month.name }} {{ cluster.year.name }} Claims</b></span>
                      </div>
                      <div class="col s2">

                        <a href="#" class="secondary-content teal-text text-darken-1 dropdown-trigger right"
                          data-target="dropmenu"><i class="small material-icons" title="review">more_vert</i></a>

                        <!-- DropMenu -->
                        <ul id="dropmenu" class="dropdown-content">
                          <li @click.prevent="handleReview('view')">
                            <a href="#">View All Pages <b>({{ encounters.length ? encounters.length : 0 }})</b></a>
                          </li>
                          <li class="divider" tabindex="-1"></li>
                          <li @click.prevent="handleReview('add')">
                            <a href="#">Add New</a>
                          </li>
                          <span
                            v-if="!encounters.filter(encounter => encounter?.status === 'c' || encounter?.status === 'o').length">
                            <li class="divider" tabindex="-1"></li>
                            <li @click.prevent="handleClose">
                              <a href="#">Close Claims</a>
                            </li>
                          </span>
                        </ul>
                      </div>
                    </span>
                  </span>
                </div>
              </div>

              <EnrolleeClaim :cluster="cluster" :reload="encounter" @next="handleNext" v-if="next" />

              <div v-else>
                <SummaryClaim :encounters="encounters" :isSubmitting="isSubmitting" :isManual="true"
                  @reload="handleReload" />

                <p>
                  <button class="waves-effect waves-light btn-large black left" :class="{
                    disabled: isSubmitting
                  }" @click="handleAdd">
                    <i class="medium material-icons left">add</i>Add Claim
                  </button>
                  <button class="waves-effect waves-light btn-large left" :class="{
                    'disabled': isSubmitting,
                    'grey darken-2': !encounters.filter(encounter => encounter.status === 'c' || encounter.status === 'o').length,
                    'blue darken-2': encounters.filter(encounter => encounter.status === 'c' || encounter.status === 'o').length,
                  }" @click="handleSave">
                    <i class="medium material-icons left">save</i>Save
                  </button>

                  <button class="waves-effect waves-light btn-large btn right modal-trigger" :class="{
                    disabled: isSubmitting || !encounters.length,
                  }" data-target="confirmSubmit">
                    <i class="medium material-icons right">check_circle</i>{{ isSubmitting ? "Submit" : "Final Submit"
                    }}
                  </button>
                </p>
                <!-- Confirmation -->
                <Modal id="confirmSubmit" class="modal" :title="alertTitle" :message="alertMessage"
                  @confirm="handleConfirm" v-if="cluster" />
              </div>
            </div>
          </template>

          <StartClaim @next="handleNext" v-if="!cluster" />
        </div>

        <!-- User Displays  -->
        <SavedClaimUser @restart="handleRestart" v-if="currState === 'u'" />

        <!-- HMO Displays  -->
        <SavedClaimHMO @restart="handleRestart" v-if="currState === 'h'" />

      </template>

    </div>
  </div>
</template>


<script>
import { ref, onMounted, onUpdated, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ageSex, capWordsFx, } from "@/composables/fxLibrary/coreFx";
import { getFullMonthName } from "@/composables/fxLibrary/months";
import { syncSavedEnrollee } from "@/composables/serverCaller/serverCaller";
import postItem from "@/composables/postItem";
import SubHeading from "@/components/HeaderSubDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import SavedClaimUser from "@/components/claim/SavedClaimUserComponent.vue";
import SavedClaimHMO from "@/components/claim/SavedClaimHMOComponent.vue";
import StartClaim from "@/components/claim/StartClaimComponent.vue";
import EnrolleeClaim from "@/components/claim/EnrolleeClaimComponent.vue";
import SummaryClaim from "@/components/claim/SummaryClaimComponent.vue";
import SubmitSummary from "@/components/claim/SubmitSummaryClaimComponent.vue";
import Modal from "@/components/alerts/ModalComponent.vue";
import ModalNotify from "@/components/alerts/ModalNotificationComponent.vue";
import M from "materialize-css";

export default {
  name: "claimsAdd",
  components: { SubHeading, LoadingDiv, SavedClaimUser, SavedClaimHMO, StartClaim, EnrolleeClaim, SummaryClaim, SubmitSummary, Modal, ModalNotify },
  setup() {
    const isReady = ref(true);
    const isSubmitting = ref(false);
    const cluster = ref(null);
    const encounter = ref(null);
    // const editId = ref(null);
    const next = ref(false);
    const error = ref(null);
    const success = ref(null);
    const notifyTitle = ref(null);
    const notifyMessage = ref(null);
    const alertTitle = ref("Confirm");
    const alertMessage = ref("Are you sure you want to submit? Please confirm that all the details provided are accurate. Once submitted, changes cannot be made unless approved by a supervisor.");
    const home = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);
    const tabsState = ref([]);
    const currState = ref(null);

    // vue router
    const router = useRouter();

    // store
    const store = useStore();

    // start
    onMounted(() => {
      // page name
      document.title = "Add Claims | EasyHMO";
    });

    // menu
    onMounted(() => {
      // restart if availiable
      handleRestart();
    });

    // handle restart
    const handleRestart = () => {
      // reload, if available
      if (store.state.cluster) {
        cluster.value = store.state.cluster;
      }

      // clear all
      handleClear();

      // set default
      currState.value = "c";
    }

    // menu
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, name: "Claims", title: "Home", icon: "arrow_back", url: "claim", hash: null, });
      tabs.value.push({ id: 2, name: "Claims", title: "Vet", icon: "edit", url: "claim", hash: "#vet", });
      tabs.value.push({ id: 3, name: "Claims", title: "Add Claims", icon: null, url: null, hash: null, });

      // set default
      currTab.value = tabs.value.find(x => x.title.includes("Add"));
      // set home
      home.value = tabs.value.find(x => x.title.includes("Home"));
    });

    // start
    onMounted(() => {
      // load tab
      tabsState.value.push({ id: 1, title: "Current", state: "c" });
      tabsState.value.push({ id: 2, title: `Saved (${store.state.user?.slug})`, state: "u" });
      tabsState.value.push({ id: 3, title: "Saved (HMO)", state: "h" });

      // set default
      currState.value = "c";
    });

    // change state
    const handleState = (val) => {

      // clear all
      handleClear();

      // set
      if (val) {
        currState.value = val;
        router.push({ hash: "#top" });
      }

    }

    // close notification
    const handleOk = () => {
      // clear notification
      notifyTitle.value = null;
      notifyMessage.value = null;
      // Restore scrollbar (insurance)
      resetScrollBar();
    }

    // open
    const openNotifyModal = () => {
      const notifyElement = document.getElementById("notify");
      if (notifyElement) {
        var notifyInstance = M.Modal.init(notifyElement, {}); // Ensure initialization
      }
      notifyInstance.open();
    };

    // more
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // alert
    onMounted(() => {
      // modal
      const elems = document.querySelectorAll(".modal");
      M.Modal.init(elems, {});
    });

    // alert
    onUpdated(() => {
      // modal
      const elems = document.querySelectorAll(".modal");
      M.Modal.init(elems, {});
    });

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // scrollbar
    const resetScrollBar = () => {
      // Restore scrollbar (insurance)
      document.body.style.overflow = 'auto';
    }

    // clear all
    const handleClear = () => {
      error.value = null;
      success.value = null;
    }

    // review
    const handleActiveState = (val) => {
      // switch to display
      if (val == "v") {
        currState.value = "v";
      } else if (val == "d") {
        currState.value = "d";
      } else if (val == "s") {
        currState.value = "s";
      }
    };

    // handle curr status
    const handleActive = (tab) => {
      // refer
      if (tab.hash) {
        router.push({ name: tab.url, hash: tab.hash });
      } else {
        router.push({ name: tab.url });
      }
    };

    // handle next
    const handleNext = async (val) => {

      // clear all 
      // notifications
      handleClear();

      // continue
      if (!cluster.value) {
        // start
        isReady.value = false;

        // setup
        let newVal = {
          provider_id: val.provider.search,
          month_year: `${val.month.search}_${val.year.search}`,
          scheme_id: val.scheme.search,
          user_id: store.state.user.id,
          slug: store.state.user.slug,
        };

        // post update
        const { res } = await postItem("cluster", newVal);

        if (res.value) {
          // !res.value.data.encounters.length
          if (res.value.data.status === 'q') {
            // load all from server
            cluster.value = { ...res.value.data }; // ...val

            // convert month_year
            let monthYear = cluster.value.month_year.split("_");
            // month
            cluster.value.month = { search: Number(monthYear[0]), name: getFullMonthName(monthYear[0] - 1) };
            // year
            cluster.value.year = { search: Number(monthYear[1]), name: monthYear[1] };

            // uptimize claims
            cluster.value.encounters.forEach((encounter) => {
              encounter.claims.forEach((claim) => {
                claim.claim = claim.item
                claim.qty = claim.sent_qty
                claim.amt = claim.sent_price
                claim.standard = claim.standard_price
              })
            })

            // save payload
            store.commit("loadCluster", cluster.value);

            // open enrolleeClaim
            if (cluster.value.encounters.length) {
              next.value = false;
            }

            // warning
            if (res.value.data.user?.type !== "hmo") {

              // encounter is already actived by provider
              notifyTitle.value = "Important Notice";
              notifyMessage.value = `${cluster.value.provider.name} (${cluster.value.scheme.name}) 
              ${cluster.value.month.name} ${cluster.value.year.name} Claims has already been activated by the Provider. 
              Proceed only if you are taking over claims input from Provider.`
              // wait this out a bit
              setTimeout(function () {
                openNotifyModal();
              }, 2000);

            }

          } else {
            // future insurance: 
            // Cluster DB don't have 'd' at the moment
            if (res.value.data.status === 'd') {
              error.value = "Claims month is Deleted";
            } else {
              error.value = "Claims month already active!";
            }
          }
        } else {
          error.value = "Error starting claims. Try again!";
        }

        //end
        isReady.value = true;

      } else {

        if ("encounter" in val) {

          // sort out 
          let syncEnrolleeSaved = await syncSavedEnrollee(val, cluster.value);

          // cont.
          if (syncEnrolleeSaved !== null) {

            // start 
            // main action
            let newVal = true;

            // start
            if (!val.encounter.claims.length || val.encounter.status === "d") {

              // remove for deleted
              let newClusterEncounters = cluster.value.encounters.filter(x => x.editId !== val.encounter.editId);
              cluster.value.encounters = newClusterEncounters;

              // prevent new push
              newVal = false;


            } else {


              // update old encounter if edit
              cluster.value.encounters.forEach((x, y) => {

                if (x.editId == val.encounter.editId) {

                  // update here
                  cluster.value.encounters[y] = val.encounter;

                  // prevent new push
                  newVal = false;
                }

              });

            }


            // set new encounter
            if (newVal) {
              cluster.value.encounters.push(val.encounter);
            }


            // update server
            // save payload
            store.commit("loadCluster", cluster.value);


            // if submit
            if (val.submit) {
              // close encounter
              encounter.value = null;
              // close enrolleeClaim
              next.value = false;
            }


          } else {

            // server sync FAILED for savedEnrollee
            // so return to home page

            // close encounter
            encounter.value = null;
            // close enrolleeClaim
            next.value = false;

            // load back original store
            // aproko Vue updates arrays without asking it
            // so last saved copy is old_
            cluster.value.encounters.forEach((x, y) => {

              if (x.editId == val.encounter.editId) {

                if (val.encounter?.old_diagnosis) {
                  // retun to original diagnosis
                  cluster.value.encounters[y].diagnosis = val.encounter?.old_diagnosis;
                }

                if (val.encounter?.old_encounter_date) {
                  // retun to original encounter_date
                  cluster.value.encounters[y].encounter_date = val.encounter?.old_encounter_date;
                }

                if (val.encounter?.old_encounter_date_end) {
                  // retun to original encounter_date_end
                  cluster.value.encounters[y].encounter_date_end = val.encounter?.old_encounter_date_end;
                }

              }

            });

          }

        }

      }
    };

    // handle reload
    const handleReload = (val) => {
      encounter.value = val.encounter;
      // editId.value = val.encounter.editId;
      next.value = true;
    };

    // handle review
    const handleReview = (val) => {
      if (val == "view") {
        next.value = false;
      } else if (val == "add") {
        next.value = true;
      }
    };

    // handle close
    const handleClose = () => {
      // clear up saved
      // insurance, double check there is no unsaved encounter/claims
      if (!store.state.cluster?.encounters.filter(encounter => encounter.status === 'c' || encounter.status === 'o').length) {

        // clear store
        store.commit("loadCluster", null);

        // continue
        if (cluster.value.user_id === store.state.user.id) {
          handleState("u"); // c=current, u=user, h=hmo, n=null
        } else {
          handleState("h"); // c=current, u=user, h=hmo, n=null
        }

        // clear
        cluster.value = null;

      }
    }

    // handle add
    const handleAdd = () => {
      // open new
      next.value = true;
      // close old encounter
      encounter.value = null;
    };

    // handle save
    const handleSave = () => {
      if (encounters.value.filter(encounter => encounter.status === 'c' || encounter.status === 'o').length) {
        handleSubmit("q"); // q = keep un-submitted
      } else {
        handleClose() // nothing to save
      }
    }

    // confirmation
    const handleConfirm = (val) => {
      // call submit if confirmed
      if (val) {
        // p = submitted
        handleSubmit("p");
      } else {
        // Restore scrollbar (insurance)
        resetScrollBar();
      }
    }

    // handle review
    const handleSubmit = async (clusterStatusVal) => {

      // clear all 
      // notifications
      handleClear();

      // start
      isSubmitting.value = true;

      // start
      let approved;
      let comment;
      let newCluster;
      let newEncounter;
      let newEncounters = [];
      let newClaims = [];
      let keysToRemove = ["id", "scheme", "created_at", "updated_at", "editId", "claims", "status"];
      let pendingEncounters = encounters.value.filter(encounter => encounter.status === 'c' || encounter.status === 'o'); //c & o

      // un-submitted encounters && claims
      pendingEncounters.forEach((x) => {
        // sort encounters
        newEncounter = {
          ...Object.fromEntries(
            Object.entries(x).filter(
              ([key]) => !keysToRemove.includes(key)
            )
          ),
        };
        newEncounter = ({
          ...newEncounter,
          encounter_cluster_id: cluster.value.id,
          provider_id: cluster.value.provider_id,
          status: "p", // ready for vetting
        })

        // sort claims
        x.claims.forEach((z) => {
          // vet claims
          if ((z.amt) > (z.qty * z.standard)) {
            approved = z.qty * z.standard;
            comment = "Over Billing";
          } else {
            approved = z.amt;
            comment = null;
          }

          // extra security
          if (z.status === "a") {
            // format
            newClaims.push({
              encounter_id: null,
              item: z.claim,
              sent_qty: z.qty,
              sent_price: z.amt,
              approved_qty: z.qty,
              approved_price: approved,
              standard_price: z.standard,
              authorization_code: z?.authorization_code,
              comment: comment,
            })

          }

        })

        // put everything together
        newEncounters.push({ encounter: newEncounter, claims: newClaims });

        // clean up and go again
        newEncounter = null;
        newClaims = [];

      });

      // update cluster status
      newCluster = {
        id: cluster.value.id,
        status: clusterStatusVal, // set to submitted or unsubmitted by input
      }

      // convert to user's cluster
      if (cluster.value.user?.type !== "hmo") {
        newCluster = {
          ...newCluster,
          user_id: store.state.user.id,
          slug: store.state.user.slug,
        }
      }

      // setup
      let newVal = {
        cluster: newCluster,
        encounters: newEncounters,
      };

      // post update
      const { res, err } = await postItem("encounter/claims/cluster/bulk", newVal);

      if (res.value) {
        // clear temp save
        store.commit("loadCluster", null);

        // next action
        if (res.value.data.status === "q") {

          // continue
          if (res.value.data.user_id === store.state.user.id) {
            handleState("u"); // c=current, u=user, h=hmo, n=null
          } else {
            handleState("h"); // c=current, u=user, h=hmo, n=null
          }

          // clear
          cluster.value = null;


        } else {

          if (res.value.data.status === "p") {
            // load success cluster
            res.value.data.month = cluster.value.month.name;
            res.value.data.year = cluster.value.year.name;
            // change to display
            // success.value = res.value.data; 
            // start again
            currState.value = null;
            // clear
            cluster.value = null;
            // notify (Toast)
            M.toast({
              html: "Claims Added Successfully <i class='material-icons'>check_circle</i>", // Toast content
              // classes: 'green rounded', // Add custom classes
              displayLength: 5000, // Duration in milliseconds
              inDuration: 300, // Transition in duration
              outDuration: 300, // Transition out duration
            });
            // nothing, just back home
            handleState("c"); // c=current, u=user, h=hmo, n=null
          } else {
            // nothing, just back home
            handleState("c"); // c=current, u=user, h=hmo, n=null
          }

        }

      } else {
        error.value = err.value;
      }

      // Restore scrollbar (insurance)
      resetScrollBar();

      // end
      isSubmitting.value = false; // in case of incasecity!
    };

    // output claims results
    const encounters = computed(() => {
      if (!("encounters" in cluster.value)) {
        return [];
      } else {
        return cluster.value.encounters;
      }
    });

    return {
      handleRestart,
      handleActive,
      handleActiveState,
      home,
      currTab,
      tabs,
      tabsState,
      currState,
      capWords,
      showAgeSex,
      handleOk,
      handleClear,
      handleState,
      handleConfirm,
      handleReview,
      handleNext,
      handleReload,
      handleAdd,
      handleClose,
      handleSave,
      handleSubmit,
      notifyTitle,
      notifyMessage,
      alertTitle,
      alertMessage,
      success,
      error,
      isReady,
      isSubmitting,
      next,
      cluster,
      encounter,
      encounters,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -15px;
}

.clickable {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.clickable:hover {
  opacity: 0.8;
}

.pointable {
  cursor: default;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.pointable:hover {
  opacity: 0.8;
}

.spacer {
  padding: 2px;
}

.pointer {
  cursor: default;
}
</style>