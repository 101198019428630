
// totalsent Input
function totalSentInputFx(encounter) {
    if (encounter?.claims) {
        let total = 0;
        encounter.claims.forEach((x) => {
            if (x.amt) {
                total = Number(total) + Number(x.amt);
            }
        });
        return total
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

// grosstotalsent Input
function grossTotalSentInputFx(encounters) {
    let total = 0;
    encounters.forEach((z) => {
        z.claims.forEach((x) => {
            if (x.amt) {
                total = Number(total) + Number(x.amt);
            }
        });
    });
    return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// total calculations (clustered sent)
function batchTotalSentFx(batch) {
    let total = 0;
    batch.clusters.forEach((b) => {
        b.encounters.forEach((z) => {
            z.claims.forEach((x) => {
                if (x.sent_price) {
                    total = Number(total) + Number(x.sent_price);
                }
            });
        });
    });
    return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// total calculations (clustered approved)
function batchTotalApprovedFx(batch) {
    let total = 0;
    batch.clusters.forEach((b) => {
        b.encounters.forEach((z) => {
            z.claims.forEach((x) => {
                if (z.status !== "d" && x.status === "a" && x.approved_price) {
                    // status has to be taken into acount for gross approved
                    total = Number(total) + Number(x.approved_price);
                }
            });
        });
    });
    return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// total calculations (gross sent)
function grossTotalSentFx(encounters) {
    let total = 0;
    // checks not required
    encounters.forEach((z) => {
        z.claims.forEach((x) => {
            if (x.sent_price) {
                total = Number(total) + Number(x.sent_price);
            }
        });
    });
    return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// total calculations (gross approved)
function grossTotalApprovedFx(encounters, db) {
    let total = 0;
    // check for encounter status, claim status, and approved_price availability
    encounters.forEach((z) => {
        z.claims.forEach((x) => {
            if (z.status !== "d" && x.status === "a" && x.approved_price) {
                // status has to be taken into acount for gross approved
                total = Number(total) + Number(x.approved_price);
            }
        });
    })

    // set
    if (db) {
        return total
            .toFixed(2);
    } else {
        return total
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}

// total calculations (sent)
function totalSentFx(encounter) {
    let total = 0;
    // no checking required
    encounter.claims.forEach((x) => {
        if (x.sent_price) {
            total = Number(total) + Number(x.sent_price);
        }
    });
    return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// total calculations (approved)
function totalApprovedFx(encounter) {
    let total = 0;
    // check for encounter status, claim status, and approved_price availability
    encounter.claims.forEach((x) => {
        if (encounter.status !== "d" && x.status === 'a' && x.approved_price) {
            total = Number(total) + Number(x.approved_price);
        }
    });
    return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// payment status
const checkPaidFx = (payment, repayment) => {
    let val = false;

    if (payment) {
        if (payment.stage === "c" || payment.stage === "z") {
            val = true;
        }
    }

    if (repayment) {
        if (repayment.stage == "c") {
            val = true;
        }
    }

    return val;
};

// payment status
function paymentStatusFx(cluster) {

    let val = "N/A";

    if (cluster.payment) {
        if (cluster.payment.stage == "p") {
            val = "QA"; // compliance
        } else if (cluster.payment.stage == "q") {
            val = "Finance";
        } else if (cluster.payment.stage == "c") {
            val = "Paid";
        } else if (cluster.payment.stage == "z") {
            val = "Complete";
        }
    } else {
        if (cluster.status === "q") {
            val = "Unsubmitted";
        } else if (cluster.status === "p") {
            val = "Pending"; // unvetted
        } else if (cluster.status === "v") {
            if (cluster.level === 3) {
                val = "Supv"; // Supervisor
            } else if (cluster.level === 5) {
                val = "HOD";
            } else {
                val = "Vetted"; // insurance
            }
        }
    }

    if (cluster.repayment) {
        if (cluster.repayment.stage == "p") {
            val = val = "QA (R)"; // compliance
        } else if (cluster.repayment.stage == "q") {
            val = "Finance (R)";
        } else if (cluster.repayment.stage == "c") {
            val = "Paid (R)";
        } else if (cluster.repayment.stage == "z") {
            val = "Complete (R)";
        }
    }

    return val;
}


export {
    totalSentInputFx,
    grossTotalSentInputFx,
    totalSentFx,
    totalApprovedFx,
    batchTotalSentFx,
    batchTotalApprovedFx,
    grossTotalSentFx,
    grossTotalApprovedFx,
    checkPaidFx,
    paymentStatusFx,
}