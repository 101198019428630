<template>
    <!-- Edit Diagnosis -->
    <div class="card" :class="{ 'teal lighten-5': !error, 'red lighten-5': error }" v-if="editDiagnosis">
        <div class="card-content">
            <div class="row">
                <form class="col s12">
                    <div class="row">
                        <div class="col s12">
                            <span class="card-title">Edit Diagnosis</span>
                        </div>

                        <div class="input-field col s12">
                            <input v-model="diagnosis" id="diagnosis" type="text" :disabled="isSubmitting" />
                            <label class="active" for="diagnosis">Diagnosis</label>
                        </div>

                        <div class="input-field col s12">
                            <a class="waves-effect waves-light btn red lighten-2" :class="{ 'disabled': isSubmitting }"
                                @click.prevent="handleBack('clearDiagnosis')">
                                <i class="medium material-icons left">chevron_left</i>Back
                            </a>

                            <button class="waves-effect waves-light btn right"
                                :class="{ 'disabled': isSubmitting, 'green': diagnosis }"
                                @click.prevent="handleDiagnosis">
                                <i class="medium material-icons left">sync</i>Confirm Edit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Add Claims -->
    <div class="card" :class="{ 'teal lighten-5': !error, 'red lighten-5': error }" v-else>
        <div class="card-content">
            <div class="row">
                <form class="col s12">
                    <div class="row">
                        <div class="col s12" v-if="!isSubmitting">
                            <span class="card-title">Select Item</span>
                        </div>
                        <div class="input-field col s6">
                            <TariffSearch inputId="filterClaim" :data="filterData" :startAgain="resetClaim"
                                :startEdit="editClaim" :disabled="isSubmitting" @selected="handlePick"
                                @searching="handleSearch" @clear="handleClear" />
                        </div>
                        <div class="input-field col s3">
                            <input v-model="qty" @input="handlePricing" id="qty" type="number"
                                :disabled="isSubmitting" />
                            <label class="active" for="qty">Quantity</label>
                        </div>
                        <div class="input-field col s3">
                            <input v-model="amt" id="amt" type="number" readonly :disabled="isSubmitting" />
                            <label class="active" for="amt">Amount Charged</label>
                        </div>
                        <div class="input-field col s12">
                            <a class="waves-effect waves-light btn red lighten-2" :class="{ 'disabled': isSubmitting }"
                                @click.prevent="handleBack('clearAuth')">
                                <i class="medium material-icons left">chevron_left</i>Back
                            </a>

                            <button class="waves-effect waves-light btn-large right"
                                :class="{ 'disabled': isSubmitting, 'teal': qty, 'black': !qty }"
                                @click.prevent="handleAuth">
                                <i class="medium material-icons right">check_circle</i>Authorize Item
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import getItems from "@/composables/getItems";
import TariffSearch from "@/components/search/TariffSearch.vue";
import { urlFormat } from "@/composables/tariff/urlFormat";
import { getCache, addCache } from "@/composables/cache/cache";
import { capitalizeFirstLetter } from "@/composables/fxLibrary/coreFx.js";

export default {
    name: "AddClaimSimpleComponent",
    emits: ["update"],
    props: ["turl", "editDiagnosis", "reset"],
    components: { TariffSearch },
    setup(props, context) {
        const responseDefault = ref([]);
        const responseSearch = ref([]);
        const isSubmitting = ref(props.reset);
        const error = ref(false);
        const diagnosis = ref(props.editDiagnosis);
        const editClaim = ref(null);
        const resetClaim = ref(false);
        const selectedItem = ref(null);
        const selectedPrice = ref(null);
        const claim = ref(null);
        const qty = ref(null);
        const amt = ref(null);

        // data tariff
        const filterData = computed(() => {
            // start
            let val = {};

            let valPlus;

            if (responseSearch.value) {
                valPlus = responseSearch.value.reduce((newArr, x) => {
                    newArr[x.item] = null;
                    return newArr;
                }, {});
                val = { ...val, ...valPlus };
            }

            // return final
            return val;
        });
     
      // search function
      const getTariffFromApi = (table) => {
      // get item from store (cache)
      let cacheTariff = getCache(table);
      // get from api
      if (cacheTariff?.length) {
        responseDefault.value = cacheTariff;
      } else {
        (async () => {
          const { res } = await getItems(table);
          if (res.value) {
            // search
            responseDefault.value = res.value.data;
            // add cache
            addCache(table, responseDefault.value);
          }
        })();
      }
    };

        // starter (set loading spin)
        onMounted(() => {
            // tariff url
            let url = urlFormat(props.turl);
            // get items from function
            getTariffFromApi(url);
        });

        // update reset
        const updateSubmit = (val) => {
            if (val) {
                isSubmitting.value = false;
            }
        }

        // handle diagnosis
        const handleDiagnosis = () => {
            if (diagnosis.value) {
                // format for saving
                const { fx } = capitalizeFirstLetter(diagnosis.value);
                // send back
                context.emit("update", {
                    id: "resetDiagnosis",
                    value: fx.value,
                });

                // keep buttons and fields disabled
                isSubmitting.value = true;

                // reset error (if required)
                error.value = false;
            } else {
                error.value = true;
            }
        };

        // load claim
        const handleAuth = () => {
            // start action
            if (selectedItem.value && qty.value && amt.value) {
                // format for saving
                const { fx } = capitalizeFirstLetter(selectedItem.value);

                // send for auth
                context.emit("update", {
                    id: "addNewAuth",
                    value: {
                        id: Date.now(),
                        claim: fx.value,
                        qty: qty.value,
                        amt: amt.value,
                        standard: Number(selectedPrice.value).toFixed(2),
                    },
                });

                // keep buttons and fields disabled
                isSubmitting.value = true;

                // clear entry
                // claimInputClear();

                // reset error (if required)
                error.value = false;
            } else {
                error.value = true;
            }

        };

        // clear (back)
        const handleBack = (val) => {
            // clear
            context.emit("update", {
                id: val,
                value: true
            });
        }

        // watch qty & price
        const handlePricing = () => {
            amt.value = (Number(qty.value) * Number(selectedPrice.value)).toFixed(2);
        };

        // filter components
        const handleSearch = (val) => {
            if (val.item) {
                // sort new search
                responseSearch.value = responseDefault.value.filter((x) =>
                    x.item.toLowerCase().includes(val.item.toLowerCase())
                );
            } else {
                // empty search array
                responseSearch.value = [];

                // clear entry
                claimInputClear();
            }
        };

        // null reset
        const handleClear = (val) => {
            resetClaim.value = val.item;
            editClaim.value = val.item;
        };

        // get option
        const handlePick = (val) => {
            //set
            selectedItem.value = val.item;

            //set
            if (selectedItem.value) {
                // get the price out
                responseSearch.value.forEach((x) => {
                    if (x.item === selectedItem.value) {
                        // found
                        selectedPrice.value = x.price;
                    }
                });

                // set quantity & amount
                qty.value = 1;
                amt.value = (Number(qty.value) * Number(selectedPrice.value)).toFixed(
                    2
                );
            } else {
                // if null (very unlikely)
                // clear entry
                claimInputClear();
            }
        };

        // clear inputs
        const claimInputClear = () => {
            // clear all
            selectedItem.value = null;
            selectedPrice.value = null;
            resetClaim.value = true; // will clear claims input
            qty.value = null;
            amt.value = null;
            responseSearch.value = [];

            // clear error (if needed)
            error.value = false;
        };

        // watch for changes
        watch(props, (newVal) => {
            updateSubmit(newVal.reset);
        });

        return {
            handleDiagnosis,
            handleAuth,
            handleBack,
            handlePricing,
            handleSearch,
            handleClear,
            handlePick,
            isSubmitting,
            selectedItem,
            selectedPrice,
            editClaim,
            resetClaim,
            filterData,
            error,
            diagnosis,
            claim,
            qty,
            amt,
        };
    },
};
</script>

<style></style>