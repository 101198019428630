<template>
  <!-- Loading -->
  <div class="progress" v-if="isLoading">
    <div class="indeterminate"></div>
  </div>

  <!-- Display claims -->
  <template v-else>
    <div class="card yellow lighten-5">
      <button class="waves-effect waves-light btn-large grey darken-1 right" @click="handleDownload()"><i
          class="large material-icons right">download</i></button>
      <div class="card-content white">
        <table class="highlight responsive-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Provider</th>
              <th>Account</th>
              <th>Period</th>
              <th>Approved</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Claims Summary -->
            <template v-for="(cluster, index) in clusters" :key="cluster.id">
              <tr class="clickable" @click.prevent="handleShow(cluster)">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ cluster.provider.name }}
                </td>
                <td>
                  {{ cluster.provider.bank }}<br />
                  {{ cluster.provider.account }}
                </td>
                <td>
                  {{ toMonthYear(cluster.month_year) }}
                  <small><b>({{ cluster.scheme.name }})</b></small>
                </td>
                <td>₦{{ grossTotalApproved(cluster.encounters) }}</td>
                <td v-if="batchStage == 'c'">
                  <i class="material-icons grey-text darken-2">check</i>
                </td>
                <td @click.prevent="handleReBatch(cluster)" v-else>
                  <span class="remove-item" v-if="clusters.length > 1">
                    <i title="remove and re-batch" class="material-icons grey-text text-lighten-1">low_priority
                    </i>
                  </span>
                  <span v-else>
                    <i title="re-batch blocked" class="material-icons grey-text text-lighten-1 clickable">do_not_disturb
                    </i>
                  </span>
                </td>
              </tr>

              <!-- Encounter details -->
              <template v-if="currCluster == cluster">
                <tr v-for="encounter in cluster.encounters" :key="encounter.id">
                  <td><br /></td>
                  <td><br /></td>
                  <td><br /></td>
                  <td class="secondary-content black-text text-darken-4">
                    {{ capWords(encounter.name) }}
                  </td>
                  <td class="teal-text text-darken-2">
                    ₦{{ totalApproved(encounter) }}
                  </td>
                  <td>
                    <br />
                  </td>
                </tr>
              </template>
            </template>

            <!-- Final total -->
            <tr>
              <td colspan="2"><br /></td>
              <td>Total</td>
              <td>
                <br />
              </td>
              <td class="green-text text-darken-2">
                <h5>₦{{ batchTotalApproved(clusters) }}</h5>
              </td>
              <td colspan="1"><br /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Submit -->
    <div class="card-content white" v-if="batchStage != 'c'">
      <button class="waves-effect waves-light btn-large teal right" :class="{
        disabled: isTalking || isSubmitting,
      }" @click="handleSubmit('done')">
        <i class="medium material-icons right">check_circle</i>{{ isSubmitting ? "Updating Payment Status" : "Mark (Batch) As Paid" }}
      </button>
    </div>
  </template>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import getItems from "@/composables/getItems";
import putItem from "@/composables/putItem";
import {
  toMonthYearFx,
  capWordsFx,
} from "@/composables/fxLibrary/coreFx.js";
import {
  totalApprovedFx,
  grossTotalApprovedFx,
  batchTotalApprovedFx,
} from "@/composables/calFunction/cal";
import {
  downloadExcelFx,
} from "@/composables/reportFunction/downloader";


export default {
  name: "FinanceSingleBatchComponent",
  props: ["batchId"],
  emits: ["reset"],
  setup(props, context) {
    const response = ref(null);
    const currCluster = ref(false);
    const batchStage = ref(null);
    const error = ref(null);
    const isTalking = ref(0);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(true);

    // total calculations (clustered approved)
    const batchTotalApproved = (clusters) => {
      let batch = {};
      batch.clusters = clusters;
      return batchTotalApprovedFx(batch);
    };

    // total calculations (gross approved)
    const grossTotalApproved = (encounters, db) => {
      return grossTotalApprovedFx(encounters, db);
    };

    // total calculations (approved)
    const totalApproved = (encounter) => {
      return totalApprovedFx(encounter);
    };

    //from functions
    const capWords = (val) => {
      return capWordsFx(val);
    };

    // convert to Month & Year
    const toMonthYear = (val) => {
      return toMonthYearFx(val);
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          // set batchStage
          batchStage.value = response.value.stage;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // get started
      getItemsFromApi(`cluster/finance/vetted?q=${props.batchId}`);
    });

    // show claims
    const handleShow = (val) => {
      if (currCluster.value == val) {
        currCluster.value = null;
      } else {
        currCluster.value = val;
      }
    };

    // reBatch
    const handleReBatch = async (val) => {
      if (clusters.value.length > 1) { // cannot re-batch single record

        // talk care of multiple clicks
        isTalking.value++;

        // update first (twitter style)
        response.value.clusters = response.value.clusters.filter(
          (x) => x != val
        );

        // update server
        let newVal = [];

        // put update
        const { res, err } = await putItem(
          "cluster/finance/rebatch",
          val.id,
          newVal
        );

        if (res.value) {
          // send reset value to parent
          context.emit("reset", response.value);
          // null cluster
          currCluster.value = null;
          // talk care of multiple clicks
          isTalking.value--;
        } else {
          error.value = err.value;
        }
      }
    };

    // review
    const handleSubmit = async () => {
      // start
      isSubmitting.value = true;

      // update server
      let newVal = {
        stage: "c", // marked as paid (complete)
      };

      // put update
      const { res, err } = await putItem(
        "cluster/finance/review",
        props.batchId,
        newVal
      );

      if (res.value) {
        // send reset value to parent
        context.emit("reset", "reload");
      } else {
        error.value = err.value;
      }

      // end submit
      isSubmitting.value = false;
    };


    // download
    const handleDownload = () => {
      let data = [];

      // top
      clusters.value.forEach((x, y) => {

        // all
        data.push({
          No: y + 1,
          Provider: capWords(x.provider.name),
          Account: `${x.provider.bank ? capWords(x.provider.bank) : ""} ${x.provider.account ? x.provider.account : ""}`,
          Period: `${toMonthYear(x.month_year)} ${x.scheme.name} Claims`,
          Approved: grossTotalApproved(x.encounters),
        })
        
      })

      // space
      data.push({})

      // final
      data.push({
        No: "",
        Provider: "",
        Account: "",
        Period: "Total",
        Approved: batchTotalApproved(clusters.value),
      })

      // fire function
      return downloadExcelFx(data, `LifeWORTH_HMO_Payment_Batch_${props.batchId}`);
    }


    // output results
    const clusters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.clusters;
      }
    });


    return {
      handleShow,
      handleReBatch,
      handleSubmit,
      handleDownload,
      capWords,
      batchTotalApproved,
      totalApproved,
      grossTotalApproved,
      toMonthYear,
      isReady,
      isTalking,
      isLoading,
      isSubmitting,
      batchStage,
      currCluster,
      error,
      clusters,
    };
  },
};
</script>

<style scoped>
.custom-hr {
  width: 50%;
  /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc;
  /* Adjust the color and style as needed */
  margin: 20px 20px;
  /* Adjust margin as needed */
}

.progress {
  margin-top: -15px;
  /* Adjust the value as needed to move the progress bar upwards */
}

.styled-text {
  background-color: #fafad2;
  /* Light Golden Yellow */
  /* border: 2px solid #000; Black border */
  padding: 2px;
  /* Adequate padding */
}

.capsule {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: #000000 !important;
}

.pointable {
  cursor: default;
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s ease;
  /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8;
  /* Slightly reduced opacity on hover */
}

.info-item:hover .material-icons.grey-text,
.info-item:hover .material-icons.grey-text::before {
  color: rgb(0, 0, 0) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.reset-item:hover .material-icons.grey-text,
.reset-item:hover .material-icons.grey-text::before {
  color: rgb(23, 124, 40) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}

.remove-item:hover .material-icons.grey-text,
.remove-item:hover .material-icons.grey-text::before {
  color: rgb(236, 51, 51) !important;
}
</style>