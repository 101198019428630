import store from '@/store'

// totalsent
function getCache(url) {
    if (url) {
            let cache = store.state.cache;
            cache = cache.find(x => url === x.url)?.payload;
            return cache; // null value
    }
}

function addCache(url, payload) {
    if (url && payload) {
        let isUpdated = false;
        let newCache = store.state.cache;
        newCache.forEach((x) => {
            if (url === x.url) {
                x.payload = payload;
                isUpdated = true;
            }
        })
        if (isUpdated) {
            // finish
            store.commit("loadCache", newCache);
        } else {
            if (newCache.length >= 25) {
                // remove first entry
                newCache.shift();
            }
            // push new entry
            newCache.push({url: url, payload: payload});
            // finish
            store.commit("loadCache", newCache);
        }
    }
}


export {
    getCache,
    addCache,
}