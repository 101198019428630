<template>
  <div class="section">
    <div class="card grey lighten-4">
      <div class="card-content">
        <span class="card-title teal-text">Provider<i class="material-icons right"
            v-if="selectedProvider">check_circle</i></span>
      </div>
      <div class="card-panel teal-text">
        <div class="row">
          <div class="col s12">
            <div class="input-field">
              <FilterSimpleDiv inputId="filterProvider" fieldName="Select Provider" :data="filterProviderData"
                :search="true" @selected="handlePick" @searching="handleSearch" />
            </div>
          </div>
        </div>
      </div>

      <div class="card-content">
        <span class="card-title teal-text">Period<i class="material-icons right"
            v-if="selectedDurationMonth && selectedDurationYear">check_circle</i></span>
      </div>

      <div class="card-panel">
        <div class="row">
          <div class="col s7">
            <div class="input-field">
              <FilterSimpleDiv inputId="filterDurationMonth" fieldName="Select Month" :data="filterDurationMonthData"
                :search="false" @selected="handlePick" @searching="handleSearch" />
            </div>
          </div>
          <div class="col s5">
            <div class="input-field">
              <FilterSimpleDiv inputId="filterDurationYear" fieldName="Select Year" :data="filterDurationYearData"
                :search="false" @selected="handlePick" @searching="handleSearch" />
            </div>
          </div>
        </div>
      </div>

      <div class="card-content">
        <span class="card-title teal-text">Scheme<i class="material-icons right"
            v-if="selectedScheme">check_circle</i></span>
      </div>

      <div class="card-panel">
        <div class="row">
          <div class="col s12">
            <div class="input-field">
              <FilterSimpleDiv inputId="filterScheme" fieldName="Select Scheme" :data="filterSchemeData" :search="false"
                @selected="handlePick" @searching="handleSearch" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section center">
    <p>
      <button class="waves-effect waves-light btn-large" :class="{
        disabled:
          !selectedProvider ||
          !selectedDurationMonth ||
          !selectedDurationYear ||
          !selectedScheme ||
          isSubmitting,
      }" @click="handleSubmit">
        <i class="material-icons right">{{
          isSubmitting ? "hourglass_empty" : "chevron_right"
        }}</i>{{ isSubmitting ? "Processing ..." : "Get Started" }}
      </button>
    </p>
  </div>
</template>

<script>
import { ref, computed, onMounted, watchEffect } from "vue";
import { useStore } from "vuex";
import getItems from "@/composables/getItems";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";

export default {
  name: "StartClaimComponent",
  components: { FilterSimpleDiv },
  emits: ["next"],
  setup(props, context) {
    const isSubmitting = ref(false);
    const error = ref(null);
    const responseSearch = ref(null);
    const responseSchemeDefault = ref(null);
    const responseDurationYearDefault = ref(null);
    const responseDurationMonthDefault = ref(null);
    const selectedItem = ref(null);
    const selectedProvider = ref(null);
    const selectedDurationYear = ref(null);
    const selectedDurationMonth = ref(null);
    const selectedScheme = ref(null);
    const period = ref(null);
    const scheme = ref(null);

    // store
    const store = useStore();

    // Provider
    const filterProviderData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseSearch.value) {
        if (responseSearch.value.key == "filterProvider") {
          valPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // Scheme
    const filterSchemeData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseSchemeDefault.value) {
        if (responseSchemeDefault.value.key == "filterScheme") {
          valPlus = responseSchemeDefault.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // Duration (Month)
    const filterDurationMonthData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseDurationMonthDefault.value) {
        if (responseDurationMonthDefault.value.key == "filterDurationMonth") {
          valPlus = responseDurationMonthDefault.value.value.reduce(
            (newArr, x) => {
              newArr[x.period] = null;
              return newArr;
            },
            {}
          );
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // Duration (Year)
    const filterDurationYearData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseDurationYearDefault.value) {
        if (responseDurationYearDefault.value.key == "filterDurationYear") {
          valPlus = responseDurationYearDefault.value.value.reduce(
            (newArr, x) => {
              newArr[x.period] = null;
              return newArr;
            },
            {}
          );
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // filter (Initialize)
    onMounted(() => {
      // initialize filterDuration
      let val = [
        { period: "January", month: 1 },
        { period: "February", month: 2 },
        { period: "March", month: 3 },
        { period: "April", month: 4 },
        { period: "May", month: 5 },
        { period: "June", month: 6 },
        { period: "July", month: 7 },
        { period: "August", month: 8 },
        { period: "September", month: 9 },
        { period: "October", month: 10 },
        { period: "November", month: 11 },
        { period: "December", month: 12 },
      ];
      // set
      responseDurationMonthDefault.value = {
        key: "filterDurationMonth",
        value: val,
      };
    });

    // filter (Initialize)
    onMounted(() => {
      // set
      // load value from search
      getSearchItemsFromApi("operator/server/date", "filterDurationYear");
      // initialize filterDurationYear
      responseDurationYearDefault.value = {
        key: "filterDurationYear",
        value: [],
      };
    });

    // search function
    const getSearchItemsFromApi = (table, key) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          if (key == "filterScheme") {
            // one time
            responseSchemeDefault.value = { key: key, value: res.value.data };
          } else if (key == "filterDurationYear") {
            // one time
            responseDurationYearDefault.value = {
              key: key, value: [{ period: res.value.data.current_year - 2, year: res.value.data.current_year - 2 },
              { period: res.value.data.current_year - 1, year: res.value.data.current_year - 1 },
              { period: res.value.data.current_year, year: res.value.data.current_year }]
            };
          } else {
            // search
            responseSearch.value = { key: key, value: res.value.data };
          }
        }
      })();
    };

    // field filter (Initialize)
    onMounted(() => {
      // initialize filterScheme
      // getSearchItemsFromApi("scheme/filter", "filterScheme");
      responseSchemeDefault.value = { key: "filterScheme", value: store.state.user.schemes };
    });

    // filter components where search is set to "true"
    const handleSearch = (val) => {
      if (val.id == "filterProvider") {
        // get new serach items
        getSearchItemsFromApi(`provider/filter?q=${val.value}`, val.id);
      }
    };

    const handleSubmit = () => {
      // start
      isSubmitting.value = true;

      if (
        !selectedProvider.value ||
        !selectedDurationMonth.value ||
        !selectedDurationYear.value ||
        !selectedScheme.value
      ) {
        error.value = "All fields not selected"; // most unlikely
        // end
        isSubmitting.value = false;
      } else {
        setTimeout(function () {
          context.emit("next", {
            provider: selectedProvider.value,
            month: selectedDurationMonth.value,
            year: selectedDurationYear.value,
            scheme: selectedScheme.value,
          });
          // end
          isSubmitting.value = false;
        }, 2000);
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;
      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterProvider") {
          //set
          if (!selectedItem.value.value) {
            selectedProvider.value = null;
          } else {
            responseSearch.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                selectedProvider.value = { name: x.name, search: x.id };
              }
            });
          }
        } else if (selectedItem.value.id == "filterScheme") {
          //set
          if (!selectedItem.value.value) {
            selectedScheme.value = null;
          } else {
            responseSchemeDefault.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                selectedScheme.value = { name: x.name, search: x.id };
              }
            });
          }
        } else if (selectedItem.value.id == "filterDurationMonth") {
          //set
          if (!selectedItem.value.value) {
            selectedDurationMonth.value = null;
          } else {
            responseDurationMonthDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedDurationMonth.value = {
                  name: x.period,
                  search: x.month,
                };
              }
            });
          }
        } else if (selectedItem.value.id == "filterDurationYear") {
          //set
          if (!selectedItem.value.value) {
            selectedDurationYear.value = null;
          } else {
            responseDurationYearDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedDurationYear.value = { name: x.period, search: x.year };
              }
            });
          }
        }
      }
    });

    return {
      isSubmitting,
      error,
      handleSubmit,
      handleSearch,
      handlePick,
      selectedItem,
      filterProviderData,
      filterSchemeData,
      filterDurationMonthData,
      filterDurationYearData,
      selectedProvider,
      selectedScheme,
      selectedDurationMonth,
      selectedDurationYear,
      period,
      scheme,
    };
  },
};
</script>

<style></style>